import React, { useMemo, useState } from 'react';

//------------------------------------------------------------------------------
//-- ASSETS

//-- Stylesheet
import './assets/css/style.css'; //-- All icon specific styling specific styles

//-- SVGs
// https://bobbyhadz.com/blog/react-change-color-of-svg
// TODO:: 091122 #EP || Add about icon
// import {ReactComponent as About }            from './assets/svg/about.svg';
import {ReactComponent as Account }           from './assets/svg/account.svg';

import {ReactComponent as Air }               from './assets/svg/air.svg';
// import {ReactComponent as Humidity }           from './assets/svg/humidity-percentage-bordered-corner.svg';
import {ReactComponent as Humidity }           from './assets/svg/humidity-bordered-half-solid.svg';

import {ReactComponent as Bair }              from './assets/svg/bair.svg';
import {ReactComponent as BairGeometric }     from './assets/svg/bair-geometric.svg';
import {ReactComponent as Battery }     from './assets/svg/battery.svg';
import {ReactComponent as Bell }              from './assets/svg/bell.svg';
import {ReactComponent as CalendarCheck }     from './assets/svg/calendar-check.svg';
import {ReactComponent as CompressorBw }      from './assets/svg/compressor_bw.svg';
import {ReactComponent as CompressorC }       from './assets/svg/compressor_c.svg';
import {ReactComponent as Gear }              from './assets/svg/gear.svg';

//-- INFO ICONS
import {ReactComponent as infoBordered }      from './assets/svg/info-bordered.svg';
import {ReactComponent as infoBackground }    from './assets/svg/info-background.svg';

// TODO:: 091122 #EP || Add Login icon
// import {ReactComponent as Login }            from './assets/svg/login.svg';
import {ReactComponent as Login }             from './assets/svg/account.svg';
import {ReactComponent as Logout }            from './assets/svg/logout.svg';
import {ReactComponent as None }              from './assets/svg/none.svg';
import {ReactComponent as PaperEmail }        from './assets/svg/paper-email.svg';
import {ReactComponent as PaperFolded }       from './assets/svg/paper-folded.svg';
import {ReactComponent as PaperChart }        from './assets/svg/paper-chart.svg';
import {ReactComponent as Pin }               from './assets/svg/pin.svg';
import {ReactComponent as Search }            from './assets/svg/magnifying-glass.svg'
import {ReactComponent as SignalAntennaC }    from './assets/svg/signal_antenna_c.svg';
import {ReactComponent as SignalC }           from './assets/svg/signal_c.svg';
import {ReactComponent as Spinner}            from './assets/svg/spinner.svg';
import {ReactComponent as Spinner2}           from './assets/svg/spinner2.svg';
import {ReactComponent as Triangle}           from './assets/svg/triangle.svg';
import {ReactComponent as X}                  from './assets/svg/x.svg';
import {ReactComponent as Thermometer}        from './assets/svg/thermometer.svg'
import {ReactComponent as ThermometerASC}     from './assets/svg/thermometer-ascending.svg'
import {ReactComponent as ThermometerDESC}    from './assets/svg/thermometer-descending.svg'


import {ReactComponent as LockUnlocked1} from './assets/svg/padlock_unlocked_bg_ios16_50px.svg'
import {ReactComponent as LockUnlocked} from './assets/svg/padlock_unlocked_bg_ios16_50px.svg'
import {ReactComponent as LockLocked}   from './assets/svg/padlock_locked_bg_ios16_50px.svg'








//------------------------------------------------------------------------------
//-- COMPONENT FUNCTION

export default function Icon( props ) {
  /** Define the ICON you'd like, get back the SVG based on params.
  * 
  * @param {string} props.name - The name of the icon you'd like to use.
  * @param {string} props.width - The width of the icon you'd like to use.
  * @param {string} props.height - The height of the icon you'd like to use.
  * @param {string} props.color - The color of the icon you'd like to use.
  * 
  * @return {React.Component} - The SVG of the icon you'd like to use.
  */ 
  
  //-- Deconstruct the props
  const {
    name = props.name ? props.name : null,
    width,
    height,
    background_c,
    color,
    svg_c,
    fill,
    margin,
    mobile,
    boxShadow,
    stroke
  } = props;

  //-- set the default icon as the Bair icon 
  const [Icon, setIcon] = useState(None);
  
  //-- Configuration parameters for the icon. Uses props and default configs.
  const [params, setParams ] = useState({
    icon            : String(),
    color           : String(),
    name            : name,   //-- Name of the icon to be collected from the index. Default or user defined if exists
    width           : String(),
    height          : String(),
    background_c    : String(),
    svg_c           : String(),
    fill            : String(), //-- same as svg_c
    margin          : String(),
    mobile          : String(), //-- enabled, disabled if mobile only
    boxShadow       : String(),  //-- enabled, disabled if mobile only
    stroke          : String()
  });

  
  //-- Used if not properly sent in as props to ensure works.
  const icon_Defaults = {
    name            : 'BairGeometric',
    background_c    : 'var(--icon-bg-clr)',
    color           : 'var(--icon-clr)',
    width           : '25px',
    height          : '25px',
    svg_c           : 'var(--icon-svg-clr)',
    fill            : 'var(--icon-svg-clr)',
    // margin          : '0 0.5rem', //-- Removed as causing weird spacing issues.
    mobile          : 'enabled',  //-- enabled, disabled if mobile only
    boxShadow       : 'enabled',  //-- enabled, disabled if mobile only
    stroke          : 'var(--icon-stk-clr)'
  };
  
  //-- All available icons
  const icon_Index = {
    none                      : None,
    
    account                   : Account,
    air                       : Air,
    bair                      : Bair,
    BairGeometric             : BairGeometric,
    Battery                   : Battery,
    battery                   : Battery,
    calendar                  : CalendarCheck,
    clear                     : X,
    close                     : X,
    compressor_bw             : CompressorBw,
    compressor_c              : CompressorC,
    dashboard                 : PaperChart,
    email                     : PaperEmail,
    gear                      : Gear,
    humidity                  : Humidity,
    Humidity                  : Humidity,
    infoBordered              : infoBordered,
    infoBackground            : infoBackground,
    
    LockLocked                : LockLocked,
    LockUnlocked              : LockUnlocked,

    login                     : Login,
    logout                    : Logout,
    notifications             : Bell,
    paperFolded               : PaperFolded,
    pin                       : Pin,
    psi                       : Air,
    PSI                       : Air,
    search                    : Search,
    signal_antenna_c          : SignalAntennaC,
    signal_c                  : SignalC,
    spinner                   : Spinner,
    spinner2                  : Spinner2,
    
    Temperature               : Thermometer,
    temperature               : Thermometer,
    thermometer               : Thermometer,
    thermometerASC            : ThermometerASC,
    thermometerDESC           : ThermometerDESC,
    triangle                  : Triangle,
    x                         : X,
  }


  //----------------------------------------------------------------------------
  //-- Remember icon configuration state and don't re-render unless an arg is changed.
  useMemo(() => {
    //-- if the ICON is within the icon_Index use it.
    if(icon_Index[name]){
      setIcon(icon_Index[name])
      setParams({
        ...params,
        icon            : name,
        name            : name,
        width           : width         ? width         : icon_Defaults?.width,
        height          : height        ? height        : icon_Defaults?.height,
        background_c    : background_c  ? background_c  : icon_Defaults?.background_c,
        color           : color         ? color         : icon_Defaults?.color,
        margin          : margin        ? margin        : icon_Defaults?.margin,
        svg_c           : svg_c         ? svg_c         : icon_Defaults?.svg_c,
        fill            : fill          ? fill          : icon_Defaults?.fill,
        mobile          : mobile        ? mobile        : icon_Defaults?.mobile,
        boxShadow       : boxShadow     ? boxShadow     : icon_Defaults?.boxShadow,
        stroke          : stroke        ? stroke        : icon_Defaults?.stroke,
      })
    }
    //-- Otherwise grab the default which is bair at this time.
    else {
      setIcon(icon_Index['none']);
      // setIcon(icon_Index['bair']);
    }
  },[ props ]);
  

  

  return (
    <div  className={`icon__container` +
                      `${params.boxShadow === 'disabled' ? ' icon__noBoxShadow' : ''}`
                    }
            /* Style for the wrapper AROUND the Icon*/
          style={{
              backgroundColor : params.background_c,
              width           : params.width,
              height          : params.height,
              color           : params.color,
              margin          : params.margin,
            }}
            mobile={`${JSON.stringify(params.mobile)}`}
    >
      {/* {console.log(params)} */}
     
      {/* Render icon from `./assets/svg/...`, and update styling */}
      {<Icon
        fill            = { params?.svg_c       ? params?.svg_c         : params?.fill
                                                                          ? params?.fill
                                                                          : 'var(--icon-svg-clr)'
        }
        width           = {params?.width        ? params?.width         : icon_Defaults.width}
        height          = {params?.height       ? params?.height        : icon_Defaults.height}
        background_c    = {params?.background_c ? params?.background_c  : 'icon_Defaults.background_c'}
        stroke          = {params?.stroke       ? params?.stroke        : 'var(--icon-stk-clr)'} //-- NOTE: 10222022 #EP || commented out for now. Can likely be deleted, but keeping for note for now.
        
        
      />}
      
    </div>
  )
};

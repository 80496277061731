import React from 'react';
import Equipment from '../features/Equipment';

export default function EquipmentPage() {
  return (
    <div className='page__container' > 
      <Equipment />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import Rive from 'rive-react';
import './assets/css/style.css';

//------------------------------------------------------------------------------
//-- RESOURCES

import loading_dark   from './assets/riv/loading_dark.riv';
import loading_light  from './assets/riv/loading_light.riv';


export default function Loading({ mode, title, message }) {
  //-- Takes MODE
  
  const [isLoaded, setIsLoaded] = useState(false);

  //-- Verifies if page is loaded and if it is enables animations app-wide
  const checkIsLoaded = () => { 
    const isLoadedValue =  document.readyState === "complete" || document.readyState === "interactive";
    if(isLoadedValue){
      const cont = document.querySelector(".isLoading")
      // console.log("container value - cont: ", cont)
      if(cont){
          cont.classList.toggle('isLoading')
          setIsLoaded(true);
          // console.log("Toggled: ", cont)
  
      }
    }
  }

  const logos = {
    "light"             : loading_light,
    "dark"              : loading_dark
  };


  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
      // console.log("//-- checkIsLoaded() is disabled. To enable, navigate to app and uncomment 'isLoading' from app className")
      checkIsLoaded();
    }
    // return () => { 
    // }
  }, [])

  return (
    <div className='page__div'>
      <section className='page__section'>           
        <div className='page__section__header' >
            <h3 className='page__section__title fw-bold fs-700 ls-500'>
                {title}
            </h3>
            <p className='page__section__summary' >
                {message}
            </p>
          
        </div>
        <span className='loading-animation loading__container '>
          {/* RIVE ANIMATED LOGO - Converts to SVG */}
          <Rive
            // IF the key exist in object ( mode in logos ) load that mode or default light
            src={ ((mode in logos) === true) ? logos[mode] : logos["light"] }  
          />
        </span>
      </section>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
//-- Contains a SubHeader and the Navigational elements to control the modal
import {
    handleGetNavigation,
    handleSetNavigation,
    handleActivePage
 } from '../hooks/handleNavigation';


export default function Navigation( props ) {
  
  const {
    title,
    subTitle,
    subSubTitle,
    identifier,
    modifyComponents,
    components,
    setComponents,
    pages,
    activePage,
    setActivePage
  } = props;


  const [firstRender, setFirstRender] = useState(true);
//   const firstRender = useMemo(() => true, []);

//   const thisComponentName = 'table';

  useEffect(() => {
      if(firstRender) {
          setFirstRender(false);
          handleActivePage( pages, setActivePage );
    }
  
    // return () => {
    //     //TODO:: 08142022 #EP || Onboard a cleanup function
    //     setActivePage( undefined );
    // }
    // setFirstRender(false);
    // return () => {
    //     setFirstRender(true);
    // }
  }, [activePage, pages])
  


    return (
        <section className='modal__controls'>
        
        {/* PRIMARY FOCUS ON PAGE
            * WHen modal loads, this is what user is meant to see first.
            *
        */}
        <span className='modal__focus'>
            <h5> 
                { title  ? title  : `title undefined` } { subTitle ? subTitle : `subTitle undefined` }
            </h5>
            <span>
                { identifier  ? identifier : `identifier undefined` } - { subSubTitle ? subSubTitle : `subSubTitle undefined` }
            </span>
        </span>

        {pages && Object.keys(pages)?.length > 0 
            ? Object.keys(pages).map( (page, index) => {
                // console.log("pages.page: ", pages[page]);
                if(pages[page].enabled === true){
                    return (
                        <button
                            className={ (activePage === page)
                                        ? 'modal__control active'
                                        : 'modal__control'
                                }
                            key={index}
                            id={pages[page].title}
                            //TODO:: 08142022 #EP || Evaluate is datatype is better than toggle class or just remove this.
                            datatype='state-active'
                            onClick={(e) => {
                                // console.log(e.target.id)
                                handleActivePage(pages, setActivePage, e.target.id);
                                //TODO:: 08142022 #EP || Onboard onCLick to toggle activePage. if
                                // components.table.enabled === false 
                                // ? setComponents({...components, table: {...components.table, enabled: true }})
                                // : setComponents({...components, table: {...components.table, enabled: false }})
                            }}
                            //TODO:: 08142022 #EP || Onboard onClick that's good for ALL pages
                            // onClick={() => handleSetNavigation(page.name, setComponents)}
                        >
                            {pages[page].title}
                            {/* hi */}
                        </button>
                    )
                }})
            : JSON.stringify(pages)
        }

        {/* <button
            className='modal__control'
            id='dashboard'
            datatype='state-inactive'
            onClick={(e) => {
                return (
                    console.log( e.target.id )
                )
            }}
        >
            Dashboard
        </button> */}
        {/* <button className='modal__control'>
            Customer
        </button>
        <button className='modal__control'>
            Distributor
        </button>
        <button className='modal__control'>
            Equipment
        </button> */}
        
        {/* <button
            className='modal__control'
            id='sensor'
            datatype='state-inactive'
            onClick={(e) => {
                return (
                    console.log( e.target.id )
                )
            }}
        >
            Sensor
        </button> */}

        {/* <button
            className='modal__control'
            id='sensor-logs'
            datatype='state-active'
            onClick={(e) => {
                console.log(e.target.id)
                components.table.enabled === false 
                    ? setComponents({...components, table: {...components.table, enabled: true }})
                    : setComponents({...components, table: {...components.table, enabled: false }})
            }}
        >
            Sensor Logs
        </button> */}
    </section>
  )
}



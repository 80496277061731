import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../App';

import Dropdown from '../../../components/Dropdown'

import {
    getDateTimeFormatted,
    getTimePassed
} from '../../../utils/DateTime';

import CardDetail from '../../../components/Card/components/detail'


import { useMutation } from '@apollo/client';
import { SET_ALERT } from '../../../services/gql/Mutation';


//TODO: 20221114 #EP || Convert this to parent or rename or something.

export default function Alert({
    alert,
    // number=Number(),
    handleMarkAsRead=()=>{}
    }) {

    //--------------------------------------------------------------------------
    
    const {
        User,
        Icon,
        uuidv4
    } = useContext(GlobalContext);

    const [isMarkedRead, setIsMarkedRead] = useState(false);
    
    
    //--------------------------------------------------------------------------
    //-- GQL

    const [setAlertIsRead] = useMutation(SET_ALERT);
    
    //--------------------------------------------------------------------------
    const navigate = useNavigate();
    const location = useLocation();
    
    //--------------------------------------------------------------------------
    const handleSetAsRead = async (e, alertId) => {

        // e.preventDefault();

        const parentElement = e.target.parentElement.parentElement;
        parentElement.classList.add('hidden');

        //-- mark as read to hide it locally
        User.setAlertIsRead(alertId)

        //-- mark as read in DB
        const setAlert = await setAlertIsRead({
            variables: {
                'id': alertId,
                'id_token': User?.user?.id_token        
             },
        })
    }
    
    //--------------------------------------------------------------------------
    function handleViewSensor(unit, alert){
        
        if(!unit?._id) return false
        
        navigate(`/s/${unit?._id}`,
                  { replace: false,
                      state: {
                        'data': unit,
                        'history' : {
                          pathname: location.pathname,
                          search: location.search
                        }
                        }
                  }
        );
    }
    
    //--------------------------------------------------------------------------
    useEffect(() => {
    }, [alert])

    //--------------------------------------------------------------------------
    //-- if marked as read, don't render it
    if(alert?.isRead) return null
    
    //--------------------------------------------------------------------------
    //-- Normal Render
    return (
        <div className='card__wrapper' key={alert?._id}>
            
            {console.log(alert?.Unit[0].Metric)}
            {/* {console.log(alert)} */}
            {alert?.Unit[0]?.Metric.map( (metric) => {
                const B_Name    = alert?.Business?.[0]?.name
                const E_Brand   = alert?.Equipment?.[0]?.brand
                const E_Model   = alert?.Equipment?.[0]?.model
                const E_Type    = alert?.Equipment?.[0]?.type
                


                if(metric?._id === alert?.metricId) return (
                // return (
                    <span key={uuidv4()}>
                        <section className="card__header" key={uuidv4()}>
                            <h3 className='card__header_title'>
                                {B_Name}
                            </h3>
                            <h4 className='card__header_details_mfg'>
                                {E_Brand} {E_Model} {E_Type}
                            </h4>
                            
                            <h4 className='card__header_subTitle clr-fnt-danger'>
                                
                                <span className='icon__forceStroke icon__alerts' key={uuidv4()}>
                                    <Icon   name={metric?.title ? metric?.title : 'none'}
                                            background_c='transparent'
                                            svg_c='var(--clr-accent)'
                                            stroke='var(--clr-accent)'
                                    />
                                </span>
                                {metric?.title} Alert
                                {/* {metric?.title} Alert */}
                            </h4>
                            
                        </section>
                        {/* <section className='card__content'>
                            <div className='card__content_stats'>
                                {metric.title} value is at {alert?.metricValue} on the {alert?.Equipment[0]?.brand
                                } {alert?.Equipment[0]?.model} in {alert?.Unit[0]?.room} room.
                            </div>
                        </section> */}
                </span>
            )
                return ''
        })}
            
            <CardDetail onClickEvent={() => { handleViewSensor(alert?.Unit?.[0], alert) }} 
                        args={[alert?.Unit?.[0], alert]}
            />

            
            {/* Buttons / controls */}
            <section className='card__button_bottom flex jc-center gap-1000 m-x-500 p-b-500'>
                
                
                <span className='w-1000'>
                    <Dropdown   title='Alert Message' 
                                body={
                                    <div className='card__content_metrics'>
                                        {alert?.subTitle} is at {alert?.metricValue} {alert?.metricUnit} on the {alert?.Equipment[0]?.brand
                                        } {alert?.Equipment[0]?.model} in {alert?.Unit[0]?.room} room.
                                    </div>
                                }
                    />
                </span>
                

                <button
                    className="flex- p-x-600 p-y-600 m-t-500 jc-right m-l-a fs-600 ls-500"
                    onClick={(e) => { 
                        //-- Hide element and make QGL Call
                        handleSetAsRead(e, alert?._id)
                        //-- Then update local state
                        .then(results => {
                            handleMarkAsRead(e, alert?._id)
                        })
                        
                        //! TODO:: PICK UP HERE. NEED TO GET THIS TO RENDER PROPERLY.
                    }}
                >
                    &#x2713; Mark Read
                    <br />
                </button>
                
            </section>

            {/* BOTTOM OF CARD */}
            
            <div className='card__footer'>
                <section className='card__syncStatus'>
                    <i className='card__syncStatus online'>
                        {getTimePassed(alert?.date_created)}
                        {/* Online {getTimePassed("Sun Oct 23 2022 16:06:06 GMT+0000 (Coordinated Universal Time")} */}
                    </i>
                    <span className='detail_Information'>
                        <Icon
                            name='infoBordered'
                            background_c='transparent'
                            svg_c='var(--clr-accent)'
                            // width='80%'
                        />

                        <span className='card__content_info'>
                            <i>{getDateTimeFormatted(alert?.date_created).split(',')[0]} at {getDateTimeFormatted(alert?.date_created).split(',')[1]}
                            </i>
                        </span>
                    </span>
                </section>
            </div>
        </div>
    )
}
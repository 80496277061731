import {
    gql,
    InMemoryCache, 
    defaultDataIdFromObject
} from '@apollo/client';


//!! TODO: Enable caching management


//-- https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
// const cache = new InMemoryCache({
//     typePolicies: {
//       UnconventionalRootQuery: {
//         // The RootQueryFragment can only match if the cache knows the __typename
//         // of the root query object.
//         queryType: true,
//       },
//       dataIdFromObject(responseObject){
//         switch (responseObject.__typename) {
//             case 'Alert': return `Alert:${responseObject._id}`;
//             default: return defaultDataIdFromObject(responseObject);
//         }
//       }
//     },
//   });

// const cache = new InMemoryCache({
//     dataIdFromObject(responseObject) {
//     switch (responseObject.__typename) {
//       case 'Product': return `Product:${responseObject.upc}`;
//       case 'Person': return `Person:${responseObject.name}:${responseObject.email}`;
//       default: return defaultDataIdFromObject(responseObject);
//     }
//   }
// });


export const ALERTS_GET = gql`
query GetAlerts($id_token: String!) {
    getAlerts(token: $id_token) {
      _id
      title
      subTitle
      summary
      payload
      status
      isRead
      metricId
      metricValue
      metricUnit
      countBeforeResolved
      severity
      date_created
      date_modified
      LogType {
        name
      }
      User_Notified {
        username
        _id
      }
      Equipment {
        _id
        name
        type
        brand
        model
        serial
      }
      Unit {
        _id
        serial
        name
        make
        model
        room
        floor
        Metric {
            _id
            title
        }
      }
      Account {
        _id
        name
      }
      Business {
        _id
        name
      }
    }
  }`;

// export const USER_USERNAME_GET = gql`
//     mutation query getUser($username : String!) {
//         User(username: $username){
//             user {
//                 _id
//                 name_first
//                 name_last
//                 username
//                 email
//                 phone_primary
//                 date_updated
//                 date_created
//                 business_id
//             }
//         }
// }`
//TODO:: 082822 #EP || Add user Type to the above


//TODO:: 082822 #EP || Add mutation "USER_HAS_ACCESS"

//-- 1. Send in what user is requesting
//-- 2. Get True False response


export const LOGS_GET = gql`
    query GetLogs($id: ID) {
        getLogs(_id: $id) {
            _id
            title
            summary
            payload
            date_created
            date_modified
            LogType {
            name
            _id
            }
        }
    }`


export const UNIT_GET_LOGS = gql`
    query GetUnitLogs($unitId: ID!) {
        getUnitLogs(unit_id: $unitId) {
            _id
            name
            Logs {
            _id
            title
            summary
            payload
            date_created
            date_modified
            LogType {
                _id
                name
            }
            
            }
        }
    }`

export const UNITS_GET = gql`
   query GetUnits($id_token: String!) {
    getUnits( token: $id_token ) {
        _id
        name
        label
        room
        floor
        description
        config
        status
        date_created
        date_modified
        date_installed
        serial
        make
        model
        isAlert
        Metric {
            _id
            isAlert
            title
            label
            unit
            monitor
            min
            max
            alertCount
            alertFrequency
            lastValue
            lastAlert {
                _id
                title            
                subTitle
                summary
                payload
                status
                countBeforeResolved
                isRead
                metricId
                metricValue
                metricUnit
                severity
                date_created
                date_modified
            }
            lastValue
            status
        }
        SIM {
            _id
            date_sync
        }
        
        Logs {
            date_created
            payload
        }
        Account {
            _id
            name
        }
        Distributor {
            _id
            name
        }
        Business {
            _id
            name
        }
        Equipment {
            _id
            name
            type
            brand
            model
            date_serviced
        }
  }
}`

export const UNIT_GET_DETAILS = gql`
    query GetUnit_detail($id: ID!) {
        getUnit_detail(_id: $id) {
            _id
            name
            label
            room
            floor
            description
            config
            status
            date_created
            date_modified
            date_installed
            serial
            make
            model
            device_id
            Account {
                _id
                name
                type
                status
                date_created
                date_modified
            }
            Business {
                _id
                name
                type
                status
                config
                date_created
                date_modified
                
            }
            Distributor {
                _id
                name
            }
            Equipment {
                _id
                name
                type
                brand
                model
                serial
                date_mfg
                date_created
                date_modified
                date_installed
                date_serviced
            }
            SIM {
                _id
                name
            }
            Logs {
                _id
                title
                summary
                payload
                date_created
                date_modified
                LogType {
                    _id
                    name
                    summary
                }
            }
        }
    }
`

export const UNIT_GET = gql`
query getUnit($id: ID!) {
    getUnit(_id: $id) {
        _id
        name
        label
        config
        status
        date_installed
        serial
        make
        model
        device_id
    }
}`


export const USER_ID_GET = gql`
    query GetUser( $id: ID!) {
        getUser( _id: $id) {
            _id
            alert_count
            username
            name_first
            name_last
            email
            date_created
            date_modified
            Account {
            type
            name
            status
            }
            Business {
            name
            type
            status
            }
        }
    }`;


export const USER_SESSIONSTATE_GET = gql`
    query GetUserSessionState($id_token: String!) {
        GetUserSessionState(token: $id_token) {
            User {
                _id
                alert_count
                email
                name_first
                name_last
                Account {
                    _id
                    name
                    type
                    status
                }
                Business {
                    _id
                    name
                    type
                    status
                }
            }
            alert_count
            Alert {
                _id
            }
            Unit {
                _id
            }
            unit_count
        }
    }`;


/*  Removed this from session state, just above, for now. Not needed and should save data.
    Alert {
                _id
                title
                summary
                status
                isRead
                severity
                date_created
                date_modified
            }
*/
    

// export const USER_GET = gql`
//     mutation query User($username : String!) {
//         User(username: $username){
//             user {
//                 _id
//                 name_first
//                 name_last
//                 username
//                 email
//                 phone_primary
//                 date_updated
//                 date_created
//                 business_id
//             }
//         }
//     }`;
//------------------------------------------------------------------------------
//-- Data Time Specific Helpers

var moment = require('moment'); //-- Using to add more dynamic date-time in view

//-- list the 12 months
function dateGetMonths(){
    return moment.months();
  }
  
function getDays(numberOfDays){
  //-- Request number of days from today, get results
  //TODO:: 07202022 #EP || Clean this up and see if works

  var getDaysBetweenDates = function(startDate, endDate) {

    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM-DD-YYYY'));
        now.add(1, 'days');
    }
    return dates;
  };

  //TODO:: 07202022 #EP || Why is this date hardcoded?
  var startDate = moment('04-01-2022','MM-DD-YYYY');

  var endDate = moment(
    dateFormat_yyyy_mm_dd(
      new Date(
        new Date().setDate
          (new Date().getDate() + numberOfDays)
      )
    )
  );
  // console.log(startDate,endDate)
  // console.log(dateFormat(Date.now()))
  
  
  var dateList = getDaysBetweenDates(startDate, endDate);
  
  // var startdate = "20.03.2014";
  // console.log(startDate,endDate,new_date)
  console.log(dateList);
}

function getTimeLocal(date){
  // moment(date)
  return moment(date).format("h:mm:ss a");
}

function getTimeLocal24(date){
  // moment(date)
  return moment(date).format("HH:MM:SS ");
}

function dateTimeFullLocal(date){
  return moment(date).local().format("ddd, MMMM DD, YYYY h:mm A");
}

function dateTimeFull(date){
  // moment(date)
  return moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
}

function dateDayOfWeek(date){
  return moment(date).day();
}

function dateHourOfDay(date){
  return moment(date).format("ddd, hA"); 
}


function dateFormat_yyyy_mm_dd(date){
  return moment(date).format('YYYY-MM-DD');
}

//-- Send in raw JavaScript Date/Time Value from date.Now() and returns MM/DD/YYYY format
function dateFormat_mm_dd_yyyy(date) {
  return moment(date).format('MM-DD-YYYY');

  // return `${
  //   new Date(date).getMonth() + 1}
  //   /${new Date(date).getDate()}
  //   /${new Date(date ).getFullYear()
  // }`;
};

//-- Send in raw JavaScript Date/Time Value from date.Now(), get time passed in seconds, minutes, hours or days.
function dateGetTimePassed(date){
  //TODO:: 08022019 #EP || Onboard this function
  //-- Get current time
  var now = moment(new Date()); 
  //-- Get the diff between now and created date
  var duration = moment.duration(now.diff(date));
  //-- Return value in hours
  var results = duration.asHours();

  if(results < 0.01){ let seconds = ((duration._data.seconds)) + " s"; return seconds; }
  if(results < 1){ let minutes = ((duration._data.minutes)) + " m"; return minutes; }
  if(results < 24){ let hours = (Math.trunc(results)) + " h"; return hours; }
  if(results >= 24){ let days = Math.trunc(results / 24) + " d"; return days; }
  
  //-- If for some reason gets to this point, return nothing. ( shouldn't happen but being safe )
  return null;
};



//------------------------------------------------------------------------------
//-- Exports

module.exports = {
  dateGetMonths,
  //getDays,//TODO:: 07202022 #EP || Review if needed and make plan to fix if is.
  getTimeLocal,
  getTimeLocal24,
  dateFormat_yyyy_mm_dd,
  dateFormat_mm_dd_yyyy,
  dateTimeFullLocal,
  dateTimeFull,
  dateDayOfWeek,
  dateHourOfDay,
  dateGetTimePassed
}
import React from 'react';
import Alerts from '../features/Alerts';

export default function AlertsPage() {
  return (
    <div className='page__container' >
      <Alerts />
    </div>
  )
}



export default function handleViewSensor(e, sensor, location, navigate){
        e.preventDefault();
        // console.log("handleViewSensor: ", sensor)
        navigate(`/s/${sensor?._id}`,
                  { replace: false,
                      state: {
                        'data': sensor,
                        'history' : {
                          pathname: location.pathname,
                          search: location.search
                        }
                        }
                  }
        );
    }

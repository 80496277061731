import React, { useContext } from 'react';
import { GlobalContext } from '../App';
import { NavLink } from 'react-router-dom';

export default function Homepage() {

  const thisPage = 'home';
  const {
    pages
  } = useContext(GlobalContext);

  return (
    <div className='page__container'>
      
      <div className='page__div' >
        <section className='page__section' >
          <div className='page__section__header' >
              <h3 className='page__section__title'>
                  {/* {pages[thisPage].tagline} */}
                  Bair - Homepage
              </h3>
              <p className='page__section__summary' >
               This page is currently empty as I'm working on the Sensors and 
               Equipment pages. This will likely be one of the LAST page I 
               complete, but I do expect to work on it on and off throughout 
                the project. 
                <br /><br />
                - Erik @08/13/2022 
              </p>
          </div>
        </section>



        {/* <section className='page__section' >
          <div className='page__section__header' >
              <h3 className='page__section__title'>
                  Dashboard
              </h3>
              <p className='page__section__summary' >
                 The Dashboard is the default landing page for a logged in user.
                 The data contained within is user-account specific, and is meant
                 to be a jumping-point to manage all things Bair.
              </p>
          </div>
        </section>


        <section className='page__section' >
          <div className='page__section__header' >
              <h3 className='page__section__title'>
                  Sensors
              </h3>
              <p className='page__section__summary' >
                 The Sensors page is where logged-in users go to see details 
                 related to their equipment.
              </p>
          </div>
        </section>

        <section className='page__section' >
          <div className='page__section__header' >
              <h3 className='page__section__title'>
                  Scheduler
              </h3>
              <p className='page__section__summary' >
                 The Sensors page is where logged-in users go to manage scheduling
                 maintenance related to their related equipment.
              </p>
          </div>
        </section> */}

      </div>
   </div>
  )
}

import { useState, useRef, useEffect } from 'react'
import autoAnimate from '@formkit/auto-animate'
import './assets/css/style.css';

export default function Dropdown ({
    title,
    body,
    isHidden
  }){
  const [show, setShow] = useState(() => {
    if (isHidden === false) return true
    return false
  })
  const parent = useRef(null)


  const params = {
    title     : title             ? title     : 'No Title provided',
    body      : body              ? body      : "No body content provided."
  }

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  const reveal = () => setShow(!show)

  return (
    <section className='page__dropdown' ref={parent}>
      {/* <strong className="page__dropdown-label" onClick={reveal}>{params.title}</strong> */}
      {/* <button className="flex-50 p-x-800 p-y-500 jc-left w-1000 page__dropdown-button btn-secondary" */}
      <button className={`flex-50 p-x-300 p-y-500 jc-left w-1000 page__dropdown_button ${show ? 'active' : ''}`}
              onClick={reveal}
      >
        {params.title}
      </button>
      { show &&
        <div className="page__dropdown-content btn-info4" >
          {params.body}
        </div> }
    </section>
)}


import React, { useContext, useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../App';
import './assets/css/style.css';


//-- Modal Components
import Header from './components/header';
import Navigation from './components/navigation';
import Page from './components/page';
import Footer from './components/footer';

//-- Modal Hooks
import { handleSetComponents, handleGetComponent } from './hooks/handleComponents';

export default function Modal( props ) {

    const {
        navigationProps,
        modifyComponents,
        defineStyle,
        title,
        subTitle,
        subSubTitle,
        distributor,
        customer,
        model,
        identifier,
        serial,
        unit_brand,
        unit_type,
        parent,
        children,
        //-- NEW DATA
        handleShowModal,
        pages,
        modal,
        header,
        footer
    } = props


    const {
        nav_return,
        nav_params,
        nav_context,
        nav_location,
        nav_type
    } = navigationProps;


    const {
        uuidv4,
        icons,
        Loading,
        Chart,
        List,
        Banner,
        Dropdown,
        Table
    } = useContext(GlobalContext);

    // const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);


    const [activePage, setActivePage] = useState( undefined );


    //TODO:: 08092022 #EP || Add related components that can be added into the modal
    const [components, setComponents] = useState({
        'table' : {
            enabled             : false,
            name                : '',
            header              : [],
            data                : [],
            results             : undefined,
            defaultSearchFilter : undefined,
            toggleShowSearch    : false,
            toggleShowActions   : false,
            definedActions      : []
        },
        'back_arrow': {
            enabled: true,
        },
        'close_x': {
            enabled: false
        },
        pages : {
            'template': {
                enabled :   false,      //-- Page enabled/available to user
                active  :   false,      //-- Default page to load on render
                title   : undefined,    //-- The name/title of this page
                components : {          //-- Components that exist on their page (if any) with data
                    'dropdown': {
                        enabled: false,
                    },
                    'list': {
                        enabled: false,
                    },
                    'image': {
                        enabled: false,
                    },
                    'form': {
                        enabled: false,
                    },
                    'map': {
                        enabled: false,
                    },
                    'chart': {
                        enabled: false,
                    },
                    'video': {
                        enabled: false,
                    },
                    'audio': {
                        enabled: false,
                    },
                    'table' : {
                        enabled             : false,
                        name                : '',
                        header              : [],
                        data                : [],
                        results             : undefined,
                        defaultSearchFilter : undefined,
                        toggleShowSearch    : false,
                        toggleShowActions   : false,
                        definedActions      : []
                    },
                },
            }
        },
        navigation: {
            title       : undefined,    //-- Title of the navigation  ( if any )
            subTitle    : undefined,    //-- Subtitle of the navigation ( if any )
            subSubTitle : undefined,    //-- SubSubTitle of the navigation ( if any )
            index       : [],           //-- Array of pages that can be navigated to. Filled in by app not user.
        },
        modal : {
            'back_arrow': {
                enabled: true,
            },
            'close_x': {
                enabled: false
            },
        },
        header : {
            title       : undefined,
            subTitle    : undefined,
        },
        footer : {
            status : undefined
        },

    });


    /** Main Features within parent modal
     * TODO:: Review and plan for these for future.
     */
    // const [features, setFeatures] = useState({
    //     'export': false,
    //     'save': false,
    //     'delete': false,
    //     'edit': false,
    //     'add': false,
    //     'print': false,
    //     'printPreview': false,    
    // });

    /** Styling for the parent modal container
     *
     * By default full-screen.
     * Use hook handleStyle to change the style.
     */
    const [ style, setStyle ] = useState({ class: 'modal__full' }) 

    // const verifyComponents = () => {
    //     //TODO:: 08112022 #EP || add ability to check for ALL things, not just table
    //     //-- If user provided a table, components accordingly based on what was provided.
    //     if(modifyComponents.table){
    //         // console.log("modal: modifiyComponents", modifyComponents)
    //         setComponents({
    //             ...components,
    //             ...modifyComponents  //-- All changed components
                    
    //             });
    //     }
    // };


    //--------------------------------------------------------------------------
    //-- USE EFFECT

    useEffect(() => {
        //TODO:: 082822 #EP || PLACEHOLDER:: Delete console.logs once done testing
        // console.log("Modal: useEffect: props", props);
        // console.log("pages: ", pages)
        // console.log("modal: ", modal)
        // console.log("header: ", header)
        // console.log("footer: ", footer)

        // console.log("nav_return: ", nav_return)
        // console.log("nav_params: ", nav_params)
        // console.log("nav_context: ", nav_context)
        // console.log("nav_location: ", nav_location)
        // console.log("nav_type: ", nav_type)

        setComponents(handleSetComponents(components, modifyComponents));
        setIsLoading(false);
        // console.log("modal/index.js modifyComponents: ", modifyComponents);
    } , [modifyComponents, pages, activePage]);






    //--------------------------------------------------------------------------
    //-- RETURNS
    
    // if( isLoading ) return <Loading />


    //--------------------------------------------------------------------------
    //-- Return component
    return (
        <div className='modal__parent' id='title'>
            <div className='modal__child'>

                
                {/* CONTROLS and Elements within Modal Parent */}
                <section className='modal__navigate_back'
                        onClick={() => handleShowModal()}
                >
                    
                    {/* Container holding ALL components for main modal container. */}
                    
                    {/* TODO:: Add save or delete this?
                    <span className='modal__save'>Save</span> */}
                    
                    
                    {/* BACK ARROW LEFT */}
                    <span className='modal__back_arrow'>
                        &#60; 
                    </span>
                    <span className='modal__path'>
                        { nav_return ? nav_return : ''}
                    </span>
                    
                    {/* <span className='modal__back_arrow'>&#8810;</span> */}
                    
                    { components.close_x.enabled 
                        ? <span className='modal__close_x'>&times;</span>
                        : null
                    }  
                </section>
                
                {header 
                    ? <Header 
                        title    ={ header?.title    }
                        subTitle ={ header?.subTitle }
                        />
                    : null
                }

                {/* {console.log("modifyComponents: ", modifyComponents)}
                {JSON.stringify(modifyComponents.table.data)} */}

                {/* Render Navigation based on pages sent in */}
               { pages 
                    ? <Navigation
                        title               ={ title }
                        subTitle            ={ subTitle }
                        subSubTitle         ={ subSubTitle }
                        identifier          = { identifier }
                        components          ={ components }
                        modifyComponents    ={ modifyComponents }
                        setComponents       ={ setComponents }
                        pages               ={ pages }
                        activePage          ={ activePage }
                        setActivePage       ={ setActivePage }
                    />
                    : null
               }
               {/* If pages, render pages */}
               { pages 
                    ? <Page
                        components          ={ components } 
                        //-- Ability to request a component 
                        //TODO:: 10022022 #EP || Do I need this handleGetComponent? ( not being used but should it be ? )
                        handleGetComponent  ={ handleGetComponent }
                        //-- The page that user is viewing
                        activePage          ={ activePage }
                        //-- Page content from page template that's been updated for unique content.
                        // pages               ={ modifyComponents.pages }
                        pagesReference      = { pages }
                        pages               ={ components.pages }
                        key                 ={uuidv4()}
                    />
                : null
                }

                {/* {console.log("pages: ", pages)} */}
                {/* {console.log("modifyComponents.pages", modifyComponents.pages)} */}

                
                {/*TODO: 08112022 #EP || Add TABS or sections within Modal based on
                                        what's provided and how it's configured.
                */}

              
                {/*TODO:: 081422 #EP || Get this dynamically */}
                {footer ? <Footer status={footer.status} /> : null}
            </div>
        </div>
    )
}

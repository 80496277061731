import React from 'react';
import Sensors from '../features/Sensors';

export default function SensorsPage() {
  return (
    <div className='page__container' > 
      <Sensors />
    </div>
  );
}

import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../App';

export default function Scheduler() {
    const {
        uuidv4,
        Loading,
    } = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState(true);
    
    useState(() => {
        setTimeout(() => {
                setPageLoading(false);
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
    }, []);

    if(pageLoading){
        return <Loading />
    } 
    else {
        return (
            <div className='page__div' >
                <section className='page__section' >
                    Placeholder scheduler. page
                </section>
            </div>
        )
    }
}

import React, {useContext} from 'react'
import { GlobalContext } from '../../../App';

import handleViewSensor from './handleViewSensor';

// import Tooltip from '../../../components/Tooltip';
import Detail from '../../../components/Detail';
import Dropdown from '../../../components/Dropdown';


import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';

//-- Utilities
import {
    getDateTimeFormatted,
    // isToday,
    isWithinDefinedHours,
    getTimePassed
} from '../../../utils/DateTime';


/** Provide array of Sensor Object to build cards for each sensor.
 *
 * @param {array} sensors
 * @returns `jsx`
 */
export default function HandleBuildCards(sensors = [] ) {

    const {
        Icon
    } = useContext(GlobalContext);

    const navigate = useNavigate();
    const location = useLocation();

  /** Dynamically building cards based on sensors state value.
         *
         */
        const cards = [];

        if(sensors) {

            //-- Populate page with a card for each sensor
            sensors.forEach((sensor, index) => {

                //-- EQUIPMENT
                const Equipment         = sensor?.Equipment[0];
                const E_Type            = Equipment?.type;
                const E_Name            = Equipment?.name;

                const E_Model           = Equipment?.model;
                const E_Serial          = Equipment?.serial;
                const E_Brand           = Equipment?.brand;
                const E_DateServiced    = Equipment?.date_serviced;


                // //-- ACCOUNT
                // const Account           = sensor?.Account[0];
                // const A_Name            = Account?.name;


                //-- BUSINESS
                const Business          = sensor?.Business[0];
                const B_Name            = Business?.name;
                // console.log(Business)


                //-- DISTRIBUTOR
                const Distributor       = sensor?.Distributor;

                //-- UNIT
                const _id               = sensor?._id;
                const name              = sensor?.name;
                const type              = sensor?.type;
                const label             = sensor?.label;
                const room              = sensor?.room;
                const floor             = sensor?.floor;
                const description       = sensor?.description;
                const config            = sensor?.config;
                const status            = sensor?.status;
                const date_created      = sensor?.date_created;
                const date_modified     = sensor?.date_modified;
                const date_installed    = sensor?.date_installed;
                const serial            = sensor?.serial;
                const make              = sensor?.make;
                const model             = sensor?.model;
                const firmware          = sensor?.firmware;
                const isAlert           = sensor?.isAlert;


                const syncInterval      = sensor?.syncInterval
                                            //-- How often unit is syncing in hours
                                            ? sensor?.syncInterval
                                            : process.env.REACT_APP_DEFAULT_SYNC_INTERVAL_HOURS;

                //-- LOGS
                const Logs              = sensor?.Logs;
                const lastLog           = Logs[Logs.length - 1];
                const lastLogDateTime   = lastLog?.date_created
                                            ? getDateTimeFormatted(lastLog?.date_created)
                                            : 'N/A';
                const payload           = lastLog?.payload
                                            ? JSON.parse(lastLog?.payload)
                                            : {
                                                'psi': 'N/A',
                                                'temperature': 'N/A',
                                                'humidity': 'N/A',
                                                'eventType' : 'N/A'
                                        };
                const psi               = payload?.psi;
                const temperature       = payload?.temperature;
                const humidity          = payload?.humidity;
                const eventType         = payload?.eventType;


                const metricData = [...sensor?.Metric]

                const metricDataSorted = metricData.sort((a, b) => {
                    return a?.label < b?.label ? -1 : 1;
                });

                //--------------------------------------------------------------
                //-- Build card for sensor
                cards.push(
                    <div className="card__wrapper" key={index}>


                        {/*--------------------------------------------------*/}
                        {/* HEADER */}

                        <div className="card__header">
                            {/*----------------------------------------------*/}
                            {/* ACCOUNT */}
                            {/* {A_Name} */}

                            {/*----------------------------------------------*/}
                            {/* BUSINESS */}
                            <h3 className='card__header_title'>
                                {B_Name}
                            </h3>


                            {/*----------------------------------------------*/}
                            {/* EQUIPMENT */}
                            <h4 className='card__header_details_mfg'>
                                {E_Brand} {E_Model} {E_Type}
                            </h4>
                            {/* <span className='card__header_details_label'>
                                {E_Name}
                            </span> */}


                            {/*----------------------------------------------*/}
                            {/* LOCATION */}

                            {/* <span className='card__header_details'>
                                <b>Room</b>: {room}
                            </span>
                            <span className='card__header_details'>
                                <b>Floor</b>: {floor}
                            </span> */}

                            {/*----------------------------------------------*/}
                            {/* DISTRIBUTOR */}
                        </div>

                        {/*--------------------------------------------------*/}
                        <span className='card__hr' />
                        {/*--------------------------------------------------*/}

                        {/*----------------------------------------------------------------
                        VIEW BUTTON
                            - Used to open up details page for unit sensor/unit
                        */}
                        <div  className='card__controls'
                                onClick={(e) => handleViewSensor(e, sensor, location, navigate)}
                        >
                            {/* <span className='detail__font'>
                                Detail &nbsp;
                            </span> 
                                <i className='detail_Information'>
                                <Icon
                                    name='infoBordered'
                                    background_c='transparent'
                                    svg_c='var(--clr-accent)'
                                />
                            </i> */}
                            {/* Chevron */}
                            <i  className='infoContinue'>
                                &#65310;
                            </i>
                        </div>

                        {/**----------------------------------------------------
                          * UNIT SENSOR STATS
                          *    - Contains key stats to display on sensors page.
                          *    - Built from Unit Metric Data
                          */}

                        <div className="card__content">
                            <h4 className='card__content_item_title'>
                                Metrics
                                <Detail content={'Values to monitor.'}
                                        // theme={'detail_information__light'}
                                />
                            </h4>
                            <span className='card__content_metrics'>
                                {metricData.map((metric, index) => {
                                    // console.log("metric.monitoring: ", metric.title, metric.monitor, metric.status, metric.unit)

                                    //-- Skip if disabled
                                    if(metric.status === 'disabled') return null

                                    //-- ELSE Build Metric Element
                                    return (
                                        <div className='card__content_metric' key={index}>

                                            {/* //TODO:: Add title and description? */}
                                                {/* Build  metric.description */}
                                           
                                            
                                            {/** Wrapper around the Metric's current State and the Icon  */}
                                            <span className='card__content_stat'>
                                                <h5 className='card__content_metric__title'>
                                                    {metric.label ? metric.label : 'N/A'}
                                                </h5>
                                                <i className='card__content_metrics_icon'>
                                                    <Icon   name={metric.title}
                                                            background_c='transparent'
                                                            svg_c='var(--clr-accent)'
                                                    />
                                                </i>
                                                {/** The Metric's last value and it's symbology.*/}
                                                <i  className='card__content_metrics_name_value' 
                                                    //-- Set the color of the value based value v min / max
                                                    data-value={metric.lastValue > metric.max ? 'high' : metric.lastValue < metric.min ? 'low' : 'normal'}
                                                >
                                                    {/* If Metric last value is not defined, set to N/A otherwise set as value */}
                                                    {   metric.lastValue === 'undefined' || metric.lastValue === null
                                                        ?   'N/A' :  metric.lastValue + ' ' + metric.unit
                                                    }
                                                </i>
                                                
                                                {metric.isAlert ? <span className='metric__isAlertCount'>{metric.alertCount}</span> : null}
                                            </span>

                                            {/*!!TODO: 20221221 #EP || Add full sections for each Card Metric Sub Section  */}
                                            {/* <section className='card__content_metric_sub__wrapper'>
                                                    <h5>Metrics</h5>
                                                    <i>
                                                        <i className='title'>Min:</i>
                                                        <i className='value'>{metric.min}</i>
                                                    </i>
                                                    <i>
                                                        <i className='title'>Max:</i>
                                                        <i className='value'>{metric.max}</i>
                                                    </i>
                                                    <i>
                                                        <i className='title'>Avg:</i>
                                                        <i className='value'>{metric?.avg ? metric?.avg : 'N/A'}</i>
                                                    </i>
                                            </section> */}

                                            {/*  ALERT AWARENESS */}
                                            {/* <span className='card__content_metric_sub'>
                                                <h5>Alerts</h5>
                                                <i>
                                                    <i className='title'>Alert Status:</i>
                                                    <i className='value'>{metric?.isAlert === 1 ? 'ALERT!' : 'N/A'}</i>
                                                </i>
                                                <i>
                                                    <i className='title'>Alert Count:</i>
                                                    <i className='value'>{metric?.alertCount ? metric?.alertCount : 'N/A'}</i>
                                                </i>
                                                <i>
                                                    <i className='title'>Alert Status:</i>
                                                    <i className='value'>{metric?.alertStatus ? metric?.alertStatus : 'N/A'}</i>
                                                </i>
                                            </span> */}
                                        </div>
                                    )
                                })}
                            </span>
                        </div> {/* END OF METRICS */}

                        {/*--------------------------------------------------*/}
                        <span className='card__hr' />
                        {/*--------------------------------------------------*/}

                        {/*--------------------------------------------------*/}
                        {/* LABELS */}
                        {/* <div className="card__content">
                            <h4 className='card__content_item_title'>
                                Tags
                                <Detail content={'Tags assigned to Unit'}
                                />
                            </h4>
                            <div className='card__content_labels_wrapper card__content_metrics'>
                                &#35;
                                {E_Name
                                    ?   <span className='card__header_details_label'>
                                            {E_Name}
                                        </span>
                                    : null
                                }
                            </div>
                        </div> */}

                        {/*-----------------------------------------------------------------
                        FOOTER / BOTTOM OF CARD
                            - Contains Status, Last Sync and Last Service Date and
                                Time.
                        */}
                        <div className='card__footer'>
                            {/* If Online or Offline styling and content. */}
                            { isWithinDefinedHours(lastLogDateTime, syncInterval)

                            //--------------------------------------------------
                            //-- IF ONLINE
                            ?   <span className='card__syncStatus'>
                                    <i className='card__syncStatus online'>
                                        {getTimePassed(lastLogDateTime)}
                                        {/* Online {getTimePassed("Sun Oct 23 2022 16:06:06 GMT+0000 (Coordinated Universal Time")} */}
                                    </i>

                                    <Detail content={
                                                lastLogDateTime !== 'N/A'
                                                ? <i>{getDateTimeFormatted(lastLogDateTime).split(',')[0]} {getDateTimeFormatted(lastLogDateTime).split(',')[1]}</i>
                                                : <i>No Sync Events</i>
                                            }
                                    />
                                </span>

                            //--------------------------------------------------
                            //-- IF OFFLINE
                            :   <span className='card__syncStatus'>

                                    {/* Offline Time passed on bottom left of card  */}
                                    <i className='card__syncStatus offline'>
                                        {   lastLogDateTime !== 'N/A'
                                            ? getTimePassed(lastLogDateTime) + ' ago'
                                            : ''
                                        }
                                    </i>

                                    {/* Last time sync or N/A */}
                                    <Detail content={
                                                lastLogDateTime !== 'N/A'
                                                ? <i>{getDateTimeFormatted(lastLogDateTime).split(',')[0]} {getDateTimeFormatted(lastLogDateTime).split(',')[1]}</i>
                                                : <i>No Sync Events</i>
                                            }
                                    />
                                </span> /* end of offline */
                            } {/* End of Footer If Else  */}
                        </div> {/* End of Footer  */}
                    </div>
                )
            })

            return cards;
        }

        else{
            return null
        }


}

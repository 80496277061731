import moment from 'moment';

export const getTimePassed = ( date ) => {
    //-- Provide a date/time and get duration since that date-time as a result in formatted str.
    
    var now = moment(new Date());                         //-- Get current time
    var duration = moment
                    .duration(now.diff( new Date(date))); //-- Get the diff between now and created date
    var results = duration.asHours();                     //-- Return value in hours
    
    
    //-- IF MINUTES
    if(results < 1) {
      const minutes = Math.trunc(results * 60);           //-- convert to minutes
      if(minutes <= 1)  return `${minutes} min`;   //-- if 1 min or less, return singular
      if(minutes > 1)   return `${minutes} mins`;  //-- if more than 1 min, return plural
    }

    //-- IF HOURS
    if(results < 24){ 
      const hours = Math.trunc(results); //-- convert to hours
      // console.log(hours)
      const minutes = Math.trunc((results - hours) * 60);
      return `${hours}h ${minutes}m`;
      // if(hours === 1) return hours + " h";         //-- if 1 hour, return singular
      // if(hours >= 1) return hours + " h";          //-- if 1 hour or less, return plural
      
    }

    //-- IF DAYS
    if(results > 24){ 
      const days = Math.trunc(results / 24);            //-- convert to days
      return `${days}d`;
      // if(days === 1)  return days + " day ago";         //-- if 1 day, return singular
      // if(days > 1)    return days + " day(s) ago";      //-- if 1 day or less, return plural
      
    }
    console.log(results);
    //-- If for some reason gets to this point, return nothing. ( shouldn't happen but being safe )
    return "NONE";
};

export const getDateYYYYMMDD = ( date ) => {
  //-- Provide a date-time, and get a fully-formatted YYYY-MM-DD date string for easy comparison, sorting, and filtering.
return  `${new Date(date).getFullYear()}`
      + `-`
      + `${new Date(date).getMonth() + 1}`
      + `-`
      + `${new Date(date).getDate()}`
};

export const getDateMMDDYYYY = ( date ) => {
  //-- Provide a date-time, and get a fully-formatted YYYY-MM-DD date string for easy comparison, sorting, and filtering.
  return  `${(new Date(date).getMonth() + 1) }`
        + '/'
        + `${new Date(date).getDate() + 1 }`
        + '/'
        + `${new Date(date).getFullYear()}`
};


export const getTimeZone = () => {
  return new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]

}
export const getTimeZoneLong = () => {
    //-- Get the current timezone of the browser
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateTimeFormatted = date => {
  //-- Provide a date/time and get formatted str.
  
  //-- Convert String to Date
  const dateObj = new Date(date);
  //-- Convert date to a local time
  return dateObj.toLocaleString()
};
export const getDateTimeFormattedArray = date => {
  //-- Provide a date/time and get formatted str.
  return moment(date).format('MMMM Do YYYY, h:mm:ss a').split(', ');
};

export const getDateTimeFormattedFull = date => {
  //-- Provide a date/time and get formatted str.
  return moment(date).format('MMMM Do YYYY, h:mm:ss a');
}

export const getDateFormatted = date => {
    //-- Provide a date-time, and get a fully-formatted month / date / year response
  return  `${new Date(date).getMonth() + 1}`
        + `/`
        + `${new Date(date).getDate()}`
        + `/`
        + `${new Date(date).getFullYear()}`
  ;
};

export const getDateFormattedNumbers = date => {
  //-- Provide a date-time, and get a fully-formatted month / date / year response
return  `${new Date(date).getMonth() + 1}`
      + `/`
      + `${new Date(date).getDate()}`
      + `/`
      + `${new Date(date).getFullYear()}`;
};
  
export const getDateFormattedWords = date => {
  return (
    new Date(date)
      .toLocaleDateString('en-US', 
      {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }
    ))
};


//-- Verify IF provided date is within specified range

export const isToday = date => {
  /** Send in a date to verify if date is today.
   * 
   * @param {Date} date - Date to verify
   * 
   * @returns {Boolean} - True if date is today, false if not.
  */

  //-- Get current date
  const today = new Date();
  //-- Get date to compare
  const compareDate = new Date(date);
  //-- Compare dates
  
  if(today.getDate() === compareDate.getDate()) return true
  if(today.getDate() !== compareDate.getDate()) return false
};

export const isWithinDefinedHours = (date, definedRange = Number() ) => {
  /** Send in a date and number of hours to verify if date is within 
   *    definedRange hours away or not.
   * 
   * @param {Date} date - Date to compare
   * @param {Number} definedRange - Number of hours to compare
   * 
   * @returns {Boolean} - True if date is within definedRange hours away, false if not.
  */

 
  
  const today = new Date();                             //-- Get current date
  const compareDate = new Date(date);                   //-- Get date to compare
  const diff = Math.abs(today - compareDate);           //-- Compare dates
  const hours = Math.ceil(diff / (1000 * 60 * 60));     //-- Convert to hours
  
  
  // console.log("getTimePassed", getTimePassed(compareDate))
  // console.log(`date: ${date}\ndefinedRange: ${definedRange}\ndiff: ${diff}\nhours: ${hours}`)
 
 
  //-- Compare hours
  if(hours <= definedRange) return true
  if(hours > definedRange) return false

};


// module.exports = {
//   getTimePassed,
//   getDateFormatted
// };

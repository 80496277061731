import React, { useContext, useEffect, useState } from "react";
// import { data } from "./context/data.test";
import Form from "./components/Form";
import ReadOnly from "./hooks/ReadOnly";
import EditRow from "./hooks/EditRow";
import Pagination from "./hooks/Pagination";
import Filter from "./hooks/Filter";

import { getDateFormatted, getDateTimeFormatted } from "../../utils/DateTime";


import './assets/css/style.css';
import { GlobalContext } from "../../App";

const Table = (props) => {

  const { 
    header,
    name,
    data,
    defineActions,
    defineResultsKeyword,
    results, /* How many results to show per page */
    toggleShowActions,
    toggleShowSearch,
    defaultSearchFilter,
    definedActions,
    defineResults
  } = props;

  const { uuidv4 } = useContext(GlobalContext);


  const [resultsKeyword, setResultsKeyword] = useState(
    defineResultsKeyword ? defineResultsKeyword : "Results"
  )
  const [tableData, setTableData] = useState( data );               //-- received from props or empty
  const [filteredTableData, setFilteredTableData] = useState(tableData);        //-- received from props or empty
  const [toggle, setToggle] = useState(null);                                   //-- for data ids
  const [search, setSearch] = useState("");                                     //-- for filtering data
  const [searchFilter, setSearchFilter] = useState( defaultSearchFilter
                                                  ? defaultSearchFilter
                                                  : header?.[0]?.field
  ); //-- Default search filter is first header value unless specified
  const [editData, setEditData] = useState({});                                 //-- Holds editing data
  const [order, setOrder] = useState("asc");                                    //-- for sorting
  const [number, setNumber] = useState(1);                                      //-- Page # to start on. used to go up/down
  

  //-- Actions to show in table
  const [actions, setActions] = useState( definedActions
                                          ? definedActions
                                          : []
  );
  const [showActions, setShowActions] = useState( toggleShowActions
                                                ? toggleShowActions
                                                : false
  ); //-- Show actions or not
  const [showSearch, setShowSearch] = useState( toggleShowSearch
                                              ? toggleShowSearch
                                              : true
  ); //-- Show search or not

  const [resultsPerPage, setResultsPerPage] = useState(
    results ? results : 5
  );

  const Delete = (del) => {
    const delData = tableData.filter((tbd) => {
      return del !== tbd.id;
    });
    setTableData(delData);
  };

  const Edit = (data) => {
    // for opening the editable row
    setToggle(data);
    setEditData(data);
  };

  const Cancel = () => {
    setToggle(null);
  };

  const Sort = (sort) => {
    if (order === "asc") {
      const sorted = [
        ...tableData.sort((a, b) => (a[sort] > b[sort] ? 1 : -1)),
      ];
      // console.log("Sort Request - Asc: ", sorted);
      setTableData(sorted);
      setOrder("desc");
    } else if (order === "desc") {
      const sorted = [
        ...tableData.sort((a, b) => (a[sort] < b[sort] ? 1 : -1)),
      ];
      // console.log("Sort Request - Desc: ", sorted);
      setTableData(sorted);
      setOrder("asc");
    }
  };
  
  //--  Pagination values
  const lastPost = number * resultsPerPage;
  const firstPost = lastPost - resultsPerPage;
  // const currentData = data.slice(firstPost, lastPost);
  const [currentData, setCurrentData] = useState([]);

  const [viewingNumber,] = useState(() =>{
    const int_ResultsPerPage = Math.ceil(filteredTableData.length)/currentData.length;
    const fullPages = Math.floor(int_ResultsPerPage);
    const partialPage = int_ResultsPerPage - fullPages;
    const partialPageCount = Math.ceil(filteredTableData.length) - (currentData.length)
    const getLastPageCount = ((Math.ceil(filteredTableData.length) / fullPages) - resultsPerPage ) * fullPages

    // TODO:: 081722 #EP || Get this to be a dynamic number or figure out a better way to calculate this
    return getLastPageCount
  }
  );
  
  useEffect(() => {
    // console.log("table/index.js: tableData: ", tableData);
    // console.log("table/index.js: data: ", data);
    // console.log("table/index.js: props: ", props);
    
  //   setTableData(data) //-- set table data based on props
  //   // setFilteredTableData(data) //-- set filtered table data based on props
  //   // setCurrentData(filteredTableData.slice(firstPost,lastPost))
    
    //-- If there's data, set the current data ( which is the results viewing based on page number )
    if(data.length > 0){
      setCurrentData(data.slice(firstPost,lastPost))
    }
    //-- Otherwise, set the current data to an empty array for awareness
    else{
      if(header?.length > 0){
        
        
        const emptyData = {}
        header?.map((h) => {
          // console.log(h.field)
          emptyData[h?.field] = "NO DATA"
        })
        setCurrentData([{
          ...emptyData
        }])
      }

      setTableData(data)
      // setFilteredTableData(data)
      // console.log('table: ', data)
    }
  }, [data, number, filteredTableData, firstPost, lastPost]);

  
  //----------------------------------------------------------------------------
  return (
    // <>
      <div  className="flex-column">
        <section className='table__title'>
          <h4 className='table__name flex-1000'>
            {name}
          </h4>
          {/* # of Entries {data.length} */}
          <div className='table__total flex-300 fw-semi-bold fs-500 ls-500'>
            {/* Page: {viewingNumber}
            <br />
            {resultsKeyword}: 
              {currentData.length > 0 ? currentData.length : 0}
              - {filteredTableData.length > 0 ? filteredTableData.length : 0} */}
            {/*TODO:: 08122022 #EP || Add Showing X - Y of Z */}
            {/* RESULTS: ## */}
            {resultsKeyword}: {filteredTableData.length ? filteredTableData.length : 0} 
            {/* {currentData.length > 0 ? currentData.length : 0 } -  
            {filteredTableData.length > 0 ? currentData.length : 0} */}
          </div>

          {/* SEARCH FILTER */}
          <div className='table__filter w-1000 flex-300'>
            {toggleShowSearch 
              ? <Filter
                  search={search}
                  setSearch={setSearch} 
                  tableData={tableData}
                  tableHeader={header}
                  setFilteredTableData={setFilteredTableData}
                  searchFilter={searchFilter}
                />
              : null
            }
          </div>
        </section>

        <form>
          <table 
            className="table"
            >
            <thead>
              <tr key={uuidv4()}>
                {header.map((th) => {
                  return (
                    <th 
                      key={uuidv4()}
                      // onClick={() => Sort(th.field)}
                    >
                      {th.value}
                    </th>
                  );
                })}
                { showActions
                  ? <th id="tr">Action</th> 
                  : null
                }
              </tr>
            </thead>
            <tbody key={uuidv4()}>
              {currentData.filter((val) => {
                  // console.log("currentData", currentData);
                  // console.log(val[searchFilter])
                  if (search === "")  return val;                               //-- if no search filter, return all data
                  if (val[searchFilter].toString().toLowerCase().includes(search)) return val;
                    
                    //TODO:: 08092022 #EP || Add other fields to search
                    //TODO:: 08092022 #EP || Add pagination update based on filters
                  
                  
                  
                }).map((rowData) => {
                  // console.log("rowData", rowData);
                  return (
                    // <>
                      toggle === rowData ? (
                        <EditRow
                          key={rowData.id+'edit'}
                          tableHeader={header}
                          tableData={tableData}
                          setTableData={setTableData}
                          Cancel={Cancel}
                          setEditData={setEditData}
                          editData={editData}
                          showActions={showActions}
                          actions={actions}
                          getDateFormatted={getDateFormatted}
                        />
                      ) : (
                        <ReadOnly
                          key={rowData.id +'read'}
                          data={rowData}
                          tableHeader={header}
                          currentData={currentData}
                          Edit={Edit}
                          Delete={Delete}
                          Sort={Sort}
                          showActions={showActions}
                          actions={actions}
                          getDateFormatted={getDateFormatted}
                          getDateTimeFormatted={getDateTimeFormatted}
                        />
                      )
                    // </>
                  );
                })}
            </tbody>
          </table>
        </form>
        
        <section className='table__pagination'>
          {
            // console.log(number)
            // console.log(setNumber)
            // console.log(resultsPerPage)
            // console.log(filteredTableData)
          }
          
          <Pagination
            number={number}                                   //-- Current Page #
            currentData={currentData}                         //-- Data filtered down
            setNumber={setNumber}                             //-- Set Page #
            // tableData={tableData}                             //-- All data
            filteredTableData={filteredTableData}             //-- Data filtered down
            resultsPerPage={resultsPerPage}                   //-- Number of results in each page
          />
        </section>

        {/* <h1 className="mx-3 mt-4">Add a contact</h1> */}
        {/* <Form tableData={tableData} setTableData={setTableData} /> */}
      </div>
    // </>
  );
};

export default Table;

import React, { useContext } from 'react';
import { GlobalContext } from '../../App';
import './assets/css/style.css'; //-- Login specific styles


export default function Footer() {
  const {
    Icon
  } = useContext(GlobalContext);

  return (
    <footer>
      <Icon 
        name='BairGeometric'
        background_c='transparent'
      />
      &copy; {process.env.REACT_APP_BRANDNAME} 2021 - 2022
    </footer>
  )
}

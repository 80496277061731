import React, { useContext, useState } from 'react';
import { Link, Navigate, NavLink, useLocation } from 'react-router-dom'; //-- routing 

import './assets/css/style.css';
import { GlobalContext } from '../../App';

//-- GQL
import { useQuery } from '@apollo/client';
import { USER_ID_GET } from '../../services/gql/Query'

//-- CONTEXT - Hardcoded data
//TODO:: 07262022 #EP || Onboard real data
// import Accounts from './context/json/accounts.json';
// import Notifications from './context/json/notifications.json';
// import Users from './context/json/users.json';
// import Subscriptions from './context/json/subscriptions.json';

//TODO:: 07262022 #EP || Review Data Sources and build out 
export default function Account( props ) {

  const {
    Loading,
    useAuthService,
    pageIndex,
    uuidv4
  } = useContext(GlobalContext);
  const [pageLoading, setPageLoading] = useState(true);

  // const [{ data, loading, error } ] = useQuery(USER_ID_GET);
  const { loading, error, data } =
    useQuery(USER_ID_GET, 
    { variables: { id: useAuthService.getUserId() }}
  );
  // const { loading, data, error } = useQuery( QUERY_BUSINESS_THOROUGH, { variables: { brandName: business_id_or_brand_name } } );

  
  useState(() => {
    setTimeout(() => {
            setPageLoading(false);
    }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
  }, []);

  if(pageLoading || loading) return <Loading />;

  if(error) return (
    <div className='page__div' >
      <section className='page__section' >
        <div className='page__section__header' >
          <h3>ERROR</h3>
        </div>
        <h4>Error Message:</h4>
        <p>{error.message}</p>
        <br />
        
        <h4>Error Payload:</h4>
        <p>{JSON.stringify(error)}</p>
      </section>
    </div>
  );
  
  return (
    
    <div className='page__div' >
      <section className='page__section' >
        <div className='page__section__header' >
            <h3 className='page__section__title'>
                Account Management
            </h3>
            <p className='page__section__summary' >
                NOTE: This is under active development. Please check back soon for updates.
            </p>
        </div>
          
        {/* pageIndex: {console.log(pageIndex?.account[0])} */}
        {/* {pageIndex?.account[0]?.icon} */}
        
        <div className='account__navigation'>          
          
          { Object.keys(pageIndex.account).map(( page,index ) => {
            return (
              <NavLink to={`/${pageIndex.account[page].name}`}
                        key={uuidv4()}
                        className={ 
                            ({ isActive }) => (isActive 
                                ? "navigation__link link__active"
                                : "navigation__link"
                        )}
                >
                {/* The Icon */}
                <span className='account__navigation__icon'>
                  {pageIndex.account[page]['icon']}
                </span>
                {/* The displayName value from the page index */}
                {/* <span className='navigation__pageName' >
                  {pageIndex?.account[page]?.['displayName']}
                </span> */}
              </NavLink>
              
            )
            })
          }
        </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>Username</td>
                <td>{data?.getUser?.username}</td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>{data?.getUser?.name_first}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{data?.getUser?.name_last}</td>
              </tr>
              <tr>
                <td>User Email</td>
                <td>{data?.getUser?.email}</td>
              </tr>
              <tr>
                <td>Account Name</td>
                <td> {data?.getUser?.Account[0]?.name}</td>
              </tr>
              <tr>
                <td>Account Type</td>
                <td> {data?.getUser?.Account[0]?.type}</td>
              </tr>
              <tr>
                <td>Account status</td>
                <td> {data?.getUser?.Account[0]?.status}</td>
              </tr>
              <tr>
                <td>Business Name</td>
                <td>{data?.getUser?.Business[0]?.name}</td>
              </tr>
              <tr>
                <td>Business Type</td>
                <td>{data?.getUser?.Business[0]?.type}</td>
              </tr>
              <tr>
                <td>Business Status</td>
                <td>{data?.getUser?.Business[0]?.status}</td>
              </tr>
              </tbody>
            </table>
          </div>
          
          {/* {JSON.stringify(data?.getUser)} */}
          {/* {
            data?.getUser?.Account.map((account, index) => {
              return (
                <div key={index}>
                  <h3>Account Name: {account.name}</h3>
                  <h3>Account Type: {account.type}</h3>
                  <h3>Account Status: {account.status}</h3>
                </div>
              )
            })
          } */}
      </section>
    </div>
  )
}




//TODO:: 091822 #EP || Reference concept and then remove the below concept data
// <section className='page__section' >
//         <div className='page__section__header' >
//             <h3 className='page__section__title'>
//                 Accounts
//             </h3>
//             <p className='page__section__summary' >
//                 This is a placeholder container for mgmt of user accounts.
//             </p>
//         </div>
//         {/* {JSON.stringify(Accounts)} */}
//         </section>

      
//         <section className='page__section' >
//           <div className='page__section__header' >
//               <h3 className='page__section__title'>
//                   Notification Settings
//               </h3>
//               <p className='page__section__summary' >
//                   This is a placeholder managing notification configuration.
//               </p>
//           </div>
//           {/* {JSON.stringify(Notifications)} */}
//         </section>
  
//         <section className='page__section' >
//           <div className='page__section__header' >
//               <h3 className='page__section__title'>
//                   Subscription Settings
//               </h3>
//               <p className='page__section__summary' >
//                   This is a placeholder managing Service subscriptions.
//               </p>
//             </div>
//             {/* {JSON.stringify(Subscriptions)} */}
//         </section>
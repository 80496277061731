import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../App';
import './assets/css/style.css'; // Import the CSS file for this component
import Unit from './components/Unit';
import Table    from '../../components/Table';

export default function Equipment() {

    const {
        uuidv4,
        Loading
    } = useContext(GlobalContext);


    const [pageLoading, setPageLoading] = useState(true);
    
    useState(() => {
        setTimeout(() => {
                setPageLoading(false);
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
    }, []);

    if(pageLoading){
        return <Loading />;
    }
    else {
        return(
            <div className='page__div' id='equipment__container' >
              
              <section className='page__section page__hero' >
                    <div className='page__section__header' >
                        <h3 className='page__section__title'>
                            Equipment
                        </h3>
                        <p className='page__section__summary' >
                            This page is used to manage all equipment within an
                            business.
                        </p>
                    </div>

                    {/* <div className='page__list__controls'>
                        <input  type='button'
                                className='fs-100 fw-bold ls-500 flex-100 page__list_controls_element'
                                value={'Add'}
                        />
                        <input  type='button'
                                className='fs-100 fw-bold ls-500 flex-100 page__list_controls_element'
                                value={'Update'}
                        />
                        <input  type='button'
                                className='fs-100 fw-bold ls-500 flex-100 page__list_controls_element'
                                value={'Filter'}
                        />
                        <input  type='button'
                                className='fs-100 fw-bold ls-500 flex-100 page__list_controls_element'
                                value={'Sort'}
                        />

                        
                         <input type='text' 
                                className='fs-100 fw-regular ls-300 flex-300 page__list_controls_element'
                                placeholder='Search'
                         />
                    </div> */}
                    <div className='page__list'
                         id='equipment__list' 
                    >
                      {/* <Unit /> */}
                      <Table 
                        name='Equipment'
                        data={equipmentTestData}
                        header={equipmentHeader}
                        
                      />
                    </div>
                </section>
            </div>
        )
    }
}



const equipmentHeader =[
    { field: 'id',              value: 'ID'             },
    { field: 'name',            value: 'Name'           },
    { field: 'type',            value: 'Type'           },
    { field: 'description',     value: 'Description'    },
    { field: 'location',        value: 'Location'       },
    { field: 'status',          value: 'Status'         },
    // { field: 'ServiceHistory',  value: 'ServiceHistory' },
    { field: 'sensor_ID',       value: 'Sensor ID'      },
    { field: 'date_added',      value: 'Date Added'     },
    // { field: 'added_by',        value: 'added_by'       },
    { field: 'date_modified',   value: 'Date Modified'  },
    // { field:  'modified_by',    value:  'Modified By'   }
];
const equipmentTestData = [
    {
        id: 1,
        name: 'Equipment 1',
        type: 'Equipment Type 1',
        description: 'This is a description of Equipment 1',
        location: 'Location 1',
        status: 'Active',
        ServiceHistory: [], /* This is a database value we're going to get pulled from below */ 
        sensor_ID: null, /* a unit without a sensor */
        date_added: '2020-01-01',
        added_by: 'User 1',
        date_modified: '2020-01-01',
        modified_by: 'User 1',
    },
    {
        id: 2,
        name: 'Equipment 2',
        type: 'Equipment Type 1',
        description: 'This is a description of Equipment 2',
        location: 'Location 2',
        status: 'Active',
        ServiceHistory: [], /* This is a database value we're going to get pulled from below */ 
        sensor_ID: null, /* a unit without a sensor */
        date_added: '2020-01-01',
        added_by: 'User 1',
        date_modified: '2020-01-01',
        modified_by: 'User 1',
    }
];


const technicianTestData =[
    {
        id: 1,
        name:   'John Doe',
        email:  'john.doe@udg.com',
        phone:  '1234567890',
        title:  'Service Technician',
        role:   'Technician',
    }
]
import React, { useEffect } from 'react'

export default function NavAlerts({pageDisplayName,alertCount}) {

  useEffect(() => {
    // console.log(pageDisplayName,alertCount)
  }, [pageDisplayName,alertCount])
  return (
    <span 
        className={
            pageDisplayName !== 'Alerts' 
                            ? 'hidden' 
                            : alertCount > 0
                            ? 'nav__alert_count' 
                            : 'hidden'
          }
        id={`nav__alert_count_${pageDisplayName}`}
      >
      {(() => {
        switch (pageDisplayName) {
          case 'Alerts' :  return (alertCount > 0 ? alertCount : null);
          default       :  return null;
        }
      })()}
    </span>
  )
}

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../App';



//------------------------------------------------------------------------------
//-- COMPONENTS

import Dropdown     from '../components/Dropdown';
import Icon         from '../components/icon';
import List         from '../components/List';
import Loading      from '../components/Loading';
import Modal        from '../components/Modal';
import Search       from '../components/Search';
import Table        from '../components/Table';
// import Notification from '../components/Notification';

//------------------------------------------------------------------------------
//-- HOOKS

import UseHandleSubmitTest  from '../hooks/useHandleSubmit.test.js';
import useHandleAccess      from '../hooks/useHandleAccess';
import useHandleBanner      from '../components/Banner/hooks/useHandleBanner';
import useIsMounted         from '../hooks/useIsMounted';
// import useIsLoaded          from '../hooks/useIsLoaded';

//------------------------------------------------------------------------------
//-- COMPONENT
export default function Sandbox() {

    const [userType, setUserType ] = useState(undefined)
    // const [hasAccess, setHasAccess] = useState(undefined)

    const {
        uuidv4,
        user, //TODO:: 082822 #EP || Convert to User instead and make request
        Pages
    } = useContext(GlobalContext);
    
    const navigate  = useNavigate();
    const location  = useLocation();
    const isMounted = useIsMounted();
    
    // const [showModal, setShowModal ] = useState(true);

    
    const { hasAccess, banner, redirecting } = useHandleAccess({ user , accessLevel: 'admin' },[]);

    
    //--------------------------------------------------------------------------
    //-- useEffect

    //-- Rerender once hasAccess result is provided or if location changes ( navigated away )
    // useEffect(() => {
    //     // console.log(user)
    //     if(isLoaded) console.log("isLoaded: ", isLoaded)
    // }, [hasAccess, location]);


    useEffect(() => {
        // if(isLoaded) console.log("isLoaded: ", isLoaded)
        // console.log("isLoaded: ", isLoaded)
        // console.log("isMounted: ", isMounted)
        
    //   return () => {
    //   }
    }, [hasAccess, location]);
    

    

    //--------------------------------------------------------------------------
    //-- Modal Management 

    const handleShowModal = (e) => {
        document.getElementById('hide-modal').classList.toggle('hide-modal');
        // const testing = document.getElementById('hide-modal');
        // console.log(testing,e)
    }


    //--------------------------------------------------------------------------
    //-- RETURN
    if(hasAccess === undefined){
        return (
            <div className='page__container'>
                Checking Access level
                {hasAccess}
            </div>
        )
    } 
    else if( hasAccess === false ){
        
        return (
            <div className='page__container'>
                {redirecting}
                {banner}
            </div>
        )
    }
    // else if(isLoaded === false){
    //     <div className='page__container'>
    //         Checking Access level
    //         {hasAccess}
    //     </div>
    // }
    else if(hasAccess === true){
        return (
            <div className='page__container page__aside'
                 key={uuidv4()}
            >
                {banner}
                <div className='page__div'>
                    
                    <section className='page__section' >
                        <div className='page__section__header' >
                            <h3 className='page__section__title fw-bold fs-700 ls-500'>
                                Development Sandbox
                            </h3>
                            <p className='page__section__summary' >
                                Landing Page for Testing all things related to the 
                                app accessible by the admin team.
                            </p>
                        </div>
                    </section>

                    <section className='page__section p-500' >
                        <div className='page__section__header'>
                            <h4>Component: Search Box</h4>
                        </div>
                        <div className='page__div m-a'>
                            <Search />
                        </div>
                    </section>

                    <section className='page__section p-500' >
                        <div className='page__section__header'>
                            <h4>Component: Icon</h4>
                        </div>
                        <div className='page__div'>
                            <span className='p-500'>
                                Icon - no args
                                <Icon />
                            </span>
                            <span className='p-500'>
                                Icon - Account
                                <Icon name="account"/>
                            </span>
                            <span className='p-500'>
                                Icon - Account - Transparent
                                <Icon name="account" background_c="transparent" />
                            </span>
                        </div>
                    </section>

                    <section className='page__section p-500' >
                        <div className='page__section__header'>
                            <h4>Component: Loading</h4>
                        </div>

                        <div className='page__div m-a'>
                            <h5> Triangle - Using Icon 'triangle'</h5>
                            <div class="loader-triangle-3-sides">< br/>
                                <Icon name="triangle" background_c="transparent" />
                            </div>
                        </div>
                        
                        <div className='page__div m-a'>
                            <Loading />
                            {/* <Notification /> */}
                            isMounted: {JSON.stringify(isMounted)}
                            
                        </div>
                    </section>

                    

                    
                    
                    {/* <UseHandleSubmitTest />  //TODO:: 07312022 #EP || Commented out because not working, add back later. */}
                    
                    {/* <section className='page__section'>
                        <h4 className='fw-bold fs-600'>List</h4>
                        <List />
                    </section> */}

                    {/* <section className='page__section'>
                        <h4 className='fw-bold fs-600'>Dropdown</h4>
                        <Dropdown />
                    </section> */}

                    {/* TABLE */}
                    {/* <section
                        className='page__section'
                        key={uuidv4()}
                    >
                        < Table
                            name={tableName}
                            data={tableData}
                            header={tableHeader}
                            results={5}
                            defaultSearchFilter={'fullName'}
                            toggleShowSearch={false}
                            toggleShowActions={false}
                            definedActions={[]}
                        />
                    </section> */}

                    {/* MODAL */}
                    {/* <section
                        className='page__section'
                        key={uuidv4()}
                    >
                        <button 
                            onClick={() => handleShowModal(document.getElementById('modal-test'))}
                        >
                            Open Modal
                        </button>
                        <span id='hide-modal'>
                            < Modal
                                title={"Testing Modal"}
                                tagline={"An example modal."}
                                definedActions={[]}
                                modifyComponents={modifyComponents}
                                handleShowModal={handleShowModal}
                            />
                        </span>
                    </section> */}

                </div>
            </div>
        )
        }
}




const tableHeader = [
    { 
        field: 'id', 
        value: 'ID' 
    },
    { 
        field: 'fullName', 
        value: 'Full Name' 
    },
    { 
        field: 'username', 
        value: 'Username'
    },
    { 
        field: 'phoneNumber', 
        value: 'Phone Number'
    },
    { 
        field: 'website', 
        value: 'Website'
    },
    { 
        field: 'companyName', 
        value: 'Company Name'
    },
    { 
        field: 'email', 
        value: 'Email'
    }
];
const tableData = [
    {
        id: 1,
        fullName: "Erik Plachta",
        username: "EP",
        email: "erik@bair.app",
        phoneNumber: "1-234-567-8901",
        website: "bair.app",
        companyName: "Bair",
      },
      {
        id: 2,
        fullName: "Emily Plachta",
        username: "Em",
        email: "emily@bair.app",
        phoneNumber: "1-234-567-8901",
        website: "bair.app",
        companyName: "Bair",
      },
      {
        id: 3,
        fullName: "Erik Plachta",
        username: "EP",
        email: "erik@bair.app",
        phoneNumber: "1-234-567-8901",
        website: "bair.app",
        companyName: "Bair",
      },
      {
        id: 4,
        fullName: "Emily Plachta",
        username: "Em",
        email: "emily@bair.app",
        phoneNumber: "1-234-567-8901",
        website: "bair.app",
        companyName: "Bair",
      },
      {
        id: 5,
        fullName: "Erik Plachta",
        username: "EP",
        email: "erik@bair.app",
        phoneNumber: "1-234-567-8901",
        website: "bair.app",
        companyName: "Bair",
      },
      {
        id: 6,
        fullName: "Emily Plachta",
        username: "Em",
        email: "emily@bair.app",
        phoneNumber: "1-234-567-8901",
        website: "bair.app",
        companyName: "Bair",
      }
      
];

const tableName = 'Test Data Set'
const tableRows = 5;


const modifyComponents = {
    //-- send in table data but have it hidden by default with enabled default
    "table": {
        enabled: false,
        header: tableHeader,
        data: tableData,
        name: tableName,
        rows: tableRows,
        results: 5,
        defaultSearchFilter:'fullName',
        toggleShowSearch: false,
        toggleShowActions: false
    }
}
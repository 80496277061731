import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../App';
import './assets/css/style.css'; // Import the CSS file for this component

//-- Graph QL
import { useQuery } from '@apollo/client';
import { UNITS_GET } from '../../services/gql/Query'

//------------------------------------------------------------------------------
//-- FUNCTION
export default function Dashboard() {

    const {
        uuidv4,
        Loading,
        Chart,
        setSensors
    } = useContext(GlobalContext);
    
    const [pageLoading, setPageLoading] = useState(true);
    
    
    useState(() => {
        setTimeout(() => {
                setPageLoading(false);
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
    }, []);


    //--------------------------------------------------------------------------
    //-- QGL Queries
    const { loading, error, data } = useQuery(UNITS_GET, 
        //TODO:: 09262022 #EP || Add proper variable here??
        { variables: { businessId: "tbd" }}
      );

    const handleUnitsData = () => {

        clearTimeout();
        setTimeout(() => {
            //-- if still loading, wait duration and then try again
            if(loading) handleUnitsData()
            
            // console.log("hit timeout")
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
        
        
        // console.log("getSensorData(): called", '\n\tloading: ', loading, '\n\terror: ', error,'\n\tdata: ', data);
        
        if(data) {
            // console.log("HandleUnitData: called")
            setSensors(data?.getUnits)
        }
        else {
            //TODO:: 10012022 #EP || Add proper error handling and comments
            console.log('Else: data: ', data)
        }
    };

    //--------------------------------------------------------------------------
    //-- USE EFFECT


    useEffect(() => {
        // console.log("Use Effect")
        // console.log(sensors)
        //-- Once data is loaded, set the sensors state
        if ( loading === false  && data) {
            
            handleUnitsData(data?.getUnits);
            // setSensors(data?.getUnits)
        }
        // console.log()
    }, [ data, error, loading ]);


    console.log(data)
    //--------------------------------------------------------------------------
    //-- RETURNS

    if(loading || !data)    return <Loading />;
    if( error )             return "Error: " + error.message;

    
    return (
        <div className='page__div dashboard' >
            <section className='page__section' >
                <div className='page__section__header' >
                    <h3 className='page__section__title'>
                        Dashboard
                    </h3>
                    <p className='page__section__summary' >
                        This page is currently empty as I'm working on the Sensors and 
                        Equipment pages. This will likely be one of the LAST page I 
                        complete, but I do expect to work on it on and off throughout 
                            the project. 
                        <br /><br /> 
                        - Erik @08/13/2022 
                    </p>
                </div>
            </section>

            <section className='page__section'>
                {/* {JSON.stringify(data?.getUnits)} */}
                <ul>
                    <li>Total Units: {data?.getUnits.length} </li>
                </ul>
            </section>
            
        </div>
    )
}
import React from 'react';
import Scheduler from '../features/Scheduler';

export default function SchedulerPage() {

  return (
    <div className='page__container' >
      <Scheduler />
    </div>
  )
}

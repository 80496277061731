//-- A collection of ALL Email related functions

//-- verify if an email, return all lowercase.
export function isValidEmail(email) {
    /* Send in an email address and returns true if it is valid.
    * 
    * @param {string} email - email address to be verified
    * @returns {boolean} - true if email is valid, false if not
    */
   
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../App";
import { useNavigate, useLocation } from "react-router-dom";
// import { getDateTimeFormatted } from "../../../utils/DateTime";

const ReadOnly = ( props ) => {
  const {
    data,
    Edit,
    Delete,
    tableHeader,
    Sort,
    showActions,
    actions,
    getDateTimeFormatted
  } = props;
  
  const { uuidv4 } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log("table/readonln.js: data", data);
    // console.log("View: ", showActions)
    // console.log("actions: ", actions)
  }, [data]);

  function handleViewSensor(e, id){
    e.preventDefault();
    console.log("handleViewSensor: ", id)
    navigate(`/s/${data?._id}`,
              { replace: false,
                  state: {
                    'data': data,
                    'history' : {
                      pathname: location.pathname,
                      saerch: location.search
                    }
                    }
              }
    );
  }
 
  return (
    <tr>
        {/* <> */}
        {tableHeader.map((th) => {
          // console.log("table/readonln.js: th", th);
          // console.log("{data[th.field]}", data[th.field])
          // if(th.field === 'Logs') console.log( data[th?.field] = data[th?.field].length);
          const thValue   = th?.value
          const field     = th?.field;
          let   dataValue = data[th?.field];
          // console.log(th)
          
          
          // if(field !== 'Logs') value = data[th?.field];
          if(field === 'Logs') dataValue  = data[th?.field].length;
          if(field === 'LastSyncDate') dataValue  = data?.Logs?.length > 0
                                                  ? getDateTimeFormatted(data?.Logs[data.Logs.length - 1 ].date_created)
                                                  : 'N/A'
          ;
          if(field === 'LastSyncData') dataValue  = data?.Logs?.length > 0 
                                                  ? ( Object.keys(JSON.parse(data?.Logs[data.Logs.length - 1 ].payload)).map((key, index) => {
                                                    const varValue = JSON.parse(data?.Logs[data.Logs.length - 1 ].payload)[key];
                                                    switch(key){
                                                      case 'temperature':
                                                        return <span key={uuidv4()}>{varValue}°F<br/></span>
                                                      case 'humidity':
                                                        return <span key={uuidv4()}>{varValue}%<br/></span>
                                                      case 'psi':
                                                        return <span key={uuidv4()}>{varValue} PSI</span>
                                                      // case 'battery':
                                                      //   return <span key={uuidv4()}>{varValue}%</span>
                                                      default:
                                                        return null//<span key={uuidv4()}>{varValue}</span>
                                                    }}))
                                                        // const varModifier = switch(key){
                                                    //   case 'temperature': return '°C';
                                                    //   case 'humidity': return '%';
                                                    //   case 'pressure': return 'hPa';
                                                    //   case 'battery': return '%';
                                                    //   default: return '';
                                                    // }
                                                    
                                                    // return (
                                                    //   <div key={uuidv4()}>
                                                    //     {/* <span>{key}: </span> */}
                                                    //     {/* <span>{JSON.parse(data?.Logs[data.Logs.length - 1 ].payload)[key]}</span> */}
                                                    //     {varValue}
                                                    //   </div>
                                                    // )
                                                  // }))
                                                  : 'N/A'
          ;
          // console.log((data?.Logs[0].date_created))

          // if(field !== 'Logs')
          return (
            <td key={uuidv4()}>
              <span className='table__cell_value'
                    // key={uuidv4()}
              >
                {/* {th.field === 'datetime' 
                  ? getDateTimeFormatted(data[th.field]).split(', ')[0]
                  : data[th.field]
                } */}
                {/* hi */}
                {dataValue}
              </span>
              <span className='table__header_inline'
                    // key={uuidv4()}
                    // onClick={() => Sort(th.field)}
              >
                <i>
                  {thValue}
                </i>
              </span>
            </td>
          );
        })}
        
        {showActions
          ? actions.map((action) => {
            // console.log(action)
            
            //-- EDIT BUTTON
            if(action === 'Edit'){
              <button className="btn p-200"
                      // key={uuidv4()}
                      onClick={() => Edit(data)}
              >
                Edit
              </button>
            }
            //-- DELETE BUTTON
            if(action === 'Delete'){
              return( 
                <button className="btn p-200"
                        // key={uuidv4()}
                        onClick={() => Delete(data.id)}
                >
                  Delete
                </button>
              )
            }
            //-- VIEW SENSOR BUTTON
            if(action === "View Sensor"){
              return ( 
                <td className='table__view-sensor' key={uuidv4()}>
                  <button className="btn p-200 table__view-sensor-btn"
                          // key={uuidv4()}
                          onClick={(e) => handleViewSensor(e, data)}
                  >
                    View
                  </button>
                </td>
              )
            }
          })
          : null
        }
        
        {/* 
        
          <td className='flex-row-center m-a'>
                  <button className="btn btn-dark p-200"
                          onClick={() => Edit(data)}
                  >
                  Edit
                </button>
                <button className="btn btn-danger p-200"
                        onClick={() => Delete(data.id)}
                >
                  Delete
                </button>
            </td>
        
        */}
      </tr>
  );
};

export default ReadOnly;

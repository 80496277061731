// import React, { Component } from 'react';
import decode from 'jwt-decode'; //--> https://www.npmjs.com/package/jwt-decode
import User from '../context/User';
// import { gql } from '@apollo/client';



class Auth {

  //----------------------------------------------------------------------------
  //-- Getting JWT Data

  //-- Get token if user has one
  getCurrentUserToken() {
    return decode(this.getToken());
    // return 
    //TODO: 08272022 #EP || Add decode for encryption
  }

  getAccountId(){
    try{
      const state =  JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE))
      console.log("state: ", state)
      return state['account']['_id']
    }
    catch (error){
      console.log("//-- Failed to get ID from jwt: error: ", error)
    }
  }
  
  getUserId(){
    try{
      const state =  JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE))
      // console.log("state: ", state)
      return state['user']['_id']
    }
    catch (error){
      console.log("//-- Failed to get ID from jwt: error: ", error)
    }
  }

  getCurrentUser(){
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE));
  }

  getUser(){
    /** Extract user data from JWT to be used by application
     * 
     * @returns {Object} user
     */
    return {
      account: JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE))?.account,
      user: JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE))?.user,
      business: JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE))?.business,
      // etc : JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE))
    };
  }

  //TODO:: 08222022 #EP || Add proper functions here per needs. Commented out for now.
  getBusinessId(){
    let results = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE));
    
    return results?.account?.id;
  }

  getBusinessName(){
    let results = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)); 
    return results?.business?.name;
  }

  getAccountName(){
    let results = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)); 
    return results?.account?.name;
  }

  getAccountType(){
    let results = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)); 
    return results?.account?.type;
  }

  // Checks if there is a saved token and it's still valid
  isLoggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token); // hand-waiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  getTokenString(){
    console.log("getTokenString!!!!!!!!")

    // return String(this.getToken());
    return localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE).id_token;
  }

  getToken() {
    // Retrieves the user token from localStorage process.env.REACT_APP_LOCALSTORAGE jwt storage
    
    //-- Attempt to get token if it exists in JWT local Storage
    try{
      const jwtData = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE);
      const jwtDataJSON = JSON.parse(jwtData);
      let id_token = jwtDataJSON.id_token;
      // console.log("id_token: ", typeof(id_token))
      // console.log("id_token: ", id_token)
      return id_token;
    }
    //-- If does not, just return false
    catch {
      return false;
    }
  }


  async setLocalStorage(data) {
    /** Takes in JWT data and sets it to local storage.
     * 
     */
    
    console.log("Auth.setLocalStorage().data: ", data)

    try {
      //-- extracting JWT token
      const idToken       = data?.token   ? data?.token   : undefined;

      //-- deconstructing the payload sent in via the user Model
      const user          = data?.user        ? data?.user        : data;
      const _id           = user._id          ? user._id          : undefined;
      const alerts        = user.Alerts       ? user?.Alerts      : undefined;
      const alert_count   = user.alert_count  ? user?.alert_count : undefined;
      const configuration = user.configuration? user?.configuration: undefined;
      const date_created  = user.date_created ? user.date_created : undefined;
      const name_first    = user.name_first   ? user.name_first   : undefined;
      const name_last     = user.name_last    ? user.name_last    : undefined;
      const status        = user.status       ? user.status       : undefined;
      const type          = user.type         ? user.type         : undefined;

      const account       = user?.Account[0]  ? user?.Account[0]  : undefined;
      const account_id    = account           ? account._id       : undefined;
      const account_name  = account           ? account.name      : undefined;

      const business      = user?.Business[0] ? user?.Business[0] : undefined; 
      const business_id   = business          ? business._id      : undefined;
      const business_name = business          ? business.name     : undefined;

      // console.log("dataLogin: ",data)

      const jwtData = {
        "id_token"        :  idToken,
        auth: {
          "type"          : type,
          "id_token"        :  idToken
        },
        user:{
          "_id"           : _id,
          "name_first"    : name_first,
          "last_last"     : name_last,
          "date_created"  : date_created,
          "status"        : status,
          "configuration" : configuration ? JSON.parse(configuration) : {}
        },
        account: {
          id              : account_id,
          name            : account_name
        },
        business: {
          id              : business_id,
          name            : business_name
        },
        alerts: {
          "alerts"        : alerts ? [...alerts] : [], //-- array of objects 
          "alert_count"   : alert_count //-- number
        }
      };

      localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE, JSON.stringify(jwtData));

    }
    catch (error) {
      console.log("Error: ", error)
    }

    
  }

  //----------------------------------------------------------------------------
  //-- Setting JWT Data

  async sessionSync(dataSession){
    // Extract JWT Arch and Saves user token to localStorage
    this.setLocalStorage(dataSession);


  }
  
  async login(dataLogin) {
    // Extract JWT Arch and Saves user token to localStorage
    const status = this.setLocalStorage(dataLogin);

    if(status){
      window.location.assign('/');
    }
    else {
      console.log("Error: ", status)
    }
  }

  //----------------------------------------------------------------------------
  //-- Deleting JWT Data and telling server logged of


  async logout() {
    // Clear user token and profile data from localStorage
    // axios.defaults.headers.common["Authorization"] = null;

    try {
      const logout = localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE);  
      return true;
    }
    catch (error) {
      console.log("Error: Cache Not Removed", error)
      return false;
    }
  }
}

export default new Auth();

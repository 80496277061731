import React, { useState } from "react";
import { getDateMMDDYYYY } from "../../../utils/DateTime";

const Filter = ( props ) => {

  const {
    search,
    setSearch,
    tableData,
    tableHeader,
    setFilteredTableData,
    searchFilter
  } = props;
  
  
  const handleChangeFilter = (e) => {
    var searchString = String();
    
    //-- format into a date that can be used to compare
    if(searchFilter === 'date') searchString = getDateMMDDYYYY(e.target.value);
    //-- otherwise not yet a special case so just lowercase the string
    else  searchString = e.target.value.toLowerCase();
    
    if(searchString === 'NaN/NaN/NaN')
    {
      searchString = undefined;
    }

    // console.log(searchString)
    setSearch(searchString);
  };

  //-- Handle event for when search value is changed
  const handleSetFilteredTableData = (e) => {
    const searchString = e.target.value.toLowerCase();
    
    console.log("handleSetFilteredTableData")

    var filteredTableData = [];
    
    //! TODO:: This is where I've left off -- where the filter is NOT working properly.
    if( !searchString ) filteredTableData = tableData;
    
    if(searchFilter === 'date') {
      filteredTableData = tableData.filter((item) => {
        return getDateMMDDYYYY(item[searchFilter]).includes(searchString);
      });
    } 

    if(searchFilter !== 'date' && searchString !== undefined) {
      filteredTableData = tableData.filter((result) => {
        return result[searchFilter].toString().toLowerCase().includes(searchString); 
      });
    } 
    // // if(searchFilter !== 'date') 
    // });

    console.log(tableData)
    console.log(filteredTableData)
    setFilteredTableData(filteredTableData);
  };



  const handleFilterEvents = (e) => {
    e.preventDefault();
    handleChangeFilter(e);
    handleSetFilteredTableData(e);
  }    

  const headerFilterName = tableHeader.filter((header,index) => {
    if( header.field === searchFilter ) return header.value
    
  });





  //----------------------------------------------------------------------------
  //-- RETURNS

  //-- no filter defined so disable feature
  if( searchFilter === undefined ) return null;
  
  //-- date filter so return date-picker
  if (searchFilter === 'date') {
    return (
      <>
          <input
            // type="date"
            type="date"
            className="w-1000 flex-700"
            // value={search}
            onChange={handleFilterEvents}
            // autoFocus={true}
          />
      </>
    );
    
  }

  return (
    <>
        <input
          // type="date"
          type="text"
          className="w-1000 flex-700"
          placeholder={`Filter results by ${headerFilterName[0]?.value}`}
          value={search}
          onChange={handleFilterEvents}
          // autoFocus={true}
        />
    </>
  );
};

export default Filter;
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../App';
import Table from '../../Table';
import Section from './section';

export default function Page( props ) {
    //- A page within a modal 

    const {
        // components,
        // handleGetComponent,
        activePage,
        pages,
        pagesReference
    } =props;

    const {
        uuidv4
    } = useContext(GlobalContext)

    
    const [thisPage, setThisPage] = useState(pagesReference[activePage]);

    // const {
    //     title,
    // } = thisPage;
    
    useEffect(() => {
        // console.log("page.useEffect(): activePage, pages[activePage]: ",activePage, pages[activePage]);
        
        // console.log(components.table.data)
        return(
            setThisPage(undefined)
        )
    }, [activePage, pages, thisPage])
        
    
    //-- Don't render a page if there is not one active.
    if(pages[activePage] === undefined)     return null;
        
    //-- Render the active page.
    return (
        // <div>
            <section className='modal__section modal__page'
                    key={uuidv4()}
            >
                {/* If the page has a title, render it. */}
                {pages[activePage].title & pages[activePage].showTitle
                    ?  <h5 className='fs-700 fw-bold ls-500'>
                            {pages[activePage].title}
                        </h5>
                    :   null
                }
                {/* If the page has a subTitle render it */}
                {pages[activePage].subTitle 
                    ?   <span className='fs-300 clr-light-500 ls-700'>
                            {pages[activePage].subTitle}
                        </span>
                    :   null
                }


                {Object.keys(pages[activePage].components).map( ( component, index ) => {
                    const thisComponent = pages[activePage].components[component];
                    if(component === 'table'){
                        return (
                            <section
                                className='modal__page__section'
                                key={uuidv4()}
                            >
                                {/* {console.log(handleGetComponent( pages[activePage].components, thisComponent ))} */}
                                {/* //TODO:: 10022022 #EP || Add toggle to show-hide log data, but for now leaving as is. */}
                                {/* <button className='modal__page__section_toggle fs-600 fw-semi-bold'>
                                   Show {thisComponent.name}
                                </button> */}

                                <div className='modal__page__div' key={uuidv4()}>
                                    <Table 
                                        key                  = { `${index}-${component}` }
                                        name                 = { thisComponent.name } //-components.table.name }
                                        data                 = { thisComponent.data } //-components.table.data }
                                        header               = { thisComponent.header } //-components.table.header }
                                        results              = { thisComponent.results } //-components.table.results }
                                        defaultSearchFilter  = { thisComponent.defaultSearchFilter } //-components.table.defaultSearchFilter }
                                        toggleShowSearch     = { thisComponent.toggleShowActions } //-components.table.toggleShowActions }
                                        toggleShowActions    = { thisComponent.toggleShowActions } //-components.table.toggleShowActions }
                                        definedActions       = { thisComponent.definedActions }    //-components.table.definedActions }   
                                    />
                                </div>
                            </section>
                        )
                    }

                    else if(component === 'chart'){
                        console.log("component.chart: ", component);
                    }
                    else if(component === 'section'){
                        console.log("component.section: ", component);
                        return(
                            <Section props={thisComponent} key={uuidv4()}/>
                        )
                        //     // <span className='page__section' key={uuidv4()} >
                        //         {/* TODO:: 101522 #EP || Verify h6 is proper */}
                        //         {/* <h6>{thisComponent.title}</h6> */}
                        //         {/* TODO:: 101522 #EP || Verify p is proper */}
                        //         {/* <p>{thisComponent.subTitle}</p> */}
                                
                                
                        //         {/* Switch to manage each page uniquely 
                        //             TODO:: 101522 #EP || Add a better way to manage this once it it's verified working
                        //         */}
                        //         // <Section props={thisComponent} } />
                        //         {/* {(() => { 
                        //             switch(thisComponent.title){
                        //                 case 'Dashboard'    :  return(<Section props={thisComponent} key={uuidv4()} />);
                        //                 case 'Business'     : return <Section props={thisComponent} key={uuidv4()} />
                        //                 case 'Equipment'    : return <Section props={thisComponent} key={uuidv4()} />
                        //                 case 'Sensor'       : return <Section props={thisComponent} key={uuidv4()} />
                        //                 default             : return ('');
                        //             }
                        //         // })()} */}
                        //     // </span>
                        // // )
                    }

                })}
            </section>
            
        // </div>
    )
};

import React, { useEffect, useState } from 'react';

import './assets/css/style.css';

import PieChart from './PieChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';


export default function Chart(props) {

    const {
        chartData,
        chartType,
    //     chartTitle,
    //     chartSubtitle,
    //     chartLegend,
    //     chartOptions,
    //     chartLabels,
    //     chartColors,
    //     chartDataSets,
    //     chartDataLabels,
    //     chartDataBackgroundColor,
    //     chartDataBorderColor,
    //     chartDataBorderWidth,
    //     chartDataPointBackgroundColor,
    //     chartDataPointBorderColor,
    //     chartDataPointBorderWidth,
    //     chartDataPointRadius,
    //     chartDataPointHoverRadius,
    //     chartDataPointHitRadius,
    //     chartDataPointHoverBackgroundColor,
    //     chartDataPointHoverBorderColor,
    //     chartDataPointHoverBorderWidth,
    } = props;


    const [thisChart, setThisChart] = useState(null);

    useEffect(() => {
        switch (chartType) {
            case 'pie':
                setThisChart(<PieChart chartData={chartData} />);
                break;
            case 'bar':
                setThisChart(<BarChart chartData={chartData} />);
                break;
            case 'line':
                setThisChart(<LineChart chartData={chartData} />);
                break;
            case 'doughnut':
                setThisChart(<DoughnutChart chartData={chartData} />);
                break;
            default:
                setThisChart(`Chart type ${chartType} not supported.`);
                break;
        }
    }, [chartData, chartType]);


    return (
        // <div
        //     className='chart__container'
        // >
        <>
            {thisChart}
        </>
        // </div>
    )
}

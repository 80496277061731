import React, { useContext } from 'react';
import './assets/css/style.css';
import { GlobalContext } from '../../App';

export default function Search(){
    const { 
        Icon
    } = useContext(GlobalContext);
    

    {/*TODO:: 082822 #EP || Add Logic for on Click */}

    const handleOnClick = (e) => {
        e.preventDefault()
        console.log("on submit")

    }

    return ( 
        <div className="search"
            //-- Update 'data-placeholder' to whatever you want the placeholder text to be.
            data-placeholder='Search'

        >
            {/*TODO:: 082822 #EP || Logic for this button
                    1. When click, return results up to be allow component to feed a search event
            */}
            <button className="search__button"
                type="submit"
                onClick={(e) => {handleOnClick(e)}}
                >
                <Icon name="search"
                    background_c="transparent"
                    margin="0"
                />
            </button>
            
            {/*TODO:: 082822 #EP || Logic for this element
                    1. When hover other buttons, still stay dark bg color
            */}
            <input className= "search__input"
                    type="text"
                    placeholder="Search..."
                    
                    autoFocus
            />

            {/*TODO:: 082822 #EP || Logic for this button
                    1. Make this button appear ONLY when there is text in the search
                    2. When click, clear input
            */}
            <button 
                className="clear__button"
                type="submit"
                onClick={(e) => {handleOnClick(e)}}
            >
                <Icon name="clear"
                    background_c="transparent"
                    margin="0"
                />
            </button>
        </div>
    )
}

//------------------------------------------------------------------------------
//-- IMPORTS
import React, { useState, createContext, useEffect, useMemo } from 'react';
import {  BrowserRouter, Route, Routes } from 'react-router-dom';
//-- Graph QL
import { 
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

//TODO:: 07272022 #EP || Add Auth Token to GraphQL, etc

//------------------------------------------------------------------------------
//-- ASSETS
import Logo from './assets/Logo';
import Icon from './components/icon';

//------------------------------------------------------------------------------
//-- LIB - ( Libraries ) External Libraries with facades

import {
  dateGetMonths,
  //getDays,//TODO:: 07202022 #EP || Review if needed and make plan to fix if is.
  getTimeLocal,
  dateFormat_yyyy_mm_dd,
  dateFormat_mm_dd_yyyy,
  dateTimeFullLocal,
  dateTimeFull,
  dateDayOfWeek,
  dateHourOfDay,
  dateGetTimePassed
} from './lib/DateTime'

//------------------------------------------------------------------------------
//-- CONTEXT

import Pages from './context/pages';
import User from './context/User';
import guestUser from './context/User/guest.json';
//TODO:: 08132022 #EP || Remove PLACEHOLDER JSON data importing fake users data
// import FakeAdmin from './context/User/FakeAdmin.json';
// import FakeCustomer from './context/User/FakeCustomer.json';
import Alerts from './context/Alerts';

//TODO:: 07322022 #EP || Onboard features logic and replace existing infrastructure
// import Features from './context/features';


//------------------------------------------------------------------------------
//-- COMPONENTS

import Banner from './components/Banner';
import Clock from './components/Clock';
import Loading from './components/Loading';
import Chart from './components/Chart';
import Table from './components/Table';
import Modal from './components/Modal';
//------------------------------------------------------------------------------
//-- HOOKS

import HandleSubmitEvent from './hooks/useHandleSubmit';
import  HandleFetch from './hooks/handleFetch';
//import handleAuthService from './hooks/handleAuthService';
import useAuthService from './hooks/useAuthService';
// import UseGetAlerts from './features/Notifications/Hooks/UseGetAlerts';
// import UseGetAlerts from './services/gql/Alerts/UseGetAlerts';
import {useLocalStorage} from './hooks/useStorage';

//------------------------------------------------------------------------------
//-- UTILITIES

import Mode from './utils/Mode';
import {
  isValidEmail
  } from './utils/Email';
import {
  capitalizeFirstLetter,
  shrinkAddEllipse
  } from './utils/String';


//------------------------------------------------------------------------------
//-- LAYOUTS

import Header             from './layouts/Header';
import Navigation         from './layouts/Navigation';
import Footer             from './layouts/Footer';

//------------------------------------------------------------------------------
//-- PAGES


import Sandbox            from './pages/Sandbox';



import HomePage           from './pages/Home';
import AboutPage          from './pages/About';
import SignupPage         from './pages/Signup';
import LoginPage          from './pages/Login';


import AdminPage          from './pages/Admin';
import ResellersPage      from './pages/Resellers';
import DistributorsPage   from './pages/Distributors';
import CustomersPage      from './pages/Customers';
import LocationsPage      from './pages/Locations';

import AccountPage        from './pages/Account';
import DashboardPage      from './pages/Dashboard';
import EquipmentPage      from './pages/Equipment';
import SensorsPage        from './pages/Sensors';
import SensorPage         from './pages/Sensor';
import SettingsPage       from './pages/Settings';
import NotificationsPage  from './pages/Alerts';
import SchedulerPage      from './pages/Scheduler';
import LogsPage           from './pages/Logs';

import LogoutPage         from './pages/Logout';

import HelpPage           from './pages/Help';
import NotFoundPage       from './pages/NotFound';

//------------------------------------------------------------------------------
//-- FEATURES 

import AccountSelector from './features/AccountSelector';
import Account from './features/Account';
import Dashboard from './features/Dashboard';
import Help from './features/Help';
import Logs from './features/Logs';
import Map from './features/Map';
import Notifications from './features/Alerts';
import Scheduler from './features/Scheduler';
import Sensors from './features/Sensors';
import SessionState from './features/SessionState';
import Settings from './features/Settings';
import Users from './features/Users';


//------------------------------------------------------------------------------
//-- Graph QL LOGIC - Used to connect Client to Server


//TODO:: 0782022 #EP || Move GraphQL Logic into Services
let uri = ""; //-- used to know what to do with graphQL uri based on if development or production

//-- if in development mode, use graphql local path
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') uri = 'http://127.0.0.1:3001';  //-- Local instance
//if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')  uri = 'https://bair-api-production.up.railway.app' //-- New cloud API

//-- And print to console for awareness
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  console.log('//-- client/src/App.js - Client in development...');
  console.log(`//-- process.env.REACT_APP_BRANDNAME: ${process.env.REACT_APP_BRANDNAME}`);
} ;

//-- if in production mode
if (process.env.NODE_ENV === 'production') uri = 'https://api.bair.app';

const httpLink = createHttpLink({ "uri": uri });
const authLink = setContext( 
  (_, { headers }) => {
    const token = localStorage.getItem('id_token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
}}});

/** The Apollo Client manages communication with the Apollo Server.
 * 
 * @param {object} cache - The Apollo Client cache is a normalized data store that contains all of your locally-accessible data.
 * @param {object} link - The Apollo Client link is a set of functions that Apollo Client calls to execute GraphQL operations.
 * @param {boolean} addTypename - The Apollo Client typeDefs are the GraphQL schema definitions that you define for your local data.
 * 
 * @resources
 *  
 *  - [Introduction to Apollo Client](https://www.apollographql.com/docs/react)
 *  - [Configuring the Apollo Client cache](https://www.apollographql.com/docs/react/caching/cache-configuration)
 *  - [Customizing cache IDs](https://www.apollographql.com/docs/react/caching/cache-configuration#customizing-cache-ids)
 *  - [Pagination in Apollo Client](https://www.apollographql.com/docs/react/pagination/overview/)
 *  - [Managing Local State](https://www.apollographql.com/docs/react/local-state/local-state-management/)
 *    - [Reactive variables](https://www.apollographql.com/docs/react/local-state/reactive-variables/) - State containers integrated into Apollo Client's reactivity model
 *    - [Local resolvers](https://www.apollographql.com/docs/react/local-state/local-resolvers/) - Manage local data with GraphQL like resolvers
 * 
 */
const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
    
  }),
  link: authLink.concat(httpLink),
  
  /** TODO:: 20221218 #EP || Add Local Storage  Management with GraphQL See GitHub Issue #45 https://github.com/ErikPlachta/Bair-Frontend/issues/45
   * - Add [Reactive variables](https://www.apollographql.com/docs/react/local-state/reactive-variables/) - State containers integrated into Apollo Client's reactivity model
   * - Add [Local resolvers](https://www.apollographql.com/docs/react/local-state/local-resolvers/) - Manage local data with GraphQL like resolvers
   */
});

//------------------------------------------------------------------------------
//-- GLOBAL CONTEXT - Used to share data between components at a global level
export const GlobalContext = createContext();

//------------------------------------------------------------------------------
//-- App Function
export default function App({ uuidv4 }) {
 
  //-- Global State for sensor data as this is shared between everything
  const [sensors, setSensors ] = useState([]);
  const [sensorLength, setSensorLength] = useState() ;
  const [auth, setAuth] = useState({})
  const [alerts, setAlerts] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  
  //TODO:: 082822 #EP || Remove this and use User context instead
  const [ user, setUser ] = useState({});

  /** pageIndex
   * 
   * App.js's useEffect function checks Session for User's Auth Level.
   * That auth level determines what pages to make available. Page Index holds
   * this list of pages and the app renders links accordingly.
   */
  const [pageIndex, setPageIndex] = useState(null);
  
  /** targets
   * 
   * Contains a list Objects and their Set functions for ALL States/Context that
   * exist globally.  Used in tandem with handleUpdate, handleDelete,
   * handleCreate, and handleGet functions.
   * 
   * A user makes a request to handle a change, they provide the index of the
   * object they want to change, and the props
   */
  const [targets, setTargets] = useState({
    'user' : setUser,
  });

  //----------------------------------------------------------------------------
  //-- Global State Management Functions
  //TODO:: 07272022 #EP || Onboard global state management functions

  function HandleUpdate({ targetIndex = undefined, props = undefined }) {
    /** Define the target updating and provided props to be updated.
     * 
     * @param {STRING} targetIndex  - The index value of the object to update within targetIndex
     * @param {STRING} props        - The name of the property to update
     * @param {STRING} targetIndex  - The index of what's being updated within the array of things that CAN be updated.
    */
    // console.log('//-- App.js - handleUpdate().props: ', props);
    // console.log('//-- App.js - handleUpdate().targetIndex: ', targetIndex);

    try {
      //-- Update the global state of the target index with the provided props
      targets[targetIndex](props);
    }
    catch(error) {
      console.log('//-- App.js - handleUpdate() - error: ', error);
    }
    
    // setUser(props)
  }

  function HandleDelete() {
    console.log('//-- App.js - handleDelete()');
  }

  function HandleCreate() {
    console.log('//-- App.js - handleCreate()');
  }

  function HandleGet() {
    //-- Manages sharing between application in ways unique to requirements.
    console.log('//-- App.js - handleGet()');
    return useLocalStorage('alertCount', 0)
    
  }

  //----------------------------------------------------------------------------
  //-- ROUTER LOCATIONS - Used to minify details that would normal exist in the return function.
  const [pages, ] = useState({    
    'sandbox':    {   
                      displayName:'Sandbox',   
                      name:'sandbox',
                      tagline:'Bair Sensors Sandbox.', 
                      jsx: <Sandbox key={uuidv4()} uuidv4={uuidv4} />
    },
    
    'home':       {   
                      displayName:'Home',   
                      name:'home',
                      tagline:'Bair Sensors Sandbox.', 
                      jsx: <HomePage key={uuidv4()} uuidv4={uuidv4} />
                },
    'about':      {  
                      displayName:'About', 
                      name:'about',
                      tagline:'This is a tagline placeholder', 
                      jsx: <AboutPage key={uuidv4()} uuidv4={uuidv4} />
                  },
    
    'signup':     {   
                      displayName:'Signup', 
                      name:'signup',
                      tagline:'This is a tagline placeholder', 
                      jsx: <SignupPage key={uuidv4()} uuidv4={uuidv4}/>,
                  },
    'login':      {   
                      displayName:'Login',  
                      name:'login',
                      tagline:'This is a tagline placeholder', 
                      jsx: <LoginPage key={uuidv4()} uuidv4={uuidv4} />
                  },
    'dashboard':  {
                      displayName:'Dashboard',
                      name:'dashboard',
                      tagline:'Dashboard tagline here', 
                      jsx: <DashboardPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="dashboard" />
                  },
   
    'equipment':    {   
                      displayName:'Equipment',
                      name:'equipment',
                      tagline:'Equipment tagline here', 
                      jsx: <EquipmentPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="equipment" />
                  },
    'sensors':    {   
                    displayName:'Sensors',
                    // name:'sensor',
                    tagline:'sensors tagline here', 
                    jsx: <SensorsPage key={uuidv4()} uuidv4={uuidv4}/>,
                    icon: <Icon name="compressor_bw" />
                  },
     'sensor':    {   
                    displayName:'Sensor',
                    // name:'sensor',
                    tagline:'sensor tagline here', 
                    jsx: <SensorPage key={uuidv4()} uuidv4={uuidv4}/>,
                    icon: <Icon name="compressor_bw" />
                  },
    'scheduler':  {   
                      displayName:'Scheduler',
                      // name:'scheduler',
                      tagline:'Scheduler tagline here', 
                      jsx: <SchedulerPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="calendar" />
                  },
  'notifications':{
                      displayName:'Notifications',
                      name:'notifications',
                      tagline:'sensors tagline here', 
                      jsx: <NotificationsPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="notifications" background_c="transparent" />
                  },
    'logs':       {
                      displayName:'Logs',
                      name:'logs',
                      tagline:'Logs tagline here', 
                      jsx: <LogsPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="logs" />
                  },
  'admin':    {
                      displayName:'Admin',
                      name:'admin',
                      tagline:'Admin tagline here', 
                      jsx: <AdminPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="Admin" />
                  },
  'resellers':    {
                      displayName:'Resellers',
                      name:'resellers',
                      tagline:'resellers tagline here', 
                      jsx: <ResellersPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="resellers" />
                  },
  'distributors': {
                      displayName:'Distributors',
                      name:'distributors',
                      tagline:'distributors tagline here', 
                      jsx: <DistributorsPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="distributors" />
                  },
  'customers':    {
                      displayName:'Customers',
                      name:'customers',
                      tagline:'customers tagline here', 
                      jsx: <CustomersPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="customers" />
                  },
    'locations':  {
                    displayName:'Locations',
                    name:'locations',
                    tagline:'Locations tagline here', 
                    jsx: <LocationsPage key={uuidv4()} uuidv4={uuidv4}/>,
                    icon: <Icon name="pin" />
                },
    'settings': {   
                    displayName:'Settings',
                    name:'settings',
                    tagline:'settings tagline here', 
                    jsx: <SettingsPage key={uuidv4()} uuidv4={uuidv4}/>,
                    icon: <Icon name="gear" background_c="transparent" />
                  },
    'help':       {   
                      displayName:'Help',  
                      name:'help',
                      tagline:'This is a tagline placeholder', 
                      jsx: <HelpPage key={uuidv4()} uuidv4={uuidv4} />,
                      icon: <Icon name="help" />
                  },
    'account':    {   
                      displayName:'Account',
                      name:'account',
                      tagline:'This is a tagline placeholder', 
                      jsx: <AccountPage key={uuidv4()} uuidv4={uuidv4}/>,
                      icon: <Icon name="account" background_c="transparent" />
                  },
    'logout':     {   
                      displayName:'Logout', 
                      name:'logout',
                      tagline:'This is a tagline placeholder', 
                      jsx: <LogoutPage key={uuidv4()} uuidv4={uuidv4} />,
                      icon: <Icon name="logout" />
                  },
    'notfound':   {   displayName:'404 - Page Not Found',  
                      name:'notfound',
                      tagline:'This is a tagline placeholder', 
                      jsx: <NotFoundPage key={uuidv4()} uuidv4={uuidv4}/>,
                  },
  });

  //----------------------------------------------------------------------------

  useEffect(() => {
    console.log('//-- App.js - useEffect()');

    
    if(useAuthService.isLoggedIn()){
      //-- If someone is logged in, update context and state accordingly
      
      const loggedInUser = useAuthService.getCurrentUser()
      // console.log("loggedInUser: ", loggedInUser)
      User.setUser(loggedInUser); //-- User Context object
      setUser(loggedInUser)  //-- Temp user Global State object
    }
    else {
      //-- Otherwise a guest

      // console.log("//-- PLACEHOLDER: User not logged in event")
      User.setUser(guestUser);    //-- User Context object
      setUser(guestUser);    //-- Temp user Global State object
    }
    
    //-- Get Page Index based on logged in user
    Pages.setUserType(User.getUser());
    
    setPageIndex(
      {
        ...pageIndex,
      nav     : Pages.getPages('nav'),
      header  : Pages.getPages('header'),
      account  : Pages.getPages('account'),
      routes  : Pages.getRoutes()
    });
   
    
    //-- Update Global States to prepare to update storage
    //TODO:: 07312022 #EP || Update with this with full auth functionality and updating that way instead of here.
    HandleUpdate({
      targetIndex : 'user',           //-- Update global context with it's value
      props       : User.getUser()    //-- Send in the current user from the class
    })

    // console.log("APP.js.user: ", user, loggedInUser, auth);

    //-- Update the Global State of this number of sensors for logged in account
    //TODO: 11/29/2022 #EP || Update this so actually used or remove.
    setSensorLength(sensors.length);    
    setAlertCount(User?.getAlertCount());

    User.getSessionState();

    //TODO:: add return to clear state on close app or w/e is necessary.
  }, [auth, User]);

  //----------------------------------------------------------------------------
  //-- Global State Context - Used in tandem with Global State
  const GlobalContextValue = {
    
    //-- Auth State
    User, 
    user, //-- TODO:: 082522 #EP || remove it?
    auth,
    Alerts, //-- Context Class object for managing alerts

    //-- Global State for number of alerts
    alertCount, 
    setAlertCount,
  
    //-- Third-Party Imports
    uuidv4,

    //-- Global State Management Functions
    HandleUpdate,
    HandleDelete,
    HandleCreate,
    HandleGet,

    //-- HOOKS
    useAuthService,
    
    //-- UTILITIES
    Mode,
    isValidEmail,
    capitalizeFirstLetter,
    shrinkAddEllipse,


    //-- VARIABLES AND STATES
    pageIndex,
    pages,
    Pages,
    sensors,
    setSensors,

    alerts,
    setAlerts,

    //-- FEATURES
    Account,
    Dashboard,
    Help,
    Logs,
    Map,
    Notifications,
    Scheduler,
    Sensors,
    Settings,
    Users,
    
    //-- ASSETS
    Logo,
    Icon,

    //-- COMPONENTS
    Banner,
    Chart,
    Clock,
    Loading,
    Table,
    Modal,

    //-- DateTime
    dateGetMonths,
  //getDays,//TODO:: 07202022 #EP || Review if needed and make plan to fix if is.
    getTimeLocal,
    dateFormat_yyyy_mm_dd,
    dateFormat_mm_dd_yyyy,
    dateTimeFullLocal,
    dateTimeFull,
    dateDayOfWeek,
    dateHourOfDay,
    dateGetTimePassed
  };



  //-- No Routes so force loading
  //TODO:: 091722 #EP || Add real loading here if this is used
  if(!pageIndex?.routes) return "Loading..."
  
  // if(useAuthService.isLoggedIn()){ 
  //    Alerts.handleGetAlerts() //-- Get alerts for user
  // }

  //----------------------------------------------------------------------------
  //-- Return Function  
  return (
      <GlobalContext.Provider key={uuidv4()} value={GlobalContextValue}>
        {/* GRAPH QL Wrapper */}
        <ApolloProvider client={client}>
          
          { user.type === 'admin' && user.nameFull === 'Erik Plachta' 
              ? <AccountSelector />
              : ''
          }
          
          {/* ROUTES  */}
          <BrowserRouter key={uuidv4()} basename="/">
            <Header uuidv4={uuidv4}/> {/*//TODO:: 07232022 #EP || Figure out how I want to use header. */}
            <Navigation className='navigation'
                        key={uuidv4}
                        // navLocation='top'
                        navLocation='aside' //TODO:: 07232022 #EP || Add dynamic control.
            />
            
            {useAuthService.isLoggedIn() 
                ? <SessionState User={User} /> 
                : null
              }
            
            
            {/** Dynamically building routes with ./context/pages.
            *
            * Based on user's auth level, routes are served dynamically. This is
            * done by using the ./context/pages.js file to build the routes, which
            * are extract into the pagesIndex.routes state within app.js.
            * 
            */}
            <Routes>
              { pageIndex?.routes.map( (page, index) => {  
                //-- Try to build routes dynamically
                try {

                  //-- If the last route, then add wildcard ( shouldn't be needed but just to be safe forcing it last)
                  if(index === pageIndex.routes.length - 1){
                    return <Route 
                            exact path={page.routes[0]}
                            element={page.jsx}
                            key={uuidv4()}
                          />
                  }

                  // TODO:: 091722 #EP || Build all routes within routes array for each page. ( Not working as expected and not MVP so moving on)
                  // //-- Otherwise build the route(s) for the page
                  // page.routes.map( (route, ) => {
                  //   console.log(route)
                  //   return <Route 
                  //           exact path={route}
                  //           element={page.jsx}
                  //           key={uuidv4()}
                  //         />
                  // })

                  //-- Otherwise build the first route for the page
                  return <Route 
                          exact path={page.routes[0]}
                          element={page.jsx}
                          key={uuidv4()}
                  />
                }
                //-- If fail to build routes dynamically, throw error
                catch (error) {
                  //! TODO:: 091722 #EP || Build better error handling, here. Should send error report to API for me to manage.
                  console.log("Error: ", error)
                }
              })}
            </Routes>
          </BrowserRouter>
          

          {/* <Banner //TODO:: 07312022 #EP || Add Banner back to page as a component and verify it works
              message='Under active development'
              type=''
          /> */}
        

          {/* FOOTER  */}
          <Footer />
        </ApolloProvider>
      </GlobalContext.Provider>
    // </div>
  );
};
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useHandleBanner from '../components/Banner/hooks/useHandleBanner';
import Loading from '../components/Loading';


export default function useHandleAccess({ user={ auth:{ type: undefined }} , accessLevel=undefined }) {
    /**
     * @param {string} user.type - User object
     * @param {string} securityLevel - Security level of the user
     * 
     * @returns {Navigate} - Redirects user
     * @returns {boolean}  - True if user is authorized to access the page
    */

    const [userType, ] = useState( user.auth ? user.auth.type : undefined  );
    const [hasAccess, setHasAccess] = useState(false);
    const [verifiedAccess, setVerifiedAccess] = useState(false);
    
    //-- used for banner
    const [message, setMessage ] = useState(undefined);
    const [style, setStyle ] = useState(undefined);
    const [title, setTitle ] = useState(undefined);
    
    const navigate = useNavigate();

    function handleAccess( user, accessLevel ){
        // console.log(user, accessLevel)
        //-- If a user 
      
        setVerifiedAccess(true);
      
        // console.log(user.type, accessLevel)
        
        //-- If the user-type is defined and not admin
        if(userType !== undefined && accessLevel !== userType) {
            setMessage('You do not have access to this page');
            setStyle('error');
            setTitle('Alert');
            setTimeout( () => {navigate('/')}, 3000);
            
        }
        
        //-- If guest (not logged in user), navigate to login page for user to login
        if(userType !== undefined && accessLevel === 'guest') {
            setMessage('You must be logged in to access this page');
            setStyle('error');
            setTitle('Alert');
            setTimeout( () => { navigate('/login')}, 3000);
        };
        
        //-- if user has access, set to true telling requestor that they have access
        if(userType === accessLevel) setHasAccess(true);
    }

    useEffect(() => {
        
        // console.log("userType: ", userType)
        // console.log("accessLevel: ", accessLevel)
        
        if(userType === undefined) handleAccess(userType, accessLevel);
        handleAccess(user, accessLevel)
        
        // console.log("attemptedAccess: ", attemptedAccess);
    }, []);
    
    
    return({
        hasAccess,
        banner: useHandleBanner({ title, message, style }),
        redirecting: <Loading title='Access Denied' message={message} />
    });
};
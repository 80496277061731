/* Mode is used by to determine the current overall configuration of the app.

    Author: Erik Plachta
    Date:   05/31/2022
*/

//------------------------------------------------------------------------------
//-- ASSETS
//TODO:: 07182022 #EP || Add Background Image assets to mode
// import body_backgroundImage_dark  from '../../assets/images/bg-dark.webp';
// import body_backgroundImage_light from '../../assets/images/bg-light.webp';

//------------------------------------------------------------------------------
//-- HELPERS
import { getTimeLocal24 } from '../lib/DateTime'; //-- Used for dark-light mode toggle of content and style

class Mode {
    /* Mode is used by to determine the current overall configuration of the app.
        
        Methods:
            getMode()
                - Run getMode to get current mode variables.
                Returns:
                    - mode: object
            
            setMode()
                - Run setMode to update current Mode variables.
                Returns:
                    bool: true/false if success/fail

        Returns:
            mode {
                String: nav_message, - Good Morning, Good Afternoon, Good Evening, Welcome
                String: body_backgroundImage, - Path to background image for APP
                String: body_dataAttribute - ClassName for used for styling
        }
    */
    constructor(props) {
        //-- Get current time
        this.timeNow = getTimeLocal24(new Date());
        //-- Update mode accordingly
        this.mode = {
            nav_message:            (this._get_nav_Message(this.timeNow)),
            body_backgroundImage:   (this._get_body_backgroundImage(this.timeNow)),
            body_dataAttribute:     (this._get_body_dataAttribute(this.timeNow))
        }
    };    
    
    getMode() {
        //-- Get the mode based on most recent setMode call parameters
        // return this.mode;
        return {
            nav_message:            (this._get_nav_Message(this.timeNow)),
            body_backgroundImage:   (this._get_body_backgroundImage(this.timeNow)),
            body_dataAttribute:     (this._get_body_dataAttribute(this.timeNow))
        }
    };
    
    setMode() {
      //-- Makes a call to Moment.js API to get the current mode
        try {
            //-- Get updated time
            this.timeNow = getTimeLocal24(new Date());
            //-- Update mode accordingly
            this.mode = {
                nav_message:            this._get_nav_Message(this.timeNow),
                body_backgroundImage:   this._get_body_backgroundImage(this.timeNow),
                body_dataAttribute:     this._get_body_dataAttribute(this.timeNow)
            }
            //-- success so return true
            return true;
        }
        catch(err) {
            console.log('ERROR: ', err)
            //-- failure so return false
            return false;
        }
    };


    //-- PRIVATE FUNCTIONS
    _get_nav_Message(timeNow) {
        //-- Use current time to determine welcome message.
        
        //-- If time is between 12am and 12pm, show good morning
        if (timeNow >= '00:00:00' && timeNow < '12:00:00') {
        return 'Good Morning'
        }
        //-- If time is between 12pm and 6pm, show good afternoon
        if (timeNow >= '12:00:00' && timeNow < '17:00:00') {
        return 'Good Afternoon'
        }
        //-- If time is between 6pm and 12am, show good evening
        if (timeNow >= '17:00:00' && timeNow < '23:59:59') {
        return 'Good Evening'
        }
        
        //-- If for some reason the above are not true, just return welcome
        return 'Welcome';
    };

    _get_body_backgroundImage(timeNow){
        //-- Returns path to background image based on APP Root's client/src

        //-- If time is between 12am and 12pm, show good morning
        if (timeNow >= '00:00:00' && timeNow < '07:00:00') {
           return "body_backgroundImage_dark";
        }
        //-- If time is between 12pm and 6pm, show good afternoon
        if (timeNow >= '07:00:00' && timeNow < '17:00:00') {
        return "body_backgroundImage_light";
        }
        //-- If time is between 6pm and 12am, show good evening
        if (timeNow >= '17:00:00' && timeNow < '23:59:59') {
        return "body_backgroundImage_dark";
        }
        
        //-- If for some reason the above are not true, just return welcome
        return "body_backgroundImage_light";
    }

    _get_body_dataAttribute(timeNow){
        //-- returns string to be used as className for dark-light mode dynamic  specific styling

        if (timeNow >= '00:00:00' && timeNow < '07:00:00') {
            return 'dark'
        }
        if (timeNow >= '07:00:00' && timeNow < '17:00:00') {
            return 'light'
        }
        //-- If time is between 6pm and 12am, show good evening
        if (timeNow >= '17:00:00' && timeNow < '23:59:59') {
        return 'dark'
        }
        //-- If for some reason the above are not true, just return welcome
        return 'light';
    };
}

export default new Mode();

//------------------------------------------------------------------------------
//-- IMPORTS
import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, NavLink, useLocation } from 'react-router-dom'; //-- routing 
import NavAlerts from './NavAlerts';

import { GlobalContext } from '../../App';

//-- Nav Styles
import './assets/css/style.css';
import User from '../../context/User';

// //-- GQL to get and manage alert notifications
//-- 11/26/2022 #EP || Disabled Alerts because it doesn't belong here. Concept verified, need to move to proper location.
// import { useQuery } from '@apollo/client';
// import { ALERTS_GET } from '../../services/gql/Query';



//------------------------------------------------------------------------------
//-- App Function
export default function Navigation(props) {
  
  //-- Deconstruct Props
  const {
    navLocation,
    // handleGet
  } = props;

  //-- Global State
  const {
    uuidv4,
    Pages,
    sensors,
    user,
    pageIndex,
    alerts,
    alertCount,
    User
  } = useContext(GlobalContext);

  

  // //-- Get alert data
  // const {
  //   loading,
  //   data,
  //   error,
  //   startPolling,
  //   stopPolling
  // } = useQuery( ALERTS_GET );

  // var alertCount = data.getAlerts?.length || 0;
  // const [alertCount, setAlertCount] = useState(0);
  // const [pageIndex, setPageIndex] = useState(null);

  //----------------------------------------------------------------------------
  //-- LOCAL STATE

  //-- Where navigation appears on the page
  const [location, /*setLocation*/] = useState( navLocation ? navLocation : 'top' );
  const [sensorLength, setSensorLength] = useState() ;
  
  
  //-- Getting current path from URL to highlight active link
  const currentLocation = useLocation();
  
  //-- Navigation location on page
  const setPageContainer = (location) => {
    /** Managing where the Navigation appears on the page. 
     * 
     * @param {string} location - Where the navigation appears on the page.
     * 
     * NOTE: 07/13/2022 This is temporarily hard-coded to aside
     */

    if(location === 'aside') {
      //-- Default so no changes needed

      // document.querySelector('.page__container').classList.add('page__aside');
    }
    if(location === 'top') {
      // console.log("toggle top")
      //- TODO:: 07302022 #EP || NOTE: This is NOT mutable at this time.
      document.querySelector('.page__container').classList.remove('page__aside');
    }
  }

  
  // // //-- Updating alert count in page
  // function setAlertCounts(alerts){
    
  //   if(alerts > 0){
  //     //-- Get the alert container from page
  //     // var nav_el = document.querySelector('#nav__alert_count_Alerts');
  //     // nav_el.classList.remove('hidden');
  //     // console.log(nav_el)
  //     // var notification_el = document.createElement('span');
  //     // notification_el.classList.add('nav__alert_count');
  //     // notification_el.append(alerts);
  //     // //-- Update alerts in page
  //     // nav_el.append(notification_el);
  //   }
  // };


  //----------------------------------------------------------------------------
  //-- USE EFFECTS

  //-- 11/26/2022 #EP || Disabled Alerts because it doesn't belong here. Concept verified, need to move to proper location.
  useEffect(() => { 
    /** Every time the location changes, executes to ensure the page container
      *   and nav element styling is correct.
      */
    // console.log(alertCount)
    setPageContainer(location);
    // setSensorLength(sensors?.length);

  }, [currentLocation, sensors, location, User]);


  // if(loading) return <p>Loading...</p>;
  // if(error) return <p>Error: {error.message}</p>;
  // if(!data) return <p>Not found</p>;
  
  //----------------------------------------------------------------------------
  //-- Return Functions

  
  //-- Wait to show navigation until the Pages context is loaded
  if(pageIndex === null) return <></>;

  //-- Once Page context loaded, return navigation elements
  return (
    <nav className={`navigation__${location}`} >
      {/* navigation__aside vs navigation__top */}

      {/* {data && !error && !loading
        ? setAlertCount(data?.getAlerts?.length)
        : null
      } */}

      
      {/** Build each navigational element based on the user's access and 
       * available pages.
       */}
      { Object.keys(pageIndex.nav).map(( page, index ) => {
        return (
          
          /** 1. Adding link wrapper on nav element
           * 
          */
          <NavLink to={`/${pageIndex.nav[page].name}`}
                    key={uuidv4()}
                    id={`nav__${pageIndex.nav[page].name}`}
                    className={
                      /* If the nav icon is the same as the current page, add 
                        class link__active to update the link styling with 
                        accent color.
                      */
                      
                     ({ isActive }) => (
                        //BUG:: 20221022 #EP || Add ability to have awareness of if at home route but sensors or home page to set active
                        isActive
                          ? "navigation__link link__active"
                          : "navigation__link"
                    )
                  }
            >
            
            {/** 2. Adding Page's Icon to Navigation
              *
              * 1. Check if the page has an icon
              * 2. If it does, add the icon to the navigation
              * 3. If it doesn't, return nothing
              * 
            */}
            { pageIndex?.nav[page]['icon'] 
              ? <span className='navigation__icon'>
                  {pageIndex?.nav[page]['icon']}
                </span>
              : ''
            }
            

            {/**3. Adding Page's  Display Name to Navigation
             *  
             * 3.1. Check if the page has a display name
             * 3.2. If it does, add the display name to the navigation
             * 3.3. If it doesn't, return nothing
             * 
            */}
            {pageIndex?.nav[page]?.['displayName']
              ? <span className='navigation__pageName' >
                  {pageIndex?.nav[page]?.['displayName']}
                </span>
              : 'ERROR: No Display Name'
            }

            {/** 4. Add  notification container for nav element if needed */}
            {/* <span 
                className={
                    pageIndex?.nav[page]?.['displayName'] !== 'Alerts'
                      ? 'hidden'
                      : "nav__alert_count"
                  }
                id={`nav__alert_count_${pageIndex?.nav[page]?.['displayName']}`}
              > */}
                {/*TODO::  11/26/2022 #EP || Add logic for if alerts zero not to show */}
                
                
                {/** Return number of alerts
                 * 
                 */}
                {/* {(() => {
                  switch (pageIndex?.nav[page]?.['displayName']) {
                    case 'Alerts' :  return (alertCount);
                    default       :  return null;
                  }
                })()} */}
                
                <NavAlerts  pageDisplayName={pageIndex?.nav[page]?.['displayName']}
                            // alertCount={alertCount}
                            alertCount={alertCount}
                />
                {/* {console.log("!!!! ", alertCount)} */}
                
                {/* nav__alert_count_Alerts */}
                
              {/* </span> */}
          </NavLink>
          
        )
        })
      }
    </nav>
)}
import React from 'react';
import Sensor from '../features/Sensor';

export default function SensorPage() {
    //-- Page servers as a wrapper for the Sensor component feature
    return (
        <div className='page__container' >
            <Sensor />
        </div>
    );
};

const tableContext = {
    enabled                 : false,
    name                    : "Sensors",
    data                    : Object(),
    header                  : Object(),
    results                 : 50,
    defaultSearchFilter     : "businessName",
    toggleShowActions       : true,
    definedActions          : ["View Sensor"],
};

const tableHeaderContext = [
    // {  field: "_id", value: "ID"  },
    // { field: "accountName", value: "Account" },
    { field: "businessName", value: "Business" },
    // { field: "distributorName", value: "Distributor" },
    { field: "equipmentName", value: "Equipment" },
    { field: 'room', value: "Room" },
    // { field: 'room', value: "Room" },
    { field: 'name',  value: 'Name'},
    { field: 'Logs',  value: 'Logs'},
    { field: 'LastSyncDate',  value: 'Last Sync - Date'},
    { field: 'LastSyncData',  value: 'Last Sync - Data'},
];


module.exports = {
    tableContext,
    tableHeaderContext
};
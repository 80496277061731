import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../App';

export default function Equipment() {

    const {
        uuidv4,
        Loading
    } = useContext(GlobalContext);


    const [pageLoading, setPageLoading] = useState(true);
    
    useState(() => {
        setTimeout(() => {
                setPageLoading(false);
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
    }, []);

    if(pageLoading){
        return <Loading />;
    }
    else {
        return(
            <div className='page__div' >

              <section className='page__section' >
                    <div className='page__section__header' >
                        <h3 className='page__section__title'>
                            Equipment
                        </h3>
                        <p className='page__section__summary' >
                            This page is used to manage all equipment within an
                            business.
                        </p>
                    </div>
                    
                </section>
            </div>
        )
    }
}

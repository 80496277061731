import React, { useEffect, useState } from 'react';

export default function Unit(props) {

    const [unit, setUnit] = useState({
        id              : null,
        name            : null,
        type            : null,
        description     : null,
        location        : null,
        status          : null,
        ServiceHistory  : [], /* This is a database value we're going to get pulled from below */ 
        sensor_ID       : null, /* a unit without a sensor */
        date_added      : null,
        added_by        : null,
        date_modified   : null,
        modified_by     : null,
    });


    useEffect(() => {
        if(props.id) {
            //-- if a valid unit is passed in, get the unit data
            setUnit(props);
        }
        else {
            //-- otherwise testing data
            setUnit({
                id              : 1,
                name            : 'Test',
                type            : 'Compressor',
                description     : null,
                location        : null,
                status          : null,
                ServiceHistory  : ServiceHistoryTestData[0],
                sensor_ID       : null, /* a unit without a sensor */
                date_added      : null,
                added_by        : null,
                date_modified   : null,
                modified_by     : null,
            });
        }

    }, [props]);
    
  return (
    <div className='page__list__item' >
        <div className='page__list__item_header' >
            <h4 className='page__list__item_header_title' >
                Name: {unit.name ? unit.name : 'Undefined'}
            </h4>
        </div>
        
        <ul className='page__list__item_ul'>
            <li className='page__list__item_li'>
                id: {unit.id ? unit.id : 'Undefined'}
            </li>
            <li className='page__list__item_li'>
                type: {unit.type ? unit.type : 'Undefined'}
            </li>
        </ul>
        <div>
            Last Service Date:{unit.ServiceHistory.date_service}
            < br/>

           
        </div>    
    </div>
  )
}



const ServiceHistoryTestData =[
    {
        id              : 1,
        unit_ID         : 1,
        date_service    : '2020-01-01',
        service_by_ID   : 1,
        service_notes   : 'This is a service note for unit 1',
        service_type    : 'Service Type 1',
        verfied_by_ID   : 1 
    }
]
import React, { useContext } from 'react';
import { GlobalContext } from '../App';
import Dashboard from '../features/Dashboard';

export default function DashboardPage() {

  const {
    pages
  } = useContext(GlobalContext);

  const thisPage = pages.dashboard;
  

  return (
    <div className='page__container' > 
      <Dashboard />
    </div>
  )
}

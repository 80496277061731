import React from 'react'

export default function LocationsPage() {
  return (
    <div className='page__container'>
        <div className='page__div'>
          <section className='page__section'>
            <div className='page__section__header' >
                <h3 className='page__section__title'>
                    Locations
                </h3>
                <p className='page__section__summary' >
                  This is a placeholder container for managing locations.
                </p>
            </div>


          </section>
        </div>
    </div>
  )
}

import React, {useContext, useEffect} from 'react';
import { GlobalContext } from '../../../App';
import Table from '../../Table';

export default function Section(props) {
    
    const {
        uuidv4
    } = useContext(GlobalContext);
    
    

    // const buildSection = (propsDict) => {
        
    //     var verifiedProps = '<ul>';

    //     console.log(propsDict)
    //     Object.entries(propsDict).forEach(([key, value]) => {
    //         // console.log(`${key} ${value}`); 
    //         verifiedProps += `<li>${key}: ${value}</li>`
    //     })

    //     verifiedProps += '</ul>'
    //     return verifiedProps;
    // }
    // useEffect(() => {
    //     console.log("section.useEffect(): uuidv4: ",uuidv4());
    // }, [])

    return (
        <section className='modal__section__content'>
            <span className='modal__section__header'> <b>Key</b> </span>
            <span className='modal__section__header'> <b>Value</b> </span>
        {/* </section>
        <section className='modal__section__content'> */}
                {/* {Object.entries(props.props).map(([key, value]) => {  
                    
                    if(key !== 'title' && key !== 'subTitle') {
                        
                        return (<>
                            <span className='modal__section__value' key={uuidv4()+key}>{key}</span>
                            <span className='modal__section__value' key={uuidv4()+value}>{value}</span>
                        </>)
                    }    
                })} */}
              {Object.entries(props.props).map(([key, value]) => {  
                   
                    if(key !== 'title' && key !== 'subTitle') {
                        
                        return (
                            <span className='modal__section__row' key={uuidv4()}>
                                <span className='modal__section__value'>{key}</span>
                                <span className='modal__section__value'>{value}</span>
                            </span>
                        )
                    }    
                })}
        </section>
        
    
  )
}

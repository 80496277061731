import React, { useContext, useEffect, useFocusEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { GlobalContext } from '../../App';
import './assets/css/style.css'; // Import the CSS file for this component
import { useNavigate, useLocation, useNavigationType, Navigate, useParams } from 'react-router-dom';
import HandleLogout from '../../hooks/handleLogout';

// TODO: 12182022 #EP || Add this -> import Card from '../../components/Card';

import {
    getDateTimeFormatted,
    isToday,
    isWithinDefinedHours,
    getTimePassed
} from '../../utils/DateTime';

import HandleBuildCards from './hooks/handleBuildCards';
// import HandleViewSensor from './hooks/HandleViewSensor';


import {
    tableContext,
    tableHeaderContext
 } from './context/sensorsContext.js';
import Table    from '../../components/Table';

//-- Graph QL
import { useQuery } from '@apollo/client';
import { UNITS_GET } from '../../services/gql/Query'

// import { clear } from '@testing-library/user-event/dist/clear';
// import Icon from '../../components/icon';
// import Auth from '../../services/Auth';


/** Primary View for ALL Sensors with ability to filter by URL Params
 *
 * @param {Object} props in URL to filter viewed results
 *
 * @returns jsx
 */
export default function Sensors() {

    //-- Global Context
    const {
        uuidv4,
        Loading,
        sensors,
        setSensors,
        User,
        useAuthService,
        // Modal,
        // getDateTimeFormatted
        // Icon
    } = useContext(GlobalContext);



    //-- to know if should reset the cache or not
    const [reset, setReset] = useState(0);
    // const client = useApolloClient();

    const [showTable, setShowTable] = useState(false);
    const [showCards, setShowCards] = useState(true);

    const [isLoaded, setIsLoaded] = useState(false);
    const [maxErrorsMet, setMaxErrorsMet] = useState(false);

    //- react-router-dom related
    const location = useLocation();
    const navigate = useNavigate();
    // const navigationType = useNavigationType();
    const params = useParams(); //-- Filtering viewed results


    //TODO:: 10012022 #EP || Delete this once documented in case needed for future and verified not needed ( This should be a hook within the modal itself )
    // const handleShowModal = (e) => {
    //     setShowModal(true);
    //     document.getElementById('hide-modal').classList.toggle('hide-modal');
    //     // const testing = document.getElementById('hide-modal');
    //     // console.log(testing,e)
    // }

    const handleUnitsData = () => {

        clearTimeout();
        setTimeout(() => {
            //-- if still loading, wait duration and then try again
            if(loading) handleUnitsData()

            // console.log("hit timeout")
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);


        // console.log("getSensorData(): called", '\n\tloading: ', loading, '\n\terror: ', error,'\n\tdata: ', data);

        if(data) {
            // console.log("HandleUnitData: called")
            // setSensors((prev) => {
            //     return {
            //         // ...prev,
            //         ...data?.getUnits
            //     }
            // })
            setSensors(data?.getUnits);
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log("ENV:Dev: Sensors/index: sensors: ", sensors)
            }
        }
        else {
            //TODO:: 10012022 #EP || Add proper error handling and comments
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
                console.log("ENV:Dev: Sensors/index: Else: data: ", data)
            }

        }
    };



    //--------------------------------------------------------------------------
    //-- QGL Queries
    const { loading, error, data, startPolling, stopPolling} = useQuery(
    // const { loading, error, data } = useQuery(
        UNITS_GET,
        {
        variables: {
            'id_token': User?.user?.id_token
        },
        startPolling: process.env.REACT_APP_MIN_LOAD_TIME_MS
        }
      );

    // const [pageLoading, setPageLoading] = useState(true);

    //-- The table within this Sensors page
    const tableHeader = [...tableHeaderContext];

    const tableData = sensors;

    const sensorsTable = {
        ...tableContext,
        data                    : tableData,
        header                  : tableHeader,
    };

    const handleReQuery = () => {
        console.log("handleReQuery(): called")
        // var waitToReQuery = null;
        // clearInterval();
        // if(waitToReQuery !== null) clearTimeout(waitToReQuery);
        const waitToReQuery = setTimeout(() => {
            // if(loading) handleReQuery();
            console.log("//-- if still loading, wait duration and then try again")
            // console.timeEnd("handleReQuery()");
        }, 5000);
        // if(waitToReQuery) clearTimeout(waitToReQuery);

    };


    function handleViewSensor(e, sensor){
        e.preventDefault();
        // console.log("handleViewSensor: ", sensor)
        navigate(`/s/${sensor?._id}`,
                  { replace: false,
                      state: {
                        'data': sensor,
                        'history' : {
                          pathname: location.pathname,
                          search: location.search
                        }
                        }
                  }
        );
      }

    //--------------------------------------------------------------------------
    //-- USE EFFECT


    //-- First run through so kick polling right away
    //! TODO:: 101622 #EP || Identify why re-polling isn't working right away
    //TODO: 12/01/2022 #EP || Remove Polling from here once confirmed I can manage it in SessionState
    // if(!loading && reset === 0) startPolling(1000);

    useEffect(() => {

        //-- POLLING MANAGEMENT
        // if(reset === 0) startPolling(1000);
        setReset((resetValue) => { return resetValue + 1});
        if(!loading && reset > 0) setReset(0)
        if ( !loading  && data) {
            // console.log("useEffect !loading and data")
            handleUnitsData(data?.getUnits);

            // startPolling(process.env.REACT_APP_POLLING_INTERVAL_MS);
            //TODO: 12/01/2022 #EP || Remove Polling from here once confirmed I can manage it in SessionState
            // if(reset>0) stopPolling(); startPolling(process.env.REACT_APP_POLLING_INTERVAL_MS);

            setReset((resetValue) => { return resetValue + 1 });
            // console.log("reset: ", reset)
        }
        return () => {
            setReset(0);
        }
    },[loading, error, data]);



    function handleReload( duration = 5000 ){

        //-- Attempt to reload page after 5 seconds
         setTimeout(() => {
            window.location.reload(true);//return "Error: " + error.message;

        }, duration);
    }

    // const [errorCount, setErrorCount] = useState(0);


    function handleErrorCount({
        waitTime = 5000,
        maxErrorCount=10,
        errorCount
    }) {

        // console.log("handleErrorCount: ", errorCount)

        //-- clear any previous timeouts
        clearTimeout();

        //-- Timeout to increment thisCount
        setTimeout(() => {
            if(error.message.includes('INVALID_AUTH')){
            //     setErrorCount(thisCount => {
            //         console.log(thisCount)
            //         return thisCount + 1
            // });

                errorCount = errorCount + 1;

                // console.log(errorCount)

                //-- Hit MAX so exit
                if(errorCount >= maxErrorCount) setMaxErrorsMet(true)

                //-- re-running this function so counts up
                else handleErrorCount({waitTime, maxErrorCount, errorCount});

                return errorCount
            }
        }, waitTime);

        return errorCount
    }



    // const handleCountDown = ({
    //     waitTime = 1000,
    //     maxErrorCount=10,
    //     errorCount,
    //     startTime
    // }) => {

    //     return setTimeout(() => {

    //         //-- Increment
    //         errorCount = errorCount + 1;
    //         handleErrorCount({waitTime, maxErrorCount, errorCount, startTime});
    //         return errorCount
    //     }, waitTime);


    // };

    //---------------------------------------------------------------------------
    //-- Use Effects
    useEffect(() => {
        if(error && isLoaded ) handleErrorCount({ waitTime : 1000, maxErrorCount:10, errorCount :1 });
        if(!isLoaded) setIsLoaded(true);


        //-- Clear timeout if leave page before maxErrorCount hit.
        return () => {
            //-- IF exiting page, don't want to keep running this
            clearTimeout();
        }

    }, [error, loading, data])




    //---------------------------------------------------------------------------
    //-- RETURNS

    /** GraphQL Error or some sort */
    if(error){

        return (
            <div
                className='page__div'
                key={uuidv4()}
            >
                <section className='page__section cards'>
                    <h2 className='page__section__title'>Establishing connection...</h2>
                    <p id='attemptCount'></p>
                    {/* TODO:: add this to work */}
                    {/* {console.log(error)} */}
                    {() => {
                        var timeLeft = 10;
                        var downloadTimer = setInterval(function(){
                          if(timeLeft <= 0){
                            clearInterval(downloadTimer);
                          }

                          document.getElementById("attemptCount").value = 10 - timeLeft;
                          timeLeft -= 1;
                        }, 1000);
                    }}
                    <Loading />
                    {/* {JSON.stringify(error?.[0])} */}
                    {/* {console.log(error.message)}
                    {console.log(JSON.stringify(error.message))} */}


                </section>


                {/** Managing Graph QL Errors. If max is met, logs out user and redirects.
                 *
                 */}
                {maxErrorsMet === true
                        ? HandleLogout(useAuthService, 5000)
                        : null
                }
            </div>

        )
        // return <Loading />;
    }


    if( loading || !data )  {
        clearTimeout(); //-- if error timeout happening, clear it
        return <Loading />
    };

    if(data) clearTimeout(); //-- if error timeout happening, clear it

    //-- Otherwise render results
    return (
        <div    className='page__div'
                key={uuidv4()}
        >
            {/* SENSORS CARDS - Default, users viewing alerts by cards */}
            { showCards
                ?   <section className='page__section cards'>
                        <div className='page__section__header' >
                            {/* PAGE NAME */}
                            <h3 className='page__section__title'>Sensors</h3>

                            {/* SEARCH FILTERS */}
                            <input  type='text'
                                    //TODO:: 20221022 #EP || Add search filter option here
                                    placeholder='Search Placeholder'
                                    style={{
                                        //TODO:: 20221022 #EP || Remove inline styling once polished
                                        marginLeft: 'auto',
                                        float: 'right',
                                        // position: 'relative',
                                        right: '1.25rem',
                                        width: '30%'
                                    }}
                            />

                        </div>

                        {/* BUILDING CARDS */}
                        <div className="cards__wrapper" key={uuidv4()}>
                            {/* {handleBuildCards().map((card) => {
                                return card
                            })} */}


                            {/** If the query returned sensors, build the cards, otherwise return null
                            */}
                            { sensors.length > 0
                                    ? HandleBuildCards(sensors).map((card) => {  return card })
                                    : null
                            }

                        </div>
                    </section>
                : null
            }


            {/* SENSORS TABLE SECTION - If User Opts to show table instead of cards */}
            {/*TODO:: 102022 #EP || TO Hide Table? */}
            {showTable
                ?   <section className='page__section'>
                        <div className='page__section__header' >
                            <h3 className='page__section__title'>
                                Table
                            </h3>
                        </div>
                        <Table
                            key={uuidv4()}
                            name                 = { 'Sensors' }
                            data                 = { sensorsTable?.data }
                            header               = { sensorsTable?.header }
                            results              = { sensorsTable?.results }
                            defaultSearchFilter  = { sensorsTable?.defaultSearchFilter }
                            toggleShowSearch     = { sensorsTable?.toggleShowActions }
                            toggleShowActions    = { sensorsTable?.toggleShowActions }
                            definedActions       = { sensorsTable?.definedActions }
                        />
                    </section>
                : null
            }

        </div>
    )
};
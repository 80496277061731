//-- Verify if React is mounted to prevent loading issues.
import { useEffect, useState } from "react";

export default function useIsMounted() {
  let [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
}

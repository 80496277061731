import React, { useContext } from "react";
import { GlobalContext } from "../../../App";

const EditRow = ({
  tableData,
  tableHeader,
  Cancel,
  setTableData,
  editData,
  setEditData,
  showActions,
  actions
}) => {
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const Save = (e) => {
    e.preventDefault();
    let filterData =  tableData.filter((e) => e.id !== editData.id);
    let updatedData = [...filterData, { ...editData }]
                      .sort((a, b) => a.id > b.id ? 1 : -1
    );
    setTableData(updatedData);
    // const delData = tableData.filter((tbd) => {
    //   return editData.id !== tbd.id;
    // });
    // setTableData(delData);
  };

  const { uuidv4 } = useContext(GlobalContext);
  return (
    
      <tr>
        {tableHeader.map((th) => {
          return ( 
            // <td key={uuidv4()}>
            <td key={`${editData.id}-${th.field}`}>
              <span className='table__header_inline'>
                {th.field}
              </span>
              <span className='table__cell_value'>
                <input
                  type="text"
                  name={th.field}
                  value={editData[th.field]}
                  onChange={handleEditChange}
                />
              </span>
            </td>
          )
        })}
        
      { showActions === true
                        ? (
                            <td className="flex-row-center m-a">
                            <button className="btn btn-dark p-200"
                                    type="submit"
                                    onClick={Save}>
                              Save
                            </button>
                            <button className="btn btn-danger p-200"
                                    type="button"
                                    onClick={Cancel}>
                              Cancel
                            </button>
                          </td>
                        ) : null
      }
      </tr>
    
  );
};

export default EditRow;
import React from 'react'

export default function About() {
  return (
    <div className='page__container'>
      <section
        className='page__header'
      >
        <h2>
          About
        </h2>
      </section>

      <section>
        About section placeholder.
      </section>
    </div>
  )
}

import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../App';
import './assets/css/style.css'; // Import the CSS file for this component

export default function Settings() {

    const {
        Loading
    } = useContext(GlobalContext);

    const [pageLoading, setPageLoading] = useState(true);

    useState(() => {
        setTimeout(() => {
                setPageLoading(false);
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
    }, []);

    if(pageLoading){
        return <Loading />;
    }
    else {
    return (
        <div
            className='page__div'
        >
            <div
                className='page__section'
            >
                Settings placeholder
            </div>
        </div>
    )
    }
}

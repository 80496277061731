/** ./context/Alerts/Index.js -> Alerts class
 * 
 * Used to manage GraphQL and Alerts.
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import { ALERTS_GET } from '../../services/gql/Query';

class Alerts extends React.Component {
    constructor(props) {
        //-- END OF ALERTS CONSTRUCTOR
        super(props);
        this.alerts = [];       //-- Array of Alert Objects
    };

    handleGetAlerts = async ( props ) => {
        /** GraphQL Query to get Alerts from Database
         * 
         * @param {OBJECT} props = {NULL}
         * TODO: 11/27/2022 #EP || Add ability to filter request/response
         */

         const {
            loading,
            data,
            error,
            startPolling,
            stopPolling
        } = useQuery( ALERTS_GET );

        //! TODO:: 11/27/2022 #EP || Pick up here.
        // try {
            
        // }
        // catch(err) {
        //     console.log('ERROR: Failed to get Alerts from Database.');
        //     console.log(err);
        // }

        return data, loading, error
    }

    
    

    getUser() {
        //TODO:: 082522 #EP || Add GQL logic here
        
        return this.user;
    };

    // handleLogin = async ( props ) => {

    //     const [ login, { data, loading, error } ] = useMutation(USER_LOGIN);
        
    //     try {
    //         const { data, loading, error } = await login({
    //             variables: { ...props },
    //         });

    //         // if(error)   return error
    //         // if(data){
    //         //     //-- LOGIN SUCCESS, UPDATE JWT WITH AUTH AND RE-ROUTE
    //         //     // console.log("data: ", data)
    //         //     handleAuthService.login(data.login);
    //         // }
    //     }
    //     catch( error ){
    //         console.log(error);
    //     }
        
    
    //     //TODO:: 082522 #EP || Build this out to work. 
    //     return data, loading, error
    // }

    setAlert( alert_id = Number(), action_id = Number() ){
        /* Provide a user object to define which features are available to the user.
        * 
        * @param {NUMBER}   alert_id    = UID of Alert
        * @return {NUMBER}  action_id   = UID of Action user asking to execute.
        */


        // const results = {
        //     message : null,
        //     success : null,
        //     error   : null
        // }

        try{
            if(alert_id && action_id) {
                // TODO:: 11/27/2022 #EP || Add LOGIC here
                
                return {
                    message : 'SUCCESS: Completed setAlert.',
                    success : true,
                    error   : null
                }
            }
            else {
                return {
                    message : 'Failed to setAlert.',
                    success : null,
                    error   : 'ERROR: null alert_id provided.'
                }
            }
        }
        catch(err) {
            return {
                message : 'ERROR: Failed to setAlert.',
                success : false,
                error   : err
            }
        }
        //-- END OF setUser
    };
}

export default new Alerts();

import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../App';

import './assets/css/style.css'; //-- Clock specific css

export default function Clock() {

    const {
        dateTimeFullLocal
    } = useContext(GlobalContext)

    //----------------------------------------------------------------------------
    //-- CLOCK - Extracting current Time to update every 1 second
    const [time, setTime] = useState( () => {
    setInterval(() => {
        setTime(dateTimeFullLocal(new Date()
        ),1000);
    }) //-- grab DOM timezone, return time in HH:MM:SS aa format
    });

    return (
    <span  className='clock'>
        {/* //TODO:: 05/24/22 #EP | Make Clock appear if logged in. */}
        { time }
    </span> 
    )
}

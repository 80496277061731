import React, { useContext } from 'react';
import { GlobalContext } from '../App';
import Settings from '../features/Settings';

export default function SettingsPage() {

  // const {
  //   pages
  // } = useContext(GlobalContext);

  // const thisPage = pages.find(page => page.name === 'settings');

  return (
    <div className='page__container' >
      <Settings />
    </div>
  )
}

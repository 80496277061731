import React from 'react'

export default function footer(props) {
    const {
        status
    } = props;
  return (
    <section className='modal__footer'>
        <span className='width-1000 m-500'>
            {status
              ? <> {status} 
              {/* TODO:: 10162022 #EP || Add more details here */}
              </>
              : null
            }
        </span>
    </section>
  )
}

import React from 'react';
import Login from '../features/Login'

export default function LoginPage() {
  return (
    <div className='page__container'>
        <Login />
    </div>
  )
}

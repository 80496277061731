import React, {useEffect, useState } from 'react';
import Banner from '..';


export default function useHandleBanner({ title=undefined, message=undefined, style=undefined }) {

    useEffect(() => {
        if(message !== undefined){
            console.log('//-- useHandleBanner: ', message, style);
        }
    }, [message, style]);

    if(message !== undefined){
        return(
            <Banner title={title} message={message} style={style} />
        )
    }

}
import React, {useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthService from '../hooks/useAuthService';

// import { useMutation } from '@apollo/client';
// const {  USER_LOGIN } = '../features/login/gql/mutation';

import HandleLogout from '../hooks/handleLogout';


export default function Logout() {
  
  const navigate = useNavigate();
  
  //TODO:: 082722 #EP || Require db post to logout to happen
  // const [login, {data, error, load }] = useMutation(USER_LOGIN)

  const handleLogout = async (e) => {

    useAuthService.logout()
    try {
      // const { data, loading, error  } = await logout({
      //     variables: { ...useAuthService.getUserId() },
      // })

    }
    catch (error) {
      console.log(error)
    }
  }

  // useState(() => {
  //   console.log("rendering logout")
  // }, []);

  if(!useAuthService.isLoggedIn()) return window.location.assign('/login');


  else {
    return (
      <div className='page__container'>
        <div className='page__div'>
          <section className='page__section' >
            Are you sure you want to logout?
            <br/>

            <button
              onClick={ (e) => { HandleLogout(useAuthService, 0) }}
            >
              Logout
            </button>
              
            <button
              onClick={ () => {  navigate("/dashboard") }}
            >
              Return to Dashboard
            </button>
          </section>
        </div>
      </div>
    )
  }
}

import React, { Component, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'; //-- Being used to generate unique keys within pages
import { GlobalContext } from '../../App';

import Icon from '../../components/icon';


//-- Importing all pages to be used within the index

import About                from '../../pages/About';
import Account              from '../../pages/Account';                               //-- Signed in User Account Management
import Admin                from '../../pages/Admin';
import Customers            from '../../pages/Customers';
import Dashboard            from '../../pages/Dashboard';                             //-- This is the Homepage for logged in user
import Distributors         from '../../pages/Distributors';
import Equipment            from '../../pages/Equipment';                             //-- Equipment Management
import Help                 from '../../pages/Help';
import Homepage             from '../../pages/Home';
import Locations            from '../../pages/Locations';
import Logs                 from '../../pages/Logs';                                  //-- This is meant to be a complete log of all things
import Login                from '../../pages/Login';
import Logout               from '../../pages/Logout';
import Alerts               from '../../pages/Alerts';                         //-- Logged in users notification management
import NotFound             from '../../pages/NotFound';
import Resellers            from '../../pages/Resellers';
import Sandbox              from '../../pages/Sandbox';
import Sensors              from '../../pages/Sensors';                               //-- Sensor Management dashboard   
import Sensor              from '../../pages/Sensor';                                 //-- Individual Sensor
import Settings             from '../../pages/Settings';                              //-- Logged in Users specific settings
import Scheduler            from '../../pages/Scheduler';                             //-- This is used to manage scheduling service based on lsat service date
import Signup               from '../../pages/Signup';



/* Types of account for reference
admin
reseller
distributor
customer
null
*/

class Pages {
    /* Called by App.js to retrieve pages available to the user based on their account type.
    *
    * @param    user:   object user data
    * @returns  Pages:  array of page objects with title and summary 
    *
    * 
    *  Page Object Structure / template (for reference):
    *        'pagename': {
    *              "auth"            : [null, "admin", "reseller", "distributor", "customer"],      //-- Who has access based on logged in user. null is used for not logged in. Everything else is a security level/group.
    *              "description"     : "Summary of what the page is for hover descriptions",        //-- Used in hover descriptions. A brief overview of what to expect from this page in 50 characters or less.
    *              "displayName"     : "Page Name",                                                 //-- As appears within app. Used for nav and some pages.
    *              "tagline"         : "Page tagline goes here if page has one.",                   //-- Designed to be used in page headers. Similar to description but more concise.
    *              "icon"            : <Icon                                                        //-- The icon that represents hte page. If not set wont render one. Even if has one doesn't always mean it will be used in app.
    *                                      name            ="iconName"                              //-- Name of the icon to use. If not set, default is Bair.
    *                                      width           ="##px"                                  //-- Width of the icon. If not set, default is 30px.
    *                                      height          ="##px"                                  //-- Height of the icon. If not set, default is 30px.
    *                                      background_c    = ""                                     //-- Background color of the icon. If not set, default is var(--icon-bg-clr).
    *                                      svg_c           = ""                                     //-- Color of the icon. If not set, default is var(--icon-svg-clr).
    *                                   />,                                                     
    *              "parentElements"  : [null, "nav", "footer"],
    *              "routes"          : [null, "/pagename"],>,        
    *              "tagline"         : "Page tagline goes here",          
    *         }
    *            
    *            //-- who has access
    *            //-- How to render anywhere it's printed                                                     
    *            //-- null if none otherwise tagline can be used in header or wherever
    *            //-- The icon to be used when displaying link. Use null if none. 
    *                                    //-- Name of the icon to be used ( generally page but could be anything. Defaults to bair if doesn't exit or undefined)
    *                                    //-- Width of the icon. Default is 30px if not specified.
    *                                    //-- Height of the icon. Default is 30px if not specified.
    *                                    //-- CSS Value of background color of the icon. Default is  Accent Color if not specified.
    *                                    //-- Color of the SVG within. Default is black if not specified.                                                                   
    *            //-- Where to render links to this page. null = don't show. Generally only 1 location but built so can be in multiples.
    *           /-- Route Paths to this page if any. null = none.
    *            //-- React component to render with defined props.
    *       }
    *
    *   Author: Erik Plachta
    *   Date:   07302022
    *   Notes:  This context massively simplified the root App and helped me organize the application.
    */
    
    constructor() {    

        //-- The user that's requesting pages
        // this.user = {};
        this.userType = undefined;
        //-- This is the index of all pages that could be available to the user
        this.index = [
            // 'home': 
            {
                auth            : ['guest'],
                description     : 'This is the default landing page for non-logged in users.',
                displayName     : 'Home',                                                     
                icon            : <Icon name="bair" />,
                jsx             : <Homepage key={uuidv4()} uuidv4={uuidv4} />,
                parentElements  : ['nav', 'router'],
                name            : 'home',
                routes          : ['/', '/home', '/homepage'],
                tagline         :'Homepage tagline goes here.'
            },
            // 'about' :
            {
                // auth            : ['guest'],
                auth:           [''], //10012022 #EP || Removed from nav, expecting to make the homepage the only about page solution
                description     : 'This is the default landing page for non-logged in users.',
                displayName     :'About',
                icon            : <Icon name="about" />,
                jsx             : <About key={uuidv4()} uuidv4={uuidv4} />,
                name            : 'about',
                parentElements  : ['nav', 'router'],
                routes          : ['/about'], 
                tagline         :'This is a tagline placeholder', 
            },
            // 'account' :  for header
            // {
            //     auth            : ['admin', 'reseller', 'distributor', 'customer'],
            //     description     : 'tbd',
            //     displayName     : 'Account',
            //     enabled         : true,
            //     icon            : <Icon name="account" background_c="transparent" />,
            //     jsx             : <Account key={uuidv4()} uuidv4={uuidv4}/>,
            //     parentElements  : ['header', 'router'],
            //     name            : 'account',
            //     routes          : ['/account'],
            //     tagline         : 'This is a tagline placeholder', 
            // },
            
            // 'signup' : 
            {
                auth            : ['guest'],
                description     : 'tbd',
                displayName     : 'Signup', 
                icon            : <Icon name="signup" />,
                jsx             : <Signup key={uuidv4()} uuidv4={uuidv4}/>,
                parentElements  : ['router'],
                name            :'signup',
                routes          : ['/signup'],
                tagline         : 'Signup for an account tagline', 
            },
            // 'login' : 
            {
                auth            : ['guest'],
                description     : 'tbd',
                displayName     : 'Login',
                enabled         : true,
                icon            : <Icon name="login"/>,
                // icon            : <Icon name="login" background_c='transparent'/>,
                jsx             : <Login key={uuidv4()} uuidv4={uuidv4} />,
                // parentElements  : ['nav', 'header', 'router'],
                parentElements  : ['nav', 'router'],
                name            : 'login',
                routes          : ['/login'],
                tagline         : 'This is a tagline placeholder', 
            },
            // 'dashboard' : 
            {
                // auth            : ['admin', 'reseller', 'distributor', 'customer'],
                auth            : [''],
                description     : 'tbd',
                displayName     :'Dashboard',
                icon            : <Icon name="dashboard" />,
                jsx             : <Dashboard key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 1,
                // parentElements  : ['nav', 'router'],
                parentElements  : [''],
                name            : 'dashboard',
                routes          : ['/dashboard', '/dash', '/d'],
                tagline         : 'Dashboard tagline here', 
            },
            // 'equipment' : 
            {
                //TODO:: 082822 #EP || Add auth back once built out
                // auth            : ['admin', 'reseller', 'distributor', 'customer'],
                auth            : [''],
                description     : 'tbd',
                displayName     : 'Equipment',
                icon            : <Icon name="equipment" />,
                jsx             : <Equipment key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 2,
                parentElements  : ['nav', 'router'],
                name            : 'equipment',
                routes          : ['/equipment', '/e'],
                tagline         : 'Equipment tagline here', 
            },
            // 'sensors': 
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Sensors',
                enabled         : true,
                icon            : <Icon name="compressor_bw" width='30px' height='30px' />,
                jsx             : <Sensors key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 3,
                parentElements  : ['nav', 'router'],
                name            :'sensors',
                routes          : ['/sensors'],
                tagline         : 'sensors tagline here', 
            },
                // 'sensor': 
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Sensor',
                enabled         : true,
                icon            : <Icon name="compressor_bw" width='30px' height='30px' />,
                jsx             : <Sensor key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 3,
                parentElements  : [],
                name            :'sensors',
                routes          : ['/s/:id'],
                tagline         : 'sensor tagline here', 
            },
            // 'scheduler':
            {
                auth            : ['admin', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     :  'Scheduler',
                enabled         : false,
                jsx             : <Scheduler key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 4,
                icon            : <Icon name="calendar" />,
                parentElements  : ['router'],
                name            : 'scheduler',
                routes          : ['/scheduler'],
                tagline         :'Scheduler tagline here', 
            },
            
            // 'alerts': 
            {
             
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     :'Alerts',
                enabled         : true,
                icon            : <Icon name="notifications" />,
                jsx             : <Alerts key={uuidv4()} uuidv4={uuidv4}/>,
                // parentElements  : ['header', 'router', 'nav'],
                parentElements  : ['router', 'nav'],
                name            : 'alerts',
                routes          : ['/alerts'],
                tagline         :'Alerts tagline here'
            },
            // 'logs': 
            {
                auth            : ['admin', 'distributor'],
                description     : 'tbd',
                displayName     :'Logs',
                enabled         : false,
                icon            : <Icon name="logs" />,
                jsx             : <Logs key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 5,
                parentElements  : ['router'],
                name            :'logs',
                routes          : ['/logs'],
                tagline:'Logs tagline here', 
            },
            // 'admin': 
            {
                auth            : ['admin'],
                description     : 'tbd',
                displayName     : 'Admin',
                enabled         : false,
                icon            : <Icon name="Admin" />,
                jsx             : <Admin key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 6,
                parentElements  : ['router'],
                name            : 'admin',
                routes          : ['/admin'],
                tagline         :'Admin tagline here', 
            },
            // 'resellers': 
            {
                auth            : ['admin', 'reseller'],
                description     : 'tbd',
                displayName     :'Resellers',
                enabled         : false,
                icon            : <Icon name="resellers" />,
                jsx             : <Resellers key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 7,
                parentElements  : ['router'],
                name            : 'resellers',
                routes           : ['/resellers'],
                tagline         :'resellers tagline here', 
            },
            // 'distributors': 
            {
                auth            : ['admin', 'reseller', 'distributor'],
                description     : 'tbd',
                displayName     : 'Distributors',
                enabled         : false,
                icon            : <Icon name="distributors" />,
                jsx             : <Distributors key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 8,
                parentElements  : ['router'],
                name            :'distributors',
                routes          : ['/distributors'],
                tagline         : 'distributors tagline here', 
            },
            // 'customers': 
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     :'Customers',
                enabled         : false,
                icon            : <Icon name="customers" />,
                jsx             : <Customers key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 9,
                parentElements  : ['router'],
                name            : 'customers',
                routes          : ['/customers'],
                tagline         : 'customers tagline here', 
            },
            // 'locations': 
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Locations',
                enabled         : false,
                icon            : <Icon name="pin" />,
                jsx             : <Locations key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 10,
                parentElements  : ['router'],
                name            : 'locations',
                routes          : ['/locations'],
                tagline:'Locations tagline here', 
            },
            // 'sandbox': 
            {
                auth            : ['admin'],
                description     : 'Testing features and content here to be reviewed by the Bair admin team.',
                displayName     : 'Sandbox',
                enabled         : true,
                icon            : <Icon name="sandbox" background_c="rgba(255,255,255, 0.3" />,
                jsx             : <Sandbox key={uuidv4()} uuidv4={uuidv4}/>,
                parentElements  : ['router'],
                name            : 'sandbox',
                routes          : ['/sandbox'],
                tagline         : 'Developer sandbox', 
            },
            // 'settings':      //091722 #EP || Disabled as I don't believe I need this.
            {
                // auth            : ['admin', 'reseller', 'distributor', 'customer'],
                auth            : [''], //091722 #EP || Disabled as I don't believe I need this.
                description     : 'tbd',
                displayName     : 'Settings',
                enabled         : false, //091722 #EP || Disabled as I don't believe I need this.
                icon            : <Icon name="gear" background_c="transparent" />,
                jsx             : <Settings key={uuidv4()} uuidv4={uuidv4}/>,
                // parentElements  : ['header', 'router'],
                parentElements  : [], //091722 #EP || Disabled as I don't believe I need this.
                name            : 'settings',
                routes          : ['/settings'],
                tagline         : 'settings tagline here', 
            },
            // 'help': 
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Help',  
                icon            : <Icon name="help" />,
                jsx             : <Help key={uuidv4()} uuidv4={uuidv4} />,
                parentElements  : ['guest'],
                name            : 'help',
                routes          : ['/help'],
                tagline         : 'This is a tagline placeholder', 
            },
            // 'notfound': 
            {
                auth            : ['guest', 'admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : '404 - Page Not Found',  
                icon            : <Icon name="notfound" />,
                jsx             : <NotFound key={uuidv4()} uuidv4={uuidv4}/>,
                parentElements  : ['router'],
                name            : 'notfound',
                routes          : ['/notfound', '/404'],
                tagline         : 'This is a tagline placeholder', 
            },
            // 'account' :  for navigation bar //NOTE:: Near end to ensure appears furthest right
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Account',
                enabled         : true,
                // icon            : <Icon mobile={true} name="account" />,
                icon            : <Icon name="account" />,
                jsx             : <Account key={uuidv4()} uuidv4={uuidv4}/>,
                parentElements  : ['nav', 'router'],
                name            : 'account',
                routes          : ['/account'],
                tagline         : 'This is a tagline placeholder', 
            },
            // 'logout':    //NOTE: This is near the end because if it's in vertical nav I'm having git appear on the bottom of the menu like this
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Logout', 
                enabled         : true,
                icon            : <Icon name="logout"  background_c="transparent"/>,
                jsx             : <Logout key={uuidv4()} uuidv4={uuidv4} />,
                order           : 99,
                // parentElements  : ['nav', 'header', 'router'],
                parentElements  : ['header', 'router'],
                name            : 'logout',
                routes          : ['/logout'],
                tagline         : 'This is a tagline placeholder', 
            },
            {
            // '*':     IF NOT Logged In, Wildcard catch Home
                // auth            : ['guest','admin', 'reseller', 'distributor', 'customer'],
                auth            : ['guest'],
                description     : 'This is the default landing page for non-logged in users.',
                displayName     : 'Home',                                                     
                icon            : <Icon name="bair" />,
                jsx             : <Homepage key={uuidv4()} uuidv4={uuidv4} />,
                parentElements  : [],
                name            : 'wildcard',
                routes          : ['*'],
                tagline         :'Homepage tagline goes here.'
            },
            
            // '*':     IF NOT Logged In, Wildcard catch for Dashboard
            // {
            //     auth            : ['admin', 'reseller', 'distributor', 'customer'],
            //     description     : 'tbd',
            //     displayName     :'Dashboard',
            //     icon            : <Icon name="dashboard" />,
            //     jsx             : <Dashboard key={uuidv4()} uuidv4={uuidv4}/>,
            //     order           : 1,
            //     parentElements  : [''],
            //     name            : 'dashboard',
            //     routes          : ['*'],
            //     tagline         : 'Dashboard tagline here', 
            // },
            //! 10032022 #EP || Changed to Sensors as auth wildcard to get MVP asap
            // '*':     IF NOT Logged In, Wildcard catch for Sensors
            {
                auth            : ['admin', 'reseller', 'distributor', 'customer'],
                description     : 'tbd',
                displayName     : 'Sensors',
                enabled         : true,
                icon            : <Icon name="compressor_bw" width='30px' height='30px' />,
                jsx             : <Sensors key={uuidv4()} uuidv4={uuidv4}/>,
                order           : 3,
                parentElements  : [''],
                name            :'sensors',
                routes          : ['/*'],
                tagline         : 'sensors tagline here', 
            },
        ]
        
    }

    

    getUserType() {
        
        return this.userType;
    }

    setUserType( user = undefined ){
        //TODO:: 08132022 #EP || Remove this function and use the one in the User Clsas - only store type if needed.
        /* Provide a user object to define which features are available to the user.
        * 
        * @param {OBJECT} user - User object.
        * @return {BOOL}  bool - True if user is set, false if not.
        */

        if(user) {this.userType = user.auth.type;  return true};
        return false;
    };

    getPages( handler = undefined ) {
        /** Provide handler, (what's requesting the page data), and get all related pages. 
        * 
        * @param {STRING} handler   - What's using the page(s). Could be navigation, header, or other things too.
        * @return {ARRAY} index     - All pages related to the handler.
        */

        if(handler){
            try {
                const pagesByUserType = this.index.filter(page => page.auth.includes(this.userType));
                const pagesByHandler = pagesByUserType.filter(page => page.parentElements.includes(handler));
                return pagesByHandler;
            }
            catch(err) {
                // console.log("ERROR: ", err)
                //TODO:: 07302022 #EP || Add proper auth error handling here.
                return [{
                    'name': `CATCH ERROR: Verify handler '${handler}' value.`,
                    'error': JSON.stringify(err)
                }];
            }
        }
        
        //-- no handler so exist without results
        return false;
    };

    getPage( user = Object(), handler = String(), name = String(), debug = Boolean() ) {
        /** Request a specific page by name to get the JSX Object returned
         *
         * @param {OBJECT} user     : User Object Details for who's making the request.
         * @param {STRING} handler  : What is making the page request
         * @param {STRING} name     : The name of the page being requested
         * 
         * @return {OBJECT} results     
         * 
         *          Bool false if no name provided OR if name doesn't exist
         *          Bool True and JSX object IF named provided and exists
         */

        console.log("-------")
        console.log("Pages.getPage(user,handler,name): ", user, handler, name)
        console.log("-------")

        //-- No request so exist
        if(name.length < 0 ) return false;

        //TODO:: 09132022 #EP || Add logic for better quality control here once verified works
        //update request options based on provided user
        //update request options based on handler making request

        //-- Look for page based on user's params
        const pagesAvailableByUserType = this.index.filter(page => page.auth.includes(this.userType));
        const pagesAvailableToHandler = pagesAvailableByUserType.filter(page => page.parentElements.includes(handler));
        
        
        if (debug) console.log("pagesAvailableToHandler: ", pagesAvailableToHandler)
         
        const pageRequested = pagesAvailableToHandler.filter(page => page.name.includes('home'));

        console.log("pageRequested: ", pageRequested[0]?.jsx)
        // console.log("pageRequested['jsx']: ", JSON.stringify(pageRequested[0]['jsx']))

        
        //-- If successfully found a page, return the JSX with true value
        if(pageRequested[0]?.jsx) return pageRequested[0].jsx

        //-- If did not find page, return false with empty obj
        if(!pageRequested[0]) return{'status': false, 'jsx': {}}
    };

    getRoutes(debug = Boolean(), handler = String()) {
        /** Get all routes for the app based on user auth.
         * 
         * @param {BOOL} debug - If true, will log the results to the console.
         * @param {STRING} handler - What is making the request. (header, nav, etc)
         * TODO:: 091722 #EP || Onboard handler
         * 
         * @return {ARRAY} routes - All routes for the app based on user auth.
         */

        //-- Get all pages that are available to the user
        const pagesAvailableByUserType = this.index.filter(page => page.auth.includes(this.userType));
        
        //-- Build Object of routes to return to the app router
        const routesAvailable = pagesAvailableByUserType.map( page => {
            // page.auth.includes(this.userType)

            //-- if provided a handler
            if(handler?.length > 0){
                if (debug) console.log("handler: ", handler)
                
                //-- only return pages that have the provided handler and meet user auth
                if(page?.parentElements.includes(handler)
                && page?.enabled === true){
                    return {
                        name    : page['name'], 
                        jsx     : page['jsx'],
                        routes  : page['routes'],
                        icon    : page['icon']
                    }
                } 
            }

            //-- otherwise return all pages just based on user auth
            if(!(handler?.length > 0)){
                return {
                        name    : page['name'], 
                        jsx     : page['jsx'],
                        routes  : page['routes']
                }
            }
        });
        
        if (debug) console.log("//-- Context/Pages/getRoutes():    Need to finish building routes dynamically.")
        // console.log(routesAvailable)
        return routesAvailable;
    };
}
export default new Pages();

import React from 'react';

import Account from '../features/Account';

export default function AccountPage() {
  return (
    <div className='page__container' >
      <Account />
    </div>
  )
}

export function handleGetNavigation(components = [] ) {
    /** Send the index, current location, and target location to update accordingly.
     * 
     * @param {object} components   - All component data for current modal.
     * @return {object}             - The navigation object containing all enabled components.
    */

    console.log("handleGetNavigation: components:", components);
    //-- return the navigation object based on requestors ID
    const enabledComponents = {}
    
    Object.keys(components).map((component, pages) => {
        if(components[component].enabled) enabledComponents[component] = components[component];
        else {console.log("Disabled: ", component)}
    });

    // console.log("enabledComponents: ", enabledComponents);
    //-- Otherwi\se return empty object
    return enabledComponents || {};
};

export function handleActivePage( pages = {}, setActivePage, target = undefined) {
    /** Toggle the navigation state of the targetId to state-active and all others to state-inactive.
     * 
     * @param {object} pages    - Full nav index object.
     * @param {string} target    - The target location ID.
     * 
    */

//    console.log("handleActivePage: pages:", pages);
    
   Object.keys(pages).map((page, index) => {
    if ( target === page ) {
        // console.log("target: ", target);
        return setActivePage(target)
    }
    
    else if (pages[page].active === true && pages[page].enabled === true) {
            // console.log("setActivePage(pages[page].title): ", pages[page]);
            return setActivePage(pages[page].title)
        };
        // pages[page].active = false;
        // if(page === target) pages[page].active = true;
    
    });
    
    
    //-- Otherwise return first index result or EMPTY object if none defined 
    // return index[Object.keys(index)[0]] || {};
};

export function handleSetNavigation(pages = {}, currentId = undefined , targetId = undefined ) {
    /** Send the index, current location, and target location to update accordingly.
     * 
     * @param {object} pages    - Full nav index object.
     * @param {string} currentId  - The current location ID. 
     * @param {string} targetId   - The target location ID.
    */
   
    //-- Return the navigation object based on targetId
    if(pages[targetId])     return pages[targetId];
    
    //-- Requested ID doesn't exist, return navigation object for currentId
    if(pages[currentId])    return pages[currentId];

    //-- Otherwise return first pages result or EMPTY object if none defined 
    return pages[Object.keys(pages)[0]] || {};
};

function handleToggleNavigationState(pages = {}, currentId = undefined, targetId=undefined) {
    /** Toggle the navigation state of the targetId to state-active and all others to state-inactive.
     * 
     * @param {object} pages    - Full nav pages object.
     * @param {string} currentId  - The current location ID.
     * @param {string} targetId   - The target location ID.
    */
   
    //-- Return the navigation object based on targetId
    if(pages[targetId])     return pages[targetId];

      //-- Requested ID doesn't exist, return navigation object for currentId
      if(pages[currentId])    return pages[currentId];
    
    //-- Otherwise return first pages result or EMPTY object if none defined 
    return pages[Object.keys(pages)[0]] || {};
};

// module.exports = {
//     handleGetNavigation,
//     handleSetNavigation
// };

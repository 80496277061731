import React, { useContext } from 'react';
import './assets/css/style.css'; //-- Login specific styles
import BairLogo from '../../components/icon/assets/svg/bair-geometric.svg';
import { GlobalContext } from '../../App';
// import {ReactComponent as Bair } from  '../../components/icon/assets/svg/bair-geometric.svg';

export default function Logo() {

  const { Icon } = useContext(GlobalContext);
  return (
      <img className='brand logo' src={BairLogo} alt="Bair Logo" />
      // <Bair />
  )
}

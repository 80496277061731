/**  Modal component management
 * 
 */


export function handleSetComponents( components= Object() , modifyComponents=Object() ){
    /** Takes base components and modified components. Merges modified into base and returns updated.
     * 
     * @param {object} components - base components
     * @param {object} modifyComponents - modified components
     * @return {object} - Merged components
     */

    //-- Create OBJ to hold what's returned and spread in base values
    const verifiedComponents = {...components};

    // console.log("handleSetComponents...", "\n\tcomponents: ", components, "\n\tmodifyComponents: ",modifyComponents);

    //-- Loop through modified components and merge into base verifiedComponents if the component name exists in base
    Object.keys( modifyComponents ).forEach( componentName => {
        if( verifiedComponents[componentName] ){
            verifiedComponents[componentName] = {...verifiedComponents[componentName], ...modifyComponents[componentName]};
        }
    } );

    // console.log("\n\tverifiedComponents: ", verifiedComponents);
    return verifiedComponents;
};

export function handleGetComponent( components, requestedComponent ){
    /** Provide components and the requestedComponent, get component OBJ back.
     * 
     * @param {object} components - base components
     * @param {string} requestedComponent - name of component to get
     * 
     * @return {object} - component OBJ or empty OBJ if not found
     */
    
    if( components[requestedComponent] ){
        return components[requestedComponent];
    }
    // console.log("handleGetComponent: ", "\n\trequestedComponent: ", requestedComponent, "\n\tcomponents: ", components);
    return {};
};

// module.exports = {
//     handleSetComponents,
//     handleGetComponent
// };

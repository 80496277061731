// import React from 'react';


//TODO:: 082722 #EP || Remove GQL from here and move to use hook
// import { v4 as uuidv4 } from 'uuid'; //-- Being used to generate unique keys within pages
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';

// const useAuthService = require('../../hooks/useAuthService');
const { USER_LOGIN } = './gql/mutation';
// const { USER_SESSIONSTATE_GET } = './gql/query';
// const { USER_LOGIN } = '../../services/gql/Mutation';
// const { USER_GET } = '../../services/gql/Query';

class User {
    constructor() {
        this.user = {
            /** ID
             * @param {STRING} id - UID for the user from the server.
             */
            id: undefined,
            
            //------------------------------------------------------------------
            //-- FROM SESSION_STATE
            Alert: [],              //-- Array of alerts
            Unit: [],               //-- Array of units
            user: {                 //-- User Details
                _id: undefined,
                email: undefined,
                name_first: undefined,
                name_last: undefined,
                Account: [],
                Business: [],

            },                
            alert_count: 0,         //-- Number of alerts

            //------------------------------------------------------------------
            
            
            alerts: {               //TODO:: Remove this
                alert_count: 0,
                alerts: []
            },
            name_first: undefined,
            name_last: undefined,
            date_created: undefined,

            /** auth
             *  
             * @param       {OBJECT} auth           - The JWT token decoded.
             * @property    {STRING} auth.id        - The user's ID. 
             * @property    {STRING} auth.role      - The user's role.
             * @property    {STRING} auth.name      - The user's name.
             * @property    {BOOLEAN} auth.success  - True or False
            */
            auth: {
                id: undefined,
                role: undefined,
                name: undefined,
                success: undefined,
                type: undefined,
            },

            /** account 
             * 
             * All user account specific values that are received during auth
             * success and used within app. Storing in Class to prevent 
             * un-needed  API calls.
             * 
             * @param       {OBJECT}    account                 - User account object.
             * @property    {STRING}    account.id              - UID for the user from the server.
             * @property    {STRING}    account.type            - The user's account type.
             * @property    {STRING}    account.name            - The user's account name.
             * @property    {DateTime}  account.currentLogin    - Time user logged in within current session.
             * @property    {DateTime}  account.lastLogin       - The user's last login time.
             * @property    {DateTime}  account.lastLogout      - The user's last logout time.
             * @property    {DateTime}  account.created         - The date the user account was created.
             * @property    {DateTime}  account.updated         - The date the user account was last updated.
             * 
            */
            account: {
                name: undefined,
                type: undefined,
                currentLogin: undefined,
                lastLogin: undefined,
                lastLogout: undefined,
                created: undefined,
                updated: undefined
            },

            //-- meta 
            /** Unique meta-data used to validate and manage user access to secure information.
             * 
             * 
             * @param       {OBJECT} meta                    - Values used to verify and validate user information.
             * @property    {STRING} meta.currentLoginIp     - The user's current login IP.  
             * @property    {STRING} meta.lastLoginIP        - The IP used at last time of login 
             * @property    {STRING} meta.lastLogoutIP       - The IP used at lsat time of logout
             * @property    {STRING} meta.lastLoginDevice    - The device used currently
             * @property    {STRING} meta.lastLoginDevice    - The device used at last time of login
             * @property    {STRING} meta.lastLogoutDevice   - The device used at last time of logout
             * @property    {STRING} meta.lastLoginOS        - The OS used at last time of login
             * @property    {STRING} meta.lastLogoutOS       - The OS used at last time of logout
             * @property    {STRING} meta.lastLoginBrowser   - The browser used at last time of login
             * @property    {STRING} meta.lastLogoutBrowser  - The browser used at last time of logout
             * @property    {STRING} meta.lastLoginLocation  - The location used at last time of login
             * @property    {STRING} meta.lastLogoutLocation - The location used at last time of logout
            */
            meta: {
                //TODO 08132022 #EP || Remove and simplify once I verify how I can do this
                currentLoginIp: undefined,
                lastLoginIP: undefined,
                lastLogoutIP: undefined,
                currentLoginDevice: undefined,
                lastLoginDevice: undefined,
                lastLogoutDevice: undefined,
                lastLoginOS: undefined,
                lastLogoutOS: undefined,
                lastLoginBrowser: undefined,
                lastLogoutBrowser: undefined,
                lastLoginLocation: undefined,
                lastLogoutLocation: undefined
            }
        };
        //-- END OF USER CONSTRUCTOR
    };

    getSessionState = async function () {
        //-- Pull state from LocalStorage, which is updated within the SessionState Feature

        // return useAuthService.getUser();
        return 'TODO: Add this';
    }

    getAuth = async function() {
        return this?.auth;
    }

    setAuth = async function(authState) {
        /* Provide handler, (what's using the feature data), and get all related features. 
        * 
        * @param {OBJECT}   auth    - JWT Auth Token decoded
        * @return {OBJECT}  results - Success or Failure bool with msg
        */

        // const results = {
        //     message : null,
        //     success : null,
        //     error   : null
        // }

        try {


            
            if (authState) {
                this.user.auth = [...authState];

                // console.log("User.setAuth.... setting local storage to...   ", auth())
                // useAuthService.setLocalStorage(auth());

                return {
                    message: "Successful auth set.",
                    success: true
                }
            }
            else {
                return {
                    message: "Failed to set auth.",
                    success: false,
                    error: 'ERROR: No auth provided'
                }
            }
        }
        catch (err) {
            return {
                message: "ERROR: Auth Failure.",
                success: false,
                error: err
            }
        };
        // END OF TRY CATCH
    }

    getJwt = async function(){
        return this.user.auth.id_token;
        // console.log("User.getJwt(): - auth: ", this.user.auth.id_token)
        // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InN0YXR1cyI6ImVuYWJsZWQiLCJkYXRlX2NyZWF0ZWQiOiJXZWQgRGVjIDA3IDIwMjIgMTM6NDk6MjYgR01ULTA1MDAgKEVhc3Rlcm4gU3RhbmRhcmQgVGltZSkiLCJkYXRlX21vZGlmaWVkIjoiV2VkIERlYyAwNyAyMDIyIDEzOjQ5OjI2IEdNVC0wNTAwIChFYXN0ZXJuIFN0YW5kYXJkIFRpbWUpIiwiYWxlcnRfY291bnQiOjI4LCJBbGVydCI6W10sIl9pZCI6IjYzODY0MWVjNGU0YjQ3NzNmYjYwZWQwMSIsIm5hbWVfZmlyc3QiOiJFcmlrIiwibmFtZV9sYXN0IjoiUGxhY2h0YSIsInVzZXJuYW1lIjoiRXJpayIsImVtYWlsIjoiZXJpa0BiYWlyLmFwcCIsInBob25lIjoiOTg5LTM1MC05OTk5IiwidHlwZSI6ImFkbWluIiwiY29uZmlndXJhdGlvbiI6eyJsYXlvdXQiOnsidGhlbWUiOiJkZWZhdWx0In19LCJwYXNzd29yZCI6IiQyYiQxMCRWai5aaFIzMmxDTVpqaUwzR3AxYTRPb2UueDVZSE5ITi9YbWVXdFZjbnJ5bUVpdm1ESnhCRyIsImRhdGVfbG9naW4iOm51bGwsIkJ1c2luZXNzIjpbeyJkYXRlX2NyZWF0ZWQiOiJXZWQgRGVjIDA3IDIwMjIgMTM6NDk6MjYgR01ULTA1MDAgKEVhc3Rlcm4gU3RhbmRhcmQgVGltZSkiLCJkYXRlX21vZGlmaWVkIjoiV2VkIERlYyAwNyAyMDIyIDEzOjQ5OjI2IEdNVC0wNTAwIChFYXN0ZXJuIFN0YW5kYXJkIFRpbWUpIiwiQWNjb3VudHMiOltdLCJVc2VycyI6W10sIkVtcGxveWVlcyI6W10sIkFkbWlucyI6W10sIl9pZCI6IjYzODY0MWVjNGU0YjQ3NzNmYjYwZWQwOCIsIm5hbWUiOiJEZXZlbG9wbWVudCIsInR5cGUiOiJhZG1pbiIsInN0YXR1cyI6ImFjdGl2ZSIsIlVzZXIiOltdfV0sIkFjY291bnQiOlt7Il9pZCI6IjYzODY0MWVjNGU0YjQ3NzNmYjYwZWQwYiIsIm5hbWUiOiJCYWlyLCBMTEMiLCJ0eXBlIjoiYWRtaW4iLCJzdGF0dXMiOiJhY3RpdmUiLCJCdXNpbmVzcyI6WyI2Mzg2NDFlYzRlNGI0NzczZmI2MGVkMDgiXSwiVXNlciI6WyI2Mzg2NDFlYzRlNGI0NzczZmI2MGVkMDEiXSwiZGF0YSI6W10sImRhdGVfY3JlYXRlZCI6IjE2NzA0MzkxNDIwODEiLCJkYXRlX21vZGlmaWVkIjoiMTY3MDQzOTE0MjA4MSJ9XSwiVG9rZW4iOlsiNjM4NjQxZWM0ZTRiNDc3M2ZiNjBlZDA1Il0sInVwZGF0ZWRBdCI6IjIwMjItMTEtMjlUMTc6MzE6MjQuOTM4WiIsInBhc3N3b3JkX2hhc2giOiIyc0NRVmY1UnQ0RE9VWTFjYlVqMyJ9LCJpYXQiOjE2NzA0MzkxNDIsImV4cCI6MTY3MDQ0NjM0Mn0.6bhcP7Ohy2DD7WGTNhQ5Y5pvVU6bUocgNZiwd2Rx6q0"
    }

    getUser() {
        // TODO:: 082522 #EP || Add GQL logic here?

        return this.user;
    };

    setAlertCount() {
        this.user.alert_count = (this.user?.alerts.alerts).length;
    };

    getAlertCount() {
        //TODO:: 082522 #EP || Add GQL logic here?
        
        if((this.user?.alerts?.alerts).length > 0) return (this.user?.alerts?.alerts).length;
        return this.user?.alerts?.alert_count
    };

    // handleLogin = async function(props) {

    //     const [login, { data, loading, error }] = useMutation(USER_LOGIN);

    //     try {
    //         const { data, loading, error } = await login({
    //             variables: { ...props },
    //         });

    //         // if(error)   return error
    //         // if(data){
    //         //     //-- LOGIN SUCCESS, UPDATE JWT WITH AUTH AND RE-ROUTE
    //         //     // console.log("data: ", data)
    //         //     handleAuthService.login(data.login);
    //         // }
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }


    //     //TODO:: 082522 #EP || Build this out to work. 
    //     return data, loading, error
    // }

    setAlertIsRead = async function(alertId) {
        if(!alertId) return false;
        
        // console.log("TEST: ", this.user.alerts.alerts)
        
        const alertsUpdated = this.user?.alerts?.alerts.map(alert => {
            if(alert.id === alertId) {
                alert.is_read = 1;
                this.User.alerts.alert_count = this.User.alerts.alert_count - 1;
                this.User.alert_count = this.User.alert_count - 1;
                // console.log("marking as read")
            }
            return alert;
        })
        this.user.alerts.alerts = [...alertsUpdated];
        this.user.alerts.alerts_count = alertsUpdated.length;
        this.user.alerts_count = alertsUpdated.length;
        this.user.Alert = [[...alertsUpdated]];

        // console.log("//-- setAlertIsRead: user: ", this.user)
        return true;
    }
    
    getAlerts() {
        return this.user.alerts
    }

    setAlerts = async function(alerts) {
        
        if(!alerts) return null;

        this.user.alerts.alert_count = alerts.length;
        this.user.alerts.alerts = [alerts]
        // console.log(alerts)
        
    }

    setUser = async function(userState) {
        /* Provide a userState object to define which features are available to the userState.
         * 
         * @param {OBJECT}   userState    - userState object with all properties.
         * @return {OBJECT}  results - Success or Failure bool with msg
         */

        // const results = {
        //     message : null,
        //     success : null,
        //     error   : null
        // }

        // console.log('userState: ', userState)

        try {
            if (userState) {

                this.setAuth(userState);
                //-- Spreading the userState object into the userState Class Object.
                this.user = { 
                    ...this.user,
                    ...userState
                 };
                
                return {
                    message: 'SUCCESS: User set.',
                    success: true,
                    error: null
                }
            }
            else {
                return {
                    message: 'Failed to setUser.',
                    success: null,
                    error: 'ERROR: null user provided.'
                }
            }
        }
        catch (err) {
            return {
                message: 'ERROR: Failed to setUser.',
                success: false,
                error: err
            }
        }
        //-- END OF setUser
    };
}


export default new User();
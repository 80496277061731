import React from 'react';

export default function BannerContent(title=String(), message=String()) {
    /** Manages building content within banner */
    return <>
      {title    
        ? <span className='banner__title'>{title}</span> 
        : ''
      },
      {message  
        ? <span className='banner__message'> {message}.</span> 
        : ''
      }
    </>
  }
  
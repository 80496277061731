import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../App';
import './assets/css/style.css'; //-- Banner specific styles
import BannerContent from './components/BannerContent';

//TODO: 12022022 #EP || Add notes on how it's hiding

export default function Banner({
  elementId=String(), //-- provide this ID so can manage adding and removing itself from page
  title = String(),
  message=String(),
  style=String(),
  delayShow= Number(),
  delayHide= Number(),
  setFailedLogin= Function(), //-- used to toggle to false and remove element after done showing
  actionStatus = Boolean()
}) {
  /** Define Title, Message, and Type of Banner to display.
   * 
   */

  // const {
  //   title,
  //   message,  //-- Message to display
  //   style     //-- The type of banner
  // } = props;

  const handleRemoveBannerElement = async (elementId) => {
    try{
      document.getElementById(elementId).remove();
      return true
    }
    catch{
      return false
    }
    // console.log("removed banner element: id: ", elementId);
  }

  const [_delayShow, set_DelayShow] = useState(() => {
    /** How long banner remains on screen in MS. 
     * 
     * Default is 5000 ms
     */
    
    //-- If invalid set to default
    if(  delayShow === undefined
      || delayShow === null
      || delayShow === 0
    ) return process.env.REACT_APP_BANNER_DELAY_SHOW;
    
    //-- Otherwise return the delayShow passed in
    return delayShow;
    
  }, [delayShow]);
  
  
  const [_delayHide, set_DelayHide] = useState(() => {
    /** How long banner waits before showing in ms.
     * 
     * Default is 100 ms.
     */
    
    //-- If invalid set to default
    if(  delayHide === undefined
      || delayHide === null
      || delayHide === 0
    ) return process.env.REACT_APP_BANNER_DELAY_HIDE;
    
    //-- Otherwise return the delayHide passed in
    return delayHide;
    
  }, [delayHide]);

  const {
    uuidv4,
  } = useContext(GlobalContext);

  const [animate, setAnimate] = useState(true);
  const [hidden, setHidden] = useState(false);

  const show = () => {
    //-- Grab container
    const banner = document.getElementById('banner-parent');
    //-- if element still exists
    if (banner) {
      //-- Fade in
      banner.style.opacity = 1;
      //-- move off screen
      banner.style.transform = 'translateY(55px)';
    }

  }

  const hide = () => {
    /* 
      Runs when pressing X on banner OR after X seconds.
        NOTE: Delay in fade and move off screen is controlled in CSS
    */
    setHidden(true);

    //-- Grab container
    const banner = document.getElementById('banner-parent');
    //-- if element still existed
    if (banner) {
      //-- Fade out
      banner.style.opacity = 0;
      //-- move off screen
      banner.style.transform = 'translateY(-50px)';
    }
  };

  const handleBannerAnimation = async (_delayShow, _delayHide) => {
    //-- on first run, start animation to show banner
    if (animate) {

      //-- Use setTimeout to delay showing banner
      setTimeout(function () {
        //-- Show the banner
        show()
      },
        // 100
        _delayShow
      );

      //-- Use setTimeout to delay hiding banner
      setTimeout(function () {

        //-- If not already hidden ( pressed X ) hide it
        if (hidden === false) {
          hide()
        }
      }, _delayHide );

      //----------------------------
      //-- Stops the animation so this useEffect only runs once
      setAnimate(false);
      
      
      //----------------------------
      //-- Removes the banner element from the page.
      setTimeout(() => {
        // setFailedLogin(false);
        // console.log("setFailedLogin(false)")
        const removed = handleRemoveBannerElement(elementId)
        .then((isRemoved) => {
          // console.log("Banner/index.js: isRemoved: ", isRemoved);
        })
        // console.log("//-- Banner/index.js: actionStatus: ", actionStatus);
        // console.log("//-- Banner/index.js: removed: ", removed);
      },_delayHide); 
        // _delayHide * 1.1);
        
    }
  }

  useEffect(() => {
    handleBannerAnimation(_delayShow, _delayHide);
    // console.log("Banner: mounting");
    return () => {
      // console.log("Banner: unmounting");
    }
  }, [actionStatus, _delayHide, _delayShow]);

  return (
    <section className='banner-parent' id='banner-parent'>

      {/* The Message  */}
      {(() => {
        switch (style) {
          case 'info':
            return (
              <span className='banner-message banner__info fw-500'>
                {BannerContent(title, message)}
              </span> 
            );
          case 'success':
            return (
              <span className='banner-message banner__success fw-500'>
                {BannerContent(title, message)}
              </span> 
            );
          case 'error':
            return (
              <span className='banner-message banner__error fw-500'>
                {BannerContent(title, message)}
              </span> 
            );
          default:
            return (
              <span className='banner-message banner__default fw-500'>
                {BannerContent(title, message)}
              </span> 
            );
        }
      })()}

      {/* The Close Button */}
      <span
        className='banner-x'
        onClick={hide}
      >
        x
      </span>
    </section>
  )
}

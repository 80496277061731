import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../App';
// import Alerts from './Components/Alerts';
import Alert from './Components/Alert';
import { useQuery } from '@apollo/client';
import { ALERTS_GET } from '../../services/gql/Query';

import List from '../../components/List'




export default function AlertFeature() {
    /** Feature includes Notifications of both normal and alert.
     * 
     * //TODO: 20221108 #EP || Add notification QTY count to header
     *                          - This will require adding a query to notifications for user on login
     * 
     */

    const {
        uuidv4,
        Loading,
        alerts,
        setAlerts,
        User,
        alertCount,
        setAlertCount
    } = useContext(GlobalContext);
      

    // const [pageLoading, setPageLoading]     = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [alertHolder, setAlertHolder] = useState([User?.getAlerts()]);

    const {  
        data, loading, error, stopPolling, startPolling
    } = useQuery(ALERTS_GET, 
        {  variables: {
            'id_token': User?.user?.id_token
            // 'id_token': User.getJwt()
            // 'id_token': authServices?.getTokenString()
            },
        }
    );
    
    //--------------------------------------------------------------------------
    const handleMarkAsRead = async (e, alertId) => {
        // e.preventDefault();
        // console.log("Alert.handleMarkAsRead(): alertId: ", alertId)
        
        // const parentElement = e.target.parentElement.parentElement;
        // parentElement.classList.add('hidden');
        
        User.setAlertCount(alertCount - 1)
        setAlertCount(alertCount - 1)

        setAlertHolder(currentState => {
            return currentState.filter((alert) => {
                if(alert._id === alertId) alert.isRead = 1;
                return alert
            })
        })
        User.setAlerts([alertHolder])
    };

    
    //--------------------------------------------------------------------------
    //-- Use Effect 
 
    useEffect(() => {
        startPolling(process.env.REACT_APP_POLLING_INTERVAL_MS)

        return () => {
            stopPolling()
        }
    },[User, alertCount])


    
    //--------------------------------------------------------------------------
    //-- Conditional Returns 

    // if(pageLoading) return <Loading />
    if(loading)     return <Loading />
    if(error)       return <p>Error: {error.message}</p>
    // if(!loading && !error && data && isInitialLoad){
    if(!loading && !error && data && isInitialLoad){
        
        User.setAlerts([...data?.getAlerts])
        // console.log("alertHolder: original ", alertHolder)
        // console.log("data?.getAlerts: ", [data?.getAlerts])
        setAlertHolder([...data?.getAlerts])
        
        setAlerts(data?.getAlerts)
        setIsInitialLoad(false)
        // console.log("updating..")
    }

    
    //--------------------------------------------------------------------------
    //-- Primary Return

    return (
        <div className='page__div'>
            <section className='page__section cards'> 
                
                {/* PAGE TITLE */}
                <section className='page__section__header'>
                    <h2 className='page__section__title'>Alerts</h2>
                </section>


                {/* CONTROL MENU */}
                <section className='page__list__controls'>
                    
                   
                    {/* <List /> */}
                    
                    {/* Search Filter */}
                    {/* //TODO:: 20221022 #EP || Add search filter option here */}
                    <input  type='text'
                            placeholder='Search'
                            style={{
                                //TODO:: 20221022 #EP || Remove inline styling once polished
                                marginLeft: 'auto',
                                float: 'right',
                                // position: 'relative',
                                right: '1.25rem',
                                width: '30%'
                            }}
                    />
                    <button> Reset </button>
                </section>

                { alertHolder
                    ? alertHolder.map((alert, index) => {
                        return <Alert
                            key={uuidv4()}
                            alert={alert}
                            // number={ alertHolder.length - index }
                            handleMarkAsRead={handleMarkAsRead}
                        />
                    })
                    : "null"
                }
            </section>
        </div>
    )
}


import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GlobalContext } from '../../App';
import handleSetLocalStorage from '../../hooks/handleSetLocalStorage';

import { USER_SESSIONSTATE_GET, ALERTS_GET } from '../../services/gql/Query';

export default function SessionState({ User }) {
    const {
        authServices,
        setAlertCount,
        // alertCount,
        // alerts,
        // Sensors
        
    } = useContext(GlobalContext);


    // const { loading, error, data } =  useQuery(USER_SESSIONSTATE_GET);
    const {
        loading, error, data
    } = useQuery(USER_SESSIONSTATE_GET,
        //-- Sending in JWT Token as a String
        {  variables: {
                'id_token': User?.user?.id_token
            },           
            startPolling: process.env.REACT_APP_MIN_LOAD_TIME_MS
        },
    );    

    const handleUpdatingSessionState = async (data) => {
        // console.log("data?.GetUserSessionState: ", data?.GetUserSessionState)
        
        // handleSetLocalStorage(data?.GetUserSessionState)
        // User.setUser( [...data?.getUserSessionState] )
            
    }
    useEffect(() => {
        
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // console.log("SessionState.useEffect(): Polling Started: ", process.env.REACT_APP_POLLING_INTERVAL_MS / 1000, "seconds");
            // console.log("SessionState.useEffect(): data: ", data, data?.GetUserSessionState)
            // console.log("SessionState.useEffect(): authServices?.getToken()", authServices?.getToken())
            // console.log("SessionState.useEffect(): authServices?.getTokenString()", authServices?.getTokenString())
            // console.log("SessionState.useEffect(): User.getJwt())", User.getJwt())
        }

        if(data && !loading){
            // User.setUser( [data?.GetUserSessionState] )
            // const updateLocalStorage = 
                // handleSetLocalStorage(data?.GetUserSessionState)
                // .then((result) => {
                //     User.setUser( data?.GetUserSessionState )
                // })
        }
        console.log("//-- SessionState.useEffect()")
        // console.log("data?.GetUserSessionState?.alerts: ", data?.GetUserSessionState)
        User.setAlerts(data?.GetUserSessionState?.Alert)
        // console.log("User.getAlerts()", User.getAlerts().alerts[0])
        setAlertCount(User.getAlerts().alert_count) //-- alerts to last database query
        // setAlertCount(data?.GetUserSessionState?.alert_count) //-- alerts to last database query
    }, [data, loading, error, User])

    
    // if (loading) {}
    // if (error) {}
    // if (data?.GetUserSessionState) handleUpdatingSessionState(data)
};
import React from 'react'

export default function Logs() {
  return (
    <div className='page__container'>
      <section className='page__header'>
          <h2>
              Logs
          </h2>
      </section>
  </div>
  )
}

import React from 'react';

export default function NotFound() {
  return (
    <section>
        <h2>404 - Page Not Found</h2>
        <div>
            The Page you are looking for does not exist. Please try again.
            PLACEHOLDER.
        </div>
    </section>
  )
}

import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../App";

const Pagination = (props) => {

  const {
    number,
    // currentData,
    setNumber,
    // tableData,
    filteredTableData,
    resultsPerPage 
  } = props;
  
  const {
    uuidv4
  } = useContext(GlobalContext);
  
  const pageNumber = [];
  for (let i = 1; i <= Math.ceil(filteredTableData.length / resultsPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };

  const Prev = () => {
    if (number !== 1) {
      //to restrict going lower than page 1
      setNumber(number - 1);
    } else {
      setNumber(number);
    }
  };

  const Next = () => {
    if ( number < filteredTableData.length / resultsPerPage ) {
      //to restrict going above page 3 as it the last page of the app
      setNumber(number + 1);
    }
  };


  useEffect(() => {
    // console.log("pagination")
    // console.log(filteredTableData)
  }, [number, filteredTableData, number]);

  

  return (
      <>
        <div  className="flex-row-center m-a p-300"
            key={`pagination-${uuidv4}`}
        >
          { number !== 1
            ? <button
                className="p-200"
                onClick={Prev}
                key={`back-${uuidv4}`}
              >
                Back
              </button>
          : <button
              className="p-200"
              onClick={Prev}
              key={`back-${uuidv4}`}
              disabled
            >
              Back
            </button>
          }
          
          {pageNumber.map((Elem) => {
              
              if(Elem === number) {
                return (
                <button className="p-300 active br-default"
                          key={`number-${uuidv4}-${Elem}`}
                          onClick={() => ChangePage(Elem)}
                  >
                    {Elem}
                  </button>
                )
              }
              else {
                return (
                  <button className="p-300 br-default"
                          key={`number-${uuidv4}-${Elem}`}
                          onClick={() => ChangePage(Elem)}
                    >
                      {Elem}
                  </button>
                )
              }
              
            
          })}
          { number < filteredTableData.length / resultsPerPage  
            ? <button
                className=""
                onClick={Next}
                key={`next-${uuidv4}`}
              >
                Next
              </button>
            : <button
                className=""
                onClick={Next}
                key={`next-${uuidv4}`}
                disabled
              >
                Next
              </button>
          }
        </div>
      </>
  );
};

export default Pagination;
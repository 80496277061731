import React from 'react';
//- A Modal header

export default function header( props ) {
    const {
    title,
    subTitle,
    } = props;

    return (
      <section className='modal__header'>
        {/* Title of the modal */}{/*TODO:: 081122022 #EP || Add utility to force max length */}
        <h4 className=''> {title  ? title : null } </h4>
         
        {/* Tagline of the modal. should be few  */}{/*TODO:: 08112022 #EP || Add utility to force max length */}
        <span className='fs-300 clr-light-500 ls-700'>{ subTitle ? subTitle : null } </span>
    </section>
  )
}

/** Templates of standard  Sensor Page rendering Config for simplify building page.
 * 
 * 
 */

//  import { getTimeZone } from '../../../utils/DateTime';
const { getTimeZone } = require('../../../utils/DateTime');

const sensorContext = {
    //-- General params for a Unit
    customer            : String(),
    room                : String(),
    distributor         : String(),
    unit_brand          : String(),
    unit_type           : String(),
    unit_model          : String(),
    unit_serial         : String(),
    definedActions      : String(),
    modifyComponents    : String(),
    //-- Pages within Modal, their Components, and their configuration(s).
    pages : {
        //-- Details about the Sensor Unit
        'Dashboard' : {
            enabled : true,
            active  : true,
            title   : 'Dashboard',
            showTitle: false,
            subTitle : String(),
            components : {          
                chart     : {},
                // form      : {},
                // TODO:: 09272022 #EP || Evaluate Placeholder Section and determine goals here. 
                section   : {
                    title           : 'Dashboard',
                    subTitle        : String(),
                    //-- Hardware Service Details
                    "Last Serviced"     : String(),
                    //-- Sensor Details
                    "Status"          : String(),
                    "Last Sync"        : String(),
                    "Last PSI"         : String(),
                    "Last Temperature" : String(),
                    "Last Humidity"    : String(),
                    "Last Type"        : String(),
                    //-- Logs
                    "Total Logs"      : String(),
                },
            }
        },
        //-- Details about the related Business
        'Business' : {
            enabled : true,         //-- Page enabled/available to user
            active  : false,        //-- Default page to load on render
            title   : 'Business',  //-- The name/title of this page
            showTitle: false,       //-- Hiding the title, not needed
            subTitle : String(),   //-- No subtitle needed
            components : {          
                // chart     : {},
                // form      : {},
                // TODO:: 09272022 #EP || Evaluate Placeholder Section and determine goals here. 
                section   : {
                    title           : 'Business',
                    subTitle        : String(), 
                    name            : String(), 
                    type            : String(), 
                    status          : String(), 
                    config          : String(), 
                    date_modified   : String(), 
                    date_created    : String(), 
                    // Users           : Object(), //-- Active Users
                    // Employees       : Object(), //-- Active Employees
                    // Admins          : Object(), //-- Admins on account
                },
            }
        },
        //-- Details about the related equipment
        'Equipment' : {
            enabled : true,         //-- Page enabled/available to user
            active  : false,        //-- Default page to load on render
            title   : 'Equipment',  //-- The name/title of this page
            showTitle: false,       //-- Hiding the title, not needed
            subTitle : String(),   //-- No subtitle needed
            components : {          
                // chart     : {},
                // form      : {},
                // TODO:: 09272022 #EP || Evaluate Placeholder Section and determine goals here. 
                section   : {
                    title           : 'Equipment',
                    subTitle        : String(),
                    name            : String(),
                    type            : String(),
                    brand           : String(),
                    model           : String(),
                    serial          : String(),
                    date_created    : String(),
                    date_mfg        : String(),
                    date_modified   : String(),
                    date_installed  : String(),
                    date_serviced   : String()
                },
            }
        },
        'Sensor' : {
            enabled : true,
            active  : false,
            title   : 'Sensor',
            showTitle: false,
            subTitle : String(),
            components: {
                section   : {
                    title           : 'Sensor',
                    subTitle        : String(),
                    name            : String(), 
                    label           : String(),
                    make            : String(),
                    model           : String(),
                    serial          : String(),
                    room            : String(),
                    floor           : String(),
                    description     : String(),
                    config          : String(),
                    status          : String(),
                    date_created    : String(),
                    date_installed  : String(),
                    date_mfg        : String(),
                    date_modified   : String(),
                    date_serviced   : String()
                },
            },
        },
        //-- Details about the Sensor Unit
        'Sensor Logs' : {
            /** @enabled:    To show page on default load or not.
             * 
             *                  This is used to enable the ability to load the a modal but NOT
             *                      render it until user requests modal. 
            */
            enabled : true,
            //-- @active: the default tab/page to load within the modal
            active  : false,
            //-- The name/title of the page that will load
            title   : 'Sensor Logs',
            //-- If should show the title on the loaded page or not
            showTitle: false,
            //-- SubTitle Value if there is a sub-title for page
            subTitle : String(),
            
            /** Components within a Modal that a Sensor page will be using.
             * 
             */
            components : {
                //-- Include a Table Component within the Sensor page.
                table : {
                    enabled             : true,     //-- by default view it.
                    name                : 'Sensor Logs',
                    
                    //-- @header:   Update this to define the headers within the table to be rendered
                    header              : [
                        // {
                        //     field: 'id',
                        //     value: 'ID'
                        // },
                        {
                            field: 'rowNum',
                            value: 'Log'
                        },
                        // {
                        //     field: 'datetime',
                        //     value: 'Date Time'
                        // },
                        {
                            field: 'date',
                            value: 'Date'
                        },
                        {
                            field: 'time',
                            value: 'Time (' + getTimeZone() + ')'
                        },
                        {
                            field: 'temp',
                            value: 'Temp'
                        },
                        {
                            field: 'humidity',
                            value: 'Humidity'
                        },
                        {
                            field: 'psi',
                            value: 'PSI'
                        }
                    ],
                    //-- @data:     Update this to define data within table
                    data                : Object(),
                    //-- @results:  Number of results to show per page
                    results             : 50,
                    //-- @defaultSearchFilter:  The header parameter the search filters by.
                    defaultSearchFilter :'date',
                    //-- @toggleShowSearch:     Show the Search Box or Not
                    toggleShowSearch    : false,
                    //-- @toggleShowActions:    Show the actions column within the table or not.
                    toggleShowActions   : false,
                    //-- @definedActions:   Provide unique actions the table can execute
                    definedActions      : [],
                },
            }
        },
    },
    //-- Modal specific configurations and options adjusted for sensor modal.
    modal : {
        back_arrow: {
            //-- Show back-arrow in modal
            enabled: true,
        },
        //-- hiding the Close button. Users to navigate back to previous page.
        close_x: {
            enabled: false  //-- The Close button on top right corner
            //TODO:: Add more?
        },
    },
    //-- The Title of the Modal
    header : {
        title       : String(),
        subTitle    : String(),
    },
    footer : {
        //-- If online or not.
        status      : String()
    }
}

module.exports = {
    sensorContext
}
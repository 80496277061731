import React, { useContext } from 'react';

export default function DistributorsPage() {
  return (
    <div className='page__container'>
        <div className='page__div'>
          <section className='page__section'>
            <div className='page__section__header' >
                <h3 className='page__section__title'>
                    Resellers
                </h3>
                <p className='page__section__summary' >
                  This is a placeholder container for Resellers to manage their
                  Distributors.
                </p>
            </div>
          </section>
        </div>
    </div>
  )
}

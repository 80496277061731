import React, { useContext } from 'react'
import { GlobalContext } from '../../App'
import './assets/css/style.css'

export default function Details({ content = String(), theme=String()}) {
    const {
        Icon
    } = useContext(GlobalContext)

    const params = {
        theme       : theme     ? theme     : 'detail_information__default',
        content     : content   ? content   : 'No content provided',
    };

    return (
        <span className={`detail_information__wrapper ${params.theme}`}>
            
            <i className='detail_information__icon'>
                <Icon
                    name='infoBordered'
                    background_c='transparent'
                    svg_c='var(--clr-accent)'
                    width='25px'
                    height='25px'
                />
            </i>
            
            {/* On  Hover, get sync interval to understand why offline/online*/}
            <span className='detail_information__content'>
                {content}
            </span>
        </span>
    )
}

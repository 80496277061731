//-- React Specific
import React from 'react';
import reportWebVitals from './utils/reportWebVitals';
import { createRoot } from 'react-dom/client';
import App from './App'; //-- App default page
import { v4 as uuidv4 } from 'uuid'; //-- Being used to generate unique keys for react
//-- Base Import
import './assets/css/style.css';  //-- Universal Styling
import './pages/assets/css/style.css'; //-- Page Specific styling


// if in Development
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  console.log('//-- client/src/index.js - running in development')
}


const container = document.getElementById('root');
const root = createRoot(container); // 
root.render(
  <React.StrictMode key={uuidv4()}>
    <App key={uuidv4()} uuidv4={uuidv4} />
  </React.StrictMode>,
);

// if in Development
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  console.log('//-- client/src/index.js - running in development')
  reportWebVitals(); // Having web-vitals print for awareness.  ( SRC -> https://bit.ly/CRA-vitals )
}; 

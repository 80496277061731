import React, { useContext,  useEffect, useState } from 'react';
import {
    useNavigationType,
    useNavigate,
    useLocation,
    useParams,
    useOutletContext,
    redirect
} from 'react-router-dom';

import { getDateTimeFormatted } from '../../utils/DateTime';

import { GlobalContext } from '../../App';

//-- Base template content that can be modified dynamically by the sensor.
import { sensorContext } from './context/sensorContext'

//-- for polling management
import { useApolloClient } from '@apollo/client';
//-- GQL
import { useQuery } from '@apollo/client';
import { UNIT_GET_DETAILS } from '../../services/gql/Query'
//TODO:: 101622 #EP || Add ability to get JUST log updates from the server and have it re-check every so often along with manually.
//TODO:: 101622 #EP || Add ability to re-sync unit details every so often along with manually.

//------------------------------------------------------------------------------
//-- Export Function
export default function Sensor() {
    /** Renders the Sensor Component
     * 
     */

    //-- React Router Hooks
    const params = useParams();
    const context = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();

    const {
        uuidv4,
        Loading,
        sensors,
        setSensors,
        Modal
    } = useContext(GlobalContext);

    const [isLoading, setIsLoading] = useState(true);

    //-- to know if should reset the cache or not
    const [reset, setReset] = useState(0);
    const client = useApolloClient();
    
    //-- Updating with Template default values
    const [parameters, /*setParameters*/] = useState({ ...sensorContext})

    //-- QGL Queries
    const { loading, error, data, startPolling, stopPolling } = useQuery(UNIT_GET_DETAILS,
        { variables: { id: params.id } }
    );

    const [logData, setLogData] = useState([]);

    //-- Container that holds the sensorContextComponent Data that's been modified
    const [modifyComponents, setModifyComponents] = useState(Object());


    const [pageTemplate, setPageTemplate] = useState({...sensorContext});

    //-- Modal Management
    const [showModal, setShowModal] = useState("yes");

    const handleShowModal = (e) => {
        setShowModal(true);
        try {
            document.getElementById('hide-modal').classList.toggle('hide-modal');
            

            const {
                pathname,
                search,
            } = location.state.history;

            // console.log("location.state: ", location.state);

            if (navigationType === 'PUSH') {
                navigate(pathname, { replace: false })
            }
            else {
                // navigate('/sensors', { replace: false })
                // navigate('/sensors', { replace: false })
                redirect('/sensors', { replace: true })
            }
        }
        catch (err) {
            console.log('Sensor.jsx - handleShowModal - err: ', err);
        }
        // const testing = document.getElementById('hide-modal');
        // console.log(testing,e)
    };
    
    const handleDashboardData = (data) => {

        const lastLog = data?.Logs[data?.Logs?.length-1];
        const lastLogPayload = lastLog?.payload ? JSON?.parse(lastLog?.payload) : {};
        const verifiedDashboardData = {
            // title           : 'Dashboard',
            // subTitle        : String(),
            
            //-- Hardware Service Details
            "Last Serviced"     : data?.Equipment?.date_serviced ? getDateTimeFormatted(data?.Equipment?.date_serviced) : 'N/A',
            
            //-- Sync Details
            "Status"            : data?.status,
            "Last Sync"         : getDateTimeFormatted(lastLog?.date_created),
            "Last Type"         : lastLog?.title,
            //-- Sensor Details
                                //-- if below zero, just show zero. If above zero, show the value.
            "Last PSI"          : lastLogPayload?.psi 
                                    ? lastLogPayload?.psi > 0 
                                        ? lastLogPayload?.psi 
                                        : 0 
                                    : 'N/A'
            ,
            "Last Humidity"     : lastLogPayload?.humidity
                                    ? lastLogPayload?.humidity.toFixed(2) + '℉'
                                    : null
            ,
            "Last Temperature"  : lastLogPayload?.temperature
                                    ? lastLogPayload?.temperature.toFixed(2) + '%'
                                    : null
            ,
              
            //-- Logs
            "Total Logs"        : data?.Logs
                                    ? data?.Logs?.length
                                    : 0
        };

        return verifiedDashboardData
    };

    const handleBusinessData = (data) => {
        const verifiedBusinessData = {
            // title           : 'Business',
            // subTitle        : String(), 
            name            : data?.name, 
            type            : data?.type, 
            status          : data?.status, 
            config          : data?.config, 
            date_modified   : getDateTimeFormatted(data?.date_modified),
            date_created    : getDateTimeFormatted(data?.date_created), 
            // Users           : data?.Users, //-- Active Users
            // Employees       : data?.Employees, //-- Active Employees
            // Admins          : data?.Admins, //-- Admins on account
        }

        return verifiedBusinessData;
    };

    const handleEquipmentData = (data) => {
        const verifiedEquipmentData = {
            // title           : 'Equipment',
            // subTitle        : String(),
            name            : data?.name,
            type            : data?.type,
            brand           : data?.brand,
            model           : data?.model,
            serial          : data?.serial,
            date_created    : data?.date_created    ? getDateTimeFormatted(data?.date_created)      : '-',
            date_mfg        : data?.date_mfg        ? getDateTimeFormatted(data?.date_mfg)          : '-',
            date_modified   : data?.date_modified   ? getDateTimeFormatted(data?.date_modified)     : '-',
            date_installed  : data?.date_installed  ? getDateTimeFormatted(data?.date_installed)    : '-',
            date_serviced   : data?.date_serviced   ? getDateTimeFormatted(data?.date_serviced)     : '-'
        };

        return verifiedEquipmentData;
    };

    const handleSensorData = (data) => {
        const verifiedSensorData = {
            // title           : 'Sensor',
            // subTitle        : String(),
            name            : data?.name, 
            label           : data?.label,
            make            : data?.make,
            model           : data?.model,
            serial          : data?.serial,
            room            : data?.room,
            floor           : data?.floor,
            description     : data?.description,
            config          : data?.config,
            status          : data?.status,
            date_created    : data?.date_created    ? getDateTimeFormatted(data?.date_created)      : '-',
            date_installed  : data?.date_installed  ? getDateTimeFormatted(data?.date_installed)    : '-',
            date_mfg        : data?.date_mfg        ? getDateTimeFormatted(data?.date_mfg)          : '-',
            date_modified   : data?.date_modified   ? getDateTimeFormatted(data?.date_modified)     : '-',
            date_serviced   : data?.date_serviced   ? getDateTimeFormatted(data?.date_serviced)     : '-'
        };

        return verifiedSensorData;
    };


    const handleLogData = (data) => {
        //-- Takes log data and extracts just the defined values

        

        //-- Check to see if sync summary indicates from from Arduino IoT Cloud or not to determine how to parse the data
        const isArduinoIoT_Data = (data?.[0]?.summary === "Arduino IoT Cloud Sync." ? true : false);
        
        //-- Extracts payload from data and then updates containers accordingly.
        const verifiedLogData = [];
        data.map((row, index) => {
            
            // console.log(row)
            //------------------------
            //-- Getting root values from row
            const rowNum        = index + 1;    //-- used for easy user reference
            const id            = row?._id;      //-- used as react key

            
            /** if IS from Arduino Cloud IoT, scrape payload
             * 
             *      - Extracts the payload according to how it's received from AIoTC
             */
            if(isArduinoIoT_Data){
                // console.log("getDateTimeFormatted(row.date_created): ", getDateTimeFormatted(row.date_created))
                //------------------------
                //-- splitting date-time
                const datetime  = row?.date_created ? getDateTimeFormatted(row?.date_created).split(', ') : '-';
                // console.log(row?.date_created)
                const date      = datetime ? datetime[0] : '-';
                const time      = datetime ? datetime[1] : '-';
                // console.log(date, time)
                
                //-- Payload Data (Each log entry has a payload which is Unit and Sensor data)
                const payload = JSON.parse(row?.payload);
                // console.log(payload)

                
                
                //-- Get title ( which is type of event )
                const typeOfLog     = payload?.title;
                
                //-- Get the Sensor Data
                const psi           = payload?.psi
                                        ? payload.psi < 0 
                                            ? 0
                                            : payload.psi 
                                        : null; 
                const temp          = payload?.temperature 
                                        ? payload.temperature.toFixed(2) + '℉'
                                        : null;
                const humidity      = payload?.humidity
                                        ? payload.humidity.toFixed(2) + '%'
                                        : null;
                // TODO:: 101522 #EP || Get average measurement of all sensors to send in from Arduino IoT Cloud
                
                const gsm_GEO       = payload?.gsm_GEO
                                        ? payload.gsm_GEO
                                        : null;
                //-- Add another row to the table with modified data
                verifiedLogData.push({ id, rowNum, datetime, date, time, temp, humidity, psi, gsm_GEO,  typeOfLog});
            }
            

            /** IF it's not FROM Arduino IoT Cloud
             *
             *    - likely from SIM, handle this way ( original 10/2022 )
             */
            if(!isArduinoIoT_Data){
                //------------------------
                //-- splitting date-time
                
                const datetime  = row.date_created ? getDateTimeFormatted(row.date_created).split(', ') : '-';
                const date      = datetime ? datetime[0] : '-';
                const time      = datetime ? datetime[1] : '-';

                //-- Payload Data (extracted from row.payload)
                const payload = JSON.parse(row?.payload);
                // const auth = row.payload.auth;
                const typeOfLog         = payload?.title;

                //------------------------
                //-- Extracting and parsing Sensor data now
                // const payload   = JSON.parse(row.payload);
                const payloadSensorData = JSON.parse(row.payload).payload

                // console.log(payloadSensorData)
                
                const compressor_A5 = payloadSensorData?.compressor_A5;
                const psi           = compressor_A5?.c_psi ? compressor_A5.c_psi : '-';
                // const psi_sum       = compressor_A5?.sum ? compressor_A5.sum : '-';
                // const psi_avg       = compressor_A5?.avg ? compressor_A5.avg : '-';

                const tempHumi_5    = payloadSensorData?.tempHumi_5;
                const temp          = tempHumi_5?.temp_5  ? tempHumi_5.temp_5 + ' ℉' : '-';
                const humidity      = tempHumi_5?.humid_5 ? tempHumi_5.humid_5 + ' %' : '-';
                
                const gsm_GEO       = payloadSensorData?.gsm_GEO;
                //-- Add another row to the table with modified data
                verifiedLogData.push({ id, rowNum, datetime, date, time, temp, humidity, psi, gsm_GEO,  typeOfLog});
            }
            

        });
        
        //-- Updating local state with verified log data
        setLogData(verifiedLogData);
        
        //-- return results back to handleUnitData
        return verifiedLogData;
    };



    //--------------------------------------------------------------------------
    //-- Main function managing ALL data deconstruction and updating of containers

    const handleUnitData = (data = Object()) => {
        // console.log("const handleUnitData = (data = Object()) => {: ", data);
        clearTimeout();
        setTimeout(() => {
            //-- if still loading, wait duration and then try again
            if(loading) handleUnitData()
            // console.log("timeout")
            // console.log(data)
            if( data ) {
                //-- Extracting and parsing results to build page
                const verifiedDashboardData  = handleDashboardData(data);
                //TODO:: 101622 #EP || Should I sync Account Data here too?
                // const verifiedAccountData  = handleAccountData(data?.Account[0])
                const verifiedBusinessData  = handleBusinessData(data?.Business[0])
                const verifiedEquipmentData = handleEquipmentData(data?.Equipment[0])
                const verifiedSensorData    = handleSensorData(data)
                const verifiedLogData       = handleLogData(data?.Logs)

                // console.log("verifiedEquipmentData: ", verifiedEquipmentData)

                //-- Updating local state with all verified data to build page
                setModifyComponents({
                    //-- Push in the default template
                    ...parameters,
                    //-- Push in modifications to default template
                    ...modifyComponents,
                    //-- Push in verified data updating the above with unique data
                    'pages': {
                        ...parameters.pages,
                        'Dashboard': {
                            ...parameters.pages['Dashboard'],
                            components: {
                                ...parameters.pages['Dashboard'].components,
                                section: {
                                    ...parameters.pages['Dashboard'].components.section,
                                    ...verifiedDashboardData
                                }
                            }
                            
                        },
                        'Business': {
                            ...parameters.pages['Business'],
                            components: {
                                ...parameters.pages['Business'].components,
                                section: {
                                    ...parameters.pages['Business'].components.section,
                                    ...verifiedBusinessData
                                }
                            }
                            
                        },
                        'Equipment': {
                            ...parameters.pages['Equipment'],
                            components: {
                                ...parameters.pages['Equipment'].components,
                                section: {
                                    ...parameters.pages['Equipment'].components.section,
                                    ...verifiedEquipmentData
                                },
                            }
                        },
                        'Sensor': {
                            ...parameters.pages['Sensor'],
                            components: {
                                ...parameters.pages['Sensor'].components,
                                section: {
                                    ...parameters.pages['Sensor'].components.section,
                                    ...verifiedSensorData
                                },
                            },

                        },
                        'Sensor Logs': {
                            ...parameters.pages['Sensor Logs'],
                            components: {
                                ...parameters.pages['Sensor Logs'].components,
                                table: {
                                    ...parameters.pages['Sensor Logs'].components.table,
                                    ////-- retain the original data and update with log data too
                                    ////- ...modifyComponents.table,
                                    //-- send in logs in reverse order (newest first)
                                    data: verifiedLogData.reverse()
                                }
                            }
                        }
                    },
                    'header': {
                        title : data?.getUnit_detail?.Business?.[0]?.name,
                        subTitle : 'test'
                    },
                    // 'footer' : {
                    //     //TODO:: 10032022 #EP || Replace with real status of unit
                    //     //IF post today in the last X hours, online, otherwise offline.
                    //     status : 'Online'
                    // }
                });

            }
            // if( data ) handleUnitData(data?.Unit)

            // console.log("hit timeout")
        }, process.env.REACT_APP_MIN_LOAD_TIME_MS);

        //-- IF there are logs, update the table with them
        
        
        //     console.log("handleUnitData - data: ", data);
        // }
    }

    // function handleIsLoading() {
    //     //TODO:: 10012022 #EP || Delete this function once it's replaced with handleUnitData
    //     if (isLoading) {
    //         setIsLoading("no");
    //         console.log("isLoading: ", isLoading);

    //         setTimeout(() => {
    //             if (isLoading === "yes") {
    //                 navigate('/sensors', { replace: false });
    //             }
    //             else {
    //                 console.log("isLoading: ", isLoading);
    //                 clearTimeout();
    //             }
    //         }, process.env.REACT_APP_MIN_LOAD_TIME_MS);
    //         clearTimeout();
    //     }
    //     // else {
    //     //     console.log("isLoading: ", isLoading);
    //     //     setIsLoading(true);
    //     // }
    // }



    //--------------------------------------------------------------------------
    //-- USE EFFECT

    // useEffect(() => {
    //     // console.log("sensorContext: ", sensorContext);
    //     handleIsLoading();
    //     // handleLogData(fakeTableData);
        
    //     // try {
    //     //     console.log("Sensor.jsx - useEffect - location: ", location);
    //     //     console.log("Sensor.jsx - useEffect - data: ", data);

    //     //     //-- If a location value exists, use the data within
    //     //     if (location['state']) {
    //     //         // console.log("Location has state")
    //     //         if (location.state['data']) {
    //     //             // console.log("location.state has data")
    //     //             setParameters({
    //     //                 ...sensorContext,
    //     //                 ...data,
    //     //                 ...location.state.data
    //     //             });
    //     //         }
    //     //     }
    //     // }
    //     //TODO:: 10012022 #EP || Replace with better error handling
    //     // catch (err) {
    //     //     console.log('Sensor.jsx - useEffect - err: ', err);
    //     // }

    //     //TODO:: 10/01/2022 #EP || Clean this up
    //     setIsLoading(false);
    //     // console.log("useEffect...sensorContext: ", sensorContext)
    //     // setPageTemplate(sensorContext);
    //     // console.log("isLoading: ", isLoading);
    // }, []);

    
    useEffect(() => {    
        
        //-- Once data is loaded, set the sensors state
        if ( !loading && !error && data ) {
            handleUnitData(data?.getUnit_detail);

        }
        startPolling(30000);
        // //-- if initial run, reset the cache ( needed for navigation back events )
        // if(reset === 0) startPolling(0); setReset(reset + 1);
        
        // //-- After initial run, set polling to default value
        // if( reset > 0 ) startPolling(process.env.REACT_APP_POLLING_INTERVAL_MS);
        
        // //-- useEffect Cleanup
        // return () =>  stopPolling();
        
    }, [ data, error, loading]);


    //---------------------------------------------------------------------------
    //-- RETURNS
    if (loading || !data) return <Loading />;
    if (error) return <>error.message</>; //TODO:: 10012022 #EP || Handle error properly


    // if ( data ) console.log(data.getUnitLogs);
    //TODO:: 10012022 #EP || Delete once verified not needed
    // if (data > 0) {
    //     console.log("Data is found")
    //     handleLogData(JSON?.parse(data?.getUnitLogs[0]?.logs))
    //     setPageTemplate(sensorContext);
    // }
    // else {
    //     // console.log("no data found: sensorContext: ", sensorContext)
    //     console.log("No data found");
    // }
    // if ( data ) ;


    return (
        // <div className='page__container' >
        <>
            {/* {console.log("params.id", params.id)}
        {console.log("data_UNIT_GET_LOGS: ", data)} */}

            {/* params.id: {id}
        <br />
        conext: {context}
        <br />
        location: {JSON.stringify(location)}
        <br />
        navigate: {JSON.stringify(navigate)}
        <br />
        navigationType: {JSON.stringify( navigationType )}
        <br />
        <button onClick={handleShowModal}>Show Modal</button> */}
            <span id='hide-modal'>
                {/* <Modal 
                handleShowModal={handleShowModal}
            /> */}
                {/*TODO:: 08162022 #EP || Add complete modal reqs */}
                {/* {console.log(data)} */}
                {/* {console.log("data: ", data)} */}
                <Modal
                    
                    //-- Values send in via Nav event
                    navigationProps={{
                        "nav_return"    : "Sensors",
                        "nav_params"    : params ? params : null,
                        "nav_context"   : context ? context : null,
                        "nav_location"  : location ? location : null,
                        "nav_type"      : navigationType ? navigationType : null,
                    }}

                    //-- Values that are defined by the query
                    //! TODO:: 10022022 #EP || Get this data from the query
                    title               ={data?.getUnit_detail?.Equipment?.[0]?.brand}
                    subTitle            ={data?.getUnit_detail?.Equipment?.[0]?.model}
                    subSubTitle         ={'SN: ' + data?.getUnit_detail?.Equipment?.[0]?.serial}
                    customer            ={data?.getUnit_detail?.Business?.[0]?.name}
                    identifier          ={data?.getUnit_detail?.Equipment?.[0]?.type}
                    distributor         ={data?.getUnit_detail?.distributor?.[0]?.name}
                    unit_brand          ={parameters?.unit?.brand ? data?.unit?.brand : ""}
                    unit_type           ={parameters?.unit?.type}
                    model               ={parameters?.unit?.model}
                    serial              ={parameters?.unit?.serial}
                    definedActions      ={[]}
                    modifyComponents    ={modifyComponents}
                    handleShowModal     ={handleShowModal}
                    
                    //-- See ./sensor/context/sensorContext.js
                    pages               ={parameters?.pages}
                    modal               ={parameters?.modal}
                    header              ={{
                        "title"     : data?.getUnit_detail?.Business?.[0]?.name,
                        //-- If has floor information, add it to the header otherwise just return room otherwise return nothing 
                        "subTitle"  : (
                                        data?.getUnit_detail?.floor     ? 'Floor ' + data?.getUnit_detail?.floor  + ' ' + data?.getUnit_detail?.room
                                        : data?.getUnit_detail?.room    ? 'Room ' + data?.getUnit_detail?.room : null
                                    )
                    }}
                    footer              ={{
                        status      : data?.getUnit_detail?.status
                    }}
                />
            </span>
        {/* </div> */}
        </>
    )
};


/* String related utilities.
*
*/



export function capitalizeFirstLetter(string) {
    /* Send in a string to capitalize the first letter.
    *
    * @param string: Single string to capitalize first letter.
    * @returns {string}: with first letter capitalized
    */
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shrinkAddEllipse(content, length=100) {
    /* Send in a to shrink to length and concatenate an ellipse to the end.
    *
    * @param 
    *   {string} content - the string to shrink
    *   {number} length - the length to shrink to. Default is 100 if undefined
    *
    * @returns {string}: String of defined or default length ending with ellipse
    */

    //-- if length is greater than 100 shrink it and then return that sub-string
    if (content.length > 100) return content.substring(0,100) + '...';
    
    //-- Otherwise return what was sent in no modification needed.
    return content;
}

import React, { useContext } from 'react'
import { GlobalContext } from '../../../App'



/** 
 * @description        Integrate a Details component by providing an onClick event.
 * 
 * @param {Function} onClickEvent Provide the function that executes when Details is selected.
 * @param {key}      uuid Unique ID. If not defined will use uuidv4
 * @returns jsx
 * 
 * @example <CardDetail 
 *              onClickEvent={ yourEventToExecute(foo, bar) }
 *              params={[foo, bar]}
 *          />
 */
export default function CardDetail({
    onClickEvent,
    key,
    // args
}) {
    

    const {
        Icon,
        uuidv4
    } = useContext(GlobalContext)

    const params = {
        key             : key ? key : uuidv4(),
        // onClickEvent    : (args) => {
        onClickEvent    : () => {
            if( (typeof onClickEvent) === 'function') {
                //TODO: 20221214 #EP || Add ability to add more args than 2
                return onClickEvent()
            }
            else  {
                //   Disable it
                return false
            }
        },
        // args            : args ? args : []
    }

    // handleViewSensor(e, alert?.Unit?.[0], alert)

  return (
    <div  className='card__controls'
            onClick={() => console.log(params.onClickEvent())}
            // onClick={(e) => console.log(params.onClickEvent([...args]))}
            // onClick={(e) => params.onClickEvent(e)}
            key={params.key}
    >
        {console.log(typeof onClickEvent)}
        <span className='detail__font'>
            Detail &nbsp;
        </span>
        <i className='detail_Information'>
            <Icon
                name='infoBordered'
                background_c='transparent'
                svg_c='var(--clr-accent)'
                boxShadow='disabled'
                // width='80%'
            />
        </i>
        <i className='infoContinue'>
            {/* &gt; */}
            &#65310;
        </i>
    </div>
  )
}

import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        alert_count
        configuration
        date_created
        date_modified
        name_first
        name_last
        status
        type
        Alert {
          _id
        }
        Account {
          _id
          name
        }
        Business {
          _id
          name
        }
      }
    }
  }
`;



// export const SET_ALERT = gql`
//   mutation SetAlert($id: ID!, $id_token: String!) {
//     setAlert(_id: $id, token: $id_token) {
//       _id
//       isRead
//     }
//   }
// `;
export const SET_ALERT = gql`
  mutation SetAlert($id: ID!, $id_token: String!) {
    setAlert(_id: $id, token: $id_token) {
      _id
      isRead
    }
  }
`;


// export const USER_LOGOUT = gql`
//   mutation logout($id: ID!) {
//     logout(_id: $id) {
//       _id
//     }
//   }
// `;
import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { GlobalContext } from '../../App';
import './assets/css/style.css';

//-- GQL
import { useMutation } from '@apollo/client';
import { USER_LOGIN } from '../../services/gql/Mutation'

//------------------------------------------------------------------------------
//-- COMPONENTS

//TODO:: 08232022 #EP || Convert elements within to components.
// import AccountId from './components/AccountId';
import UsernamePassword from './components/primaryAuth';
// import Banner from '../../components/Banner';

//------------------------------------------------------------------------------
//-- HOOKS
//-- JWT LOGIN & AUTH
import useAuthService from '../../hooks/useAuthService';
// import useHandleBanner from '../../hooks/useHandleBanner';
// import useHandleAccess from '../../hooks/useHandleAccess';


//------------------------------------------------------------------------------
//-- React Component
export default function Login() {
    //TODO:: 08232022 @#EP || How can I make secure login forms

    const {
        uuidv4,
        Loading,
        User,
        Alerts,
        setAlertCount,
        Banner
    } = useContext(GlobalContext);

    // const { hasAccess, banner, redirecting } = useHandleAccess({ User , accessLevel: 'guest' },[]);
    // const Banner = useHandleBanner('test', 'test', 'test');

    const [pageLoading, setPageLoading] = useState(true);
    const [loginForm, setLoginForm] = useState( {
        //! TODO: 12/02/2022 #EP || CHANGE THIS. Not secure, needs to be read at time.
        'email'              : undefined,
        'password'              : undefined,    //TODO: encrypt or just don't store
        'attempts'              : 0,            //TODO:: Add block after X with alert to admins
        'g-recaptcha-response'  : undefined,     //TODO:: add captcha?
    });

    const [failedLogin, setFailedLogin] = useState(false);

    const [login, { data, loading, error } ] = useMutation(USER_LOGIN);


    const [accountName, setAccountName] = useState(undefined)
    const handleChange = (e) => {
        
        //TODO:: 08232022 #EP || Move to a hook
        const { name, value } = e.target;
        if (e.target.id === 'password'){
            let password = e.target.value;
            console.log(password)
        }
        setLoginForm({
            ...loginForm,
            [name]: value
        });
    };

    const onKeyUp = async (e) => {
        e.preventDefault();
        console.log(e.target.id);
        setLoginForm({
            ...loginForm,
            [e.target.id] : e.target.value
        });

    };

    
    const handleLoginSubmit = async (e) => {
        //TODO:: 08232022 #EP || Move to a hook
        e.preventDefault();
        
        setFailedLogin(false) /* Disable the banner */

        
        // console.log("//-- Attempting to handle login")
        // console.log(e)
        // console.log(loginForm)
        setLoginForm({
            ...loginForm,
            attempts : loginForm.submitAttempts + 1
        });
        
        try {
            
            //-- Extract form data to send to GQL Mutation
            const payload = {};
            const body = e.nativeEvent.srcElement
            for( let i = 0; i < body.length; i++){
                if(body[i].id === 'password' || body[i].id === 'email' || body[i].id === 'rememberPassword' ){
                    
                    //-- Updating payload to be sent in with form data
                    payload[body[i].id] = body[i].value
                }
            }


            //-- Execute GraphQL QUERY
            const { data, loading, error  
                } = await login({
                variables: { ...payload },
            })
            
            //-- LOGIN SUCCESS, UPDATE JWT WITH AUTH  AND RE-ROUTE
            if(data)    useAuthService.login(data.login,setAlertCount);
            
        }
        catch (error) {
            //TODO:: 08222022 #EP || Update this so ui banner throws message
            console.log("//-- login error", error)
            // console.log(loginForm)
            
            //-- Throw Banner by setting login failed
            setFailedLogin(true)
            //-- Remove the banner after the delay time passes ( unless user clicks it which then at top it removes it)
            setTimeout(() => {
                setFailedLogin(false)
            }, process.env.REACT_APP_BANNER_DELAY_HIDE)
        }
    };
    
    
    useState(() => {
        
        //-- Forcing a min loading time so animations
        setTimeout(() => {
            setPageLoading(false);
        },process.env.REACT_APP_MIN_LOAD_TIME_MS )
        
    },[failedLogin]);

    
    
    //--------------------------------------------------------------------------
    //-- Conditional Rendering
    
    //-- Attempting to log in
    if(pageLoading && !useAuthService.isLoggedIn() ){
        return <Loading />;
        
    }
    //-- Is already logged in
    else if(useAuthService.isLoggedIn()){
        return (<Navigate replace to="/Dashboard" />);
    }
    
    //-- Primary Render
    else {
        return(
            <div className='page__div' >
                
                {/* BANNER - conditionally rendered */}
                {failedLogin === true 
                    ? <Banner 
                        elementId={uuidv4()}    
                        title={'Login Failed'}
                        message={'Check your connection and try again..'}
                        // eslint-disable-next-line react/style-prop-object
                        style={'error'}
                        // delayShow={100}
                        // delayHide={5000}
                        controlToggle={setFailedLogin}
                        actionStatus={failedLogin}
                    />
                    : null
                }

                <section className='page__section' >
                    <div className='page__section__content'>
                        <h2 className='page__section__title'>
                            Login Portal
                        </h2>
                        <p className='page__section__summary'>
                            Sign in with your Bair account.
                            {/* <br/> */}
                            {/*TODO: 20221213 #EP || Add way note to sign up
                            If you do not yet have a Bair account, contact your local Bair Distributor. */}
                        </p>
                    </div>

                    {/* <div className='page__section__content' > */}
                    <UsernamePassword
                        handleLoginSubmit={handleLoginSubmit}
                        // onKeyUp={onKeyUp} //-- 20221213 #EP || Removed this so not saved in cache
                        // accountName={accountName}
                    />
                    {/* </div> */}
                    
                </section>
            </div>
        )
    }
}


import Auth from '../services/Auth'

//TODO:: 082722 #EP || Build in actual logic here.
export default Auth;

// export default function useAuthService( action ) {
//     const {
//         isLoggedIn,
//         getCurrentUserToken,
//         getCurrentUser,
//         getBusinessId,
//         getBusinessName,

//     } = Auth

//     const options = {
//         "isLoggedIn"            : isLoggedIn,
//         "getCurrentUserToken"   : getCurrentUserToken,
//         "getCurrentUser"        : getCurrentUser,
//         "getBusinessId"         : getBusinessId,
//         "getBusinessName"       : getBusinessName,
//     }

//     console.log(Auth)
//     return options
// }

import React, {useContext, useState } from 'react';
import './assets/css/style.css'; //-- Login specific styles
import { GlobalContext } from '../../App';

export default function Login() {



  const {
    Loading
  } = useContext(GlobalContext);

  const [pageLoading, setPageLoading] = useState(true);

  useState(() => {
    setTimeout(() => {
            setPageLoading(false);
    },1000);
  }, []);

  return (
    <div>aahhh</div>
  )
}

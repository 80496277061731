import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../../App';

/* To navigate to reset password if needed*/
import { NavLink } from 'react-router-dom';

export default function UsernamePassword({handleLoginSubmit}) {
  const {
    Icon
  } = useContext(GlobalContext);


  const [attemptCount, setAttemptCount] = useState(0);

  const [rememberMe, setRememberMe] = useState(false); /* */
    

  //----------------------------------------------------------------------------
  //-- Toggle the state of stay-logged-in checkbox -- (which is hidden but used for form submission)

  const handleToggleCheckbox = (e) => {
    e.preventDefault();

    const checkbox = document.getElementById('rememberPassword');

    checkbox.parentElement.classList.toggle('login__remember_checked');

    if(!checkbox) return null;

    setRememberMe( currentState => {
      if( currentState === true ) return false;
      else return true;
    });

    //-- Toggle boolean state
    checkbox.checked ^= 1;

  }


  //----------------------------------------------------------------------------
  //-- Primary render
  return (
    <form
        className="login__form"
        id="login-form" 
        // onSubmit={e () => {handleLoginSubmit(e)}}
        onSubmit={(e) => {handleLoginSubmit(e)}}
    >
      {/* USERNAME */}
      <span className="login__element">
        <label htmlFor='email'></label>
        <input  id="email"
                className='login__input'
                type="email"
                placeholder="email@domain.com"
                // onKeyUp={onKeyUp} //-- 20221213 #EP || Removed this so not saved in cache
                required
                autoFocus
              //TODO: 05/18/2022 #EP | Check database min/max to add validation?
        />
      </span>
      
      {/* PASSWORD */}
      <span className="login__element">
        <label htmlFor="password"></label>
        <input  id="password"
                className='login__input'
                type="password"
                placeholder="Password"
                required
          // onKeyUp={onKeyUp} //-- 20221213 #EP || Removed this so not saved in cache
          //TODO: 05/18/2022 #EP | Check database min/max to add validation?
        />
      </span>

      {/* CHECKBOX REMEMBER PASSWORD */}
      {/*TODO: 12/17/2022 #EP || Re-enable this? */}
      {/* <span className='login__element login__remember'
            onClick={(e) => {handleToggleCheckbox(e)}}
      >          
        {rememberMe
          ? <Icon boxShadow={'disabled'}  name="LockLocked"  background_c="transparent" svg_c='var(--clr-fnt-1000)' />
          : <Icon boxShadow={'disabled'}  name="LockUnlocked"  background_c="transparent" svg_c='var(--clr-fnt-800)' />
        }
          
          <label  className='login__remember__label'
                  htmlFor='rememberPassword'>
            Stay Logged in for 30 days?
        </label>
        <input  id='rememberPassword'
                type='checkbox'
                value={rememberMe}
        />
      </span> */}

        {/* LOGIN BUTTON */}
        <button 
          id="login__button"
          className="login__button"
          type="submit"
          value={'login'}
        >
          Login
        </button>

        {/* RESET PASSWORD */}
        {/*TODO:: 20221213 #EP || Add a pag for this */}
        <NavLink  className='login__form__loginReset'
                  to={`/reset-login`}
        >
            Need Help Logging In?
        </NavLink>
    </form>
  )
}

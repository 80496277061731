import React from 'react'

export default function Help() {
  return (
    <div className='page__container'>
      <div className='page__div'>
        <section className='page__section'>
          <div className='page__section__header'>
            <h3 className='page__section__title'>
              Help
            </h3>
            <p className='page__section__summary'>
              This is a help page placeholder.
            </p>
          </div>
        </section>
      </div>
    </div>
  )
}

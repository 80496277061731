import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../App';
import {  NavLink, useLocation } from 'react-router-dom'; //-- routing 
import useAuthService from '../../hooks/useAuthService';

//-- GQL
// import { useQuery } from '@apollo/client';
// import { ALERTS_GET } from '../../services/gql/Query';


//-- Header Style Sheet
import './assets/css/style.css';
import header from '../../components/Modal/components/header';

export default function Header( ) {
  
  /** Header is the top-bar within the page. Some User controls and navigation are here, too.
   * 
   */
   const {
    uuidv4,
    // Logo,
    Icon, 
    // pages,
    Pages,
    alerts,
    // setAlerts
  } = useContext(GlobalContext)

  // const {loading, data, error} = useQuery(ALERTS_GET);

  const [businessName, ] = useState( 
                              useAuthService.isLoggedIn()
                                ? useAuthService?.getAccountName() //+ useAuthService.getBusinessName() 
                                : null
                          );
  const [accountType,] =  useState( 
                            useAuthService.isLoggedIn()
                            ? useAuthService.getAccountType()
                            : null
                          );
  
  //-- Getting browser location object to validate location and update header accordingly
  const location      = useLocation();
  
  //TODO:: 091722 #EP || Get just header pages. ATM it's getting 18 objects but only X are not undefined
  const [headerPages, setHeaderPages] = useState([]);

  
  
  const handleIsValidLocation = () => {
    //** Evaluate if is validation location or not and return proper display name. */
    

    //-- Exit if nothing to validate ( shouldn't happen, but just in case )
    if(!location || !Pages ) {
      if(useAuthService.isLoggedIn)   return 'Dashboard'
      if(!useAuthService.isLoggedIn)  return 'Homepage'
    }

    //-- Get the current page object from location to test again available Pages and their routes
    const currentPath = location.pathname.split('/')[1]
    // console.log(currentPath)

    // console.log('location', location)
    // console.log('Pages', Pages)
    //-- Check to see if location is a valid page
    const locationExist = Pages.index.filter( page => page?.routes.includes('/' + currentPath) )
    
    // console.log("locationExist: ", locationExist)
    //-- If location is valid, return it by pulling it from locationExists array
    if(locationExist.length > 0)    return locationExist?.[0]?.displayName
    
    
    //-- If location is not valid, return Dashboard or Homepage based on auth state.
    if(useAuthService.isLoggedIn)   return 'Dashboard'
    if(!useAuthService.isLoggedIn)  return 'Homepage'
  };


  useEffect(() => {
    setHeaderPages(Pages.getRoutes(false, 'header'))
    // console.log(locationRoot)
    // handleIsValidLocation()
    // if(data?.getAlerts && !error && !loading) {
    //   setAlerts(data?.getAlerts)
    // }
    
  }, [location])

  //----------------------------------------------------------------------------
  //-- RETURN FUNCTION

  

  return (
    <header key={uuidv4()}>
      <div className='header__path' >
        {/*TODO:: 082722 #EP || Evaluate if I want this to change based on auth or no auth
                                - Similar to below, I will likely need to add auth awareness but more simplified
        */}
        <NavLink  to={useAuthService.isLoggedIn() ?`/sensors` : `/sensors`}
                  className='header__link'
                  alt={`${process.env.REACT_APP_BRANDNAME} ${useAuthService.isLoggedIn() ? 'Dashboard' : "Homepage"}`}
          >
          <h1 className='header__brandName ' >
            {/* <Logo /> */}
            <Icon 
              name='BairGeometric'
              background_c='transparent'
            />
            {process.env.REACT_APP_BRANDNAME}
          </h1>
      </NavLink>
        
        {/* The current Pages location */}
        <h2 className ='header__pathContainer fs-500 fw-semi-bold ls-700' >
          {/* Navigational breadcrumbs
            - This is commented out 
          
          */}
          {/* <span className = 'header__pathSymbol'>
           /
          </span>
          
          { location 
            ? (<NavLink to={`/${location}`} 
                        className='header__link header__link_path'
              > */}
                {/** Dynamically update header based on location. 
                 * 
                */}
                {/* {handleIsValidLocation(location)}
                  
                </NavLink>
              )
            : (<NavLink to='/sensors' 
                        className='header__link header__link_path'
                >
                { useAuthService.isLoggedIn() ? 'Dashboard' : "Homepage" }
              </NavLink>
            )
          } */}
            {useAuthService.isLoggedIn() 
              ? <>
                  <span className = 'header__pathSymbol'>
                    / 
                </span>
                <NavLink  to='/account' 
                          className='header__link header__link_path'
                >
                  { businessName }
                </NavLink>
                </>
              : null
            }
        </h2>
      </div>

      



      {/**   Loops through all pages that should appear in Header. Renders their 
        *   each icon and corresponding navigational link.
        *  
        *   - Shortcuts/Icons in the header are managed in src/context/Pages.
        *   - User details and auth affect what is rendered.
        * 
        *   
      */}
          <div className='header__menu' >
            {headerPages.map((page, index) => {
              if(page){
                return (
                  <NavLink
                    key={uuidv4()}
                    className={({ isActive }) => (
                                  isActive
                                    ? "navigation__link link__active"
                                    : "navigation__link"
                    )}
                    to={`/${page.name}`}
                  >
                    {page.name === 'notifications' ? alerts?.length : null}
                    {page?.icon}
                  </NavLink>
                )
              }
            })}
          </div>
    </header>
  );
}

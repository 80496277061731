import React, { useContext, useEffect, useState } from 'react';
import './assets/css/style.css';
import { GlobalContext } from '../../App';

import Accounts from './context/json/accounts.json';
// import Notifications from './context/json/notifications.json';
// import Users from './context/json/users.json';
// import Subscriptions from './context/json/subscriptions.json';

export default function AccountSelector() {

    const {
        user,
        // setUser
    } = useContext(GlobalContext);

    const [userType, setUserType] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [users, setUsers] = useState([]);

    const [changeUserType, setChangeUserType] = useState(false);
    const [accountType, setAccountType] = useState(null);
    const [accountName, setAccountName] = useState(null);
    const [accountUser, setAccountUser] = useState(null);
    
    


    function handleChange(e){
        e.preventDefault();
        console.log("e.target: ", e.target);
        console.log("e.target.name: ", e.target.name)
        console.log("e.target.value: ", e.target.value);
        console.log("e.target.value: ", e.target.value);

        
        // AccountName-undefined
        setAccountType(document.getElementById('AccountType').value)
        
        const accountName = document.getElementById('AccountName').value;
        setAccountName(accountName);
        // if(accountName === "AccountName-undefined"){
        //     setAccountName([]);
        //     setAccountUser([]);
        //     setUsers([]);
        //     return;
        // }
        // else {
        // }
        const accountUser = document.getElementById('AccountUser').value;
        setAccountUser(accountUser)
        // if(accountUser === "AccountUser-undefined"){
            
        // }
        // else {
        // }
        

        if(e.target.name === 'AccountType') {
            setUserType(e.target.value)
            setUsers([])
            const getAccounts = Accounts.filter(account => { return account.account.type === e.target.value })
            setAccounts(Accounts.filter(account => { return account.account.type === e.target.value }))
            const thisAccount = Accounts.filter(account => { return account.account.type === document.getElementById('AccountType').value });
            setSelectedAccount(thisAccount[0]);
            setUsers(thisAccount[0].users)
        }

        if(e.target.name === 'AccountName'){
            console.log("Setting Users")
            const thisAccount = Accounts.filter(account => { return account.name === e.target.value });
            setSelectedAccount(thisAccount[0]);
            setUsers(thisAccount[0].users)
        }
    };


    useEffect(() => {
        if (!user) {
            console.log("ERROR: No user logged in how'd you get here...")
        } 
        else if(user && !userType){
            //-- If a user is logged in, and user Type not yet defined, define it
            console.log(`user exists but no type so setting userType to: ${user.type} and updating accounts based on user type.`);
            setUserType(user.type);
            
            setAccounts(Accounts.filter(account => { return account.account.type === document.getElementById('AccountType').value }));
            const thisAccount = Accounts.filter(account => { return account.account.type === document.getElementById('AccountType').value });
            setSelectedAccount(thisAccount[0]);
            setUsers(thisAccount[0].users)
        }
        //-- If a user type is defined, we're logged in so we can use the toolbar
        else if(userType) {
            console.log("userType: so setting accounts");
            
            setAccounts(Accounts.filter(account => { return account.account.type === document.getElementById('AccountType').value }));
            // const thisAccount = Accounts.filter(account => { return account.account.type === document.getElementById('AccountType').value });
            // setSelectedAccount(thisAccount[0]);
            // setUsers(thisAccount[0].users)
        } else {
            console.log("ERROR: Else statement. how'd you get here...?!")
        }

        // if(accountName === null){
        //     setUsers([]);
        // }

    }, [user, accountType, accountName, accountUser]);

    //--------------------------------------------------------------------------
    // COMPONENT

    if(!user.type) return ( <></> )
    
    return (
    <div className='page__div'
         id='account-selector__container'
        >

        {/* ACCOUNT TYPE */}
        <span className='flex-100 flex-row' >
            <label  className='fs-100 fw-semi-bold ls-500 flex-100'
                    htmlFor="AccountType"
                >
                Type:
            </label>
            <select className='fs-100 fw-bold ls-300 flex-900'
                    name="AccountType"
                    id="AccountType"
                    selected='admin'
                    onChange={handleChange}
                >
                <option value="admin">Admin</option>
                {/* <option value="reseller">Reseller</option> */}
                <option value="distributor">Distributor</option>
                <option value="customer">Customer</option>
            </select>
        </span>

        {/* ACCOUNT NAME */}
        <span className='flex-300 flex-row' >
            <label  className='fs-100 fw-semi-bold ls-500 flex-100'
                    htmlFor="AccountName"
                >
                Account:
            </label>
            <select className='fs-100 fw-bold ls-300 flex-900'
                    id="AccountName"
                    name="AccountName"
                    onChange={handleChange}
                    
                >
                {/* <option key={`AccountName-undefined`} value={'AccountName-undefined'}>-</option> */}
                {accounts.map((account, index) => {
                    // console.log(account.account.name)
                    return (
                        <option
                                key={`${index}-${account.account.id}`}
                                value={account.account.name}
                            >
                            {account.name}
                        </option>
                    )
                 })}
              
            </select>
        </span>

        <span className='flex-300 flex-row' >
            <label  className='fs-100 fw-bold ls-500 flex-100'
                    htmlFor="AccountUser"
                >
                User:
            </label>
            <select className='fs-100 fw-bold ls-300 flex-900'
                    id="AccountUser"
                    name="AccountUser" 
                    onChange={handleChange}
                >
                    
                    {/* <option key={`AccountUser-undefined`} value={'AccountUser-undefined'}>-</option> */}
                    {/* <option value={'-'}>-</option> */}
                 {users.map((user, index) => {
                   //  console.log("user: ", user)
                    let firstName = user.firstName
                    let lastName = user.lastName
                    return(
                        <option key={`${index}-${user.id}`} value={`${firstName} ${lastName}`}>
                            {firstName} {lastName}
                        </option>
                    )
                 })}
               
            </select>
        </span>
    </div>
    )
}

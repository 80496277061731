// import React, {useState} from 'react'

// export default function HandleLogout(useAuthService, waitTime=500) {  
  
//     const logout = useState( async () => {
//         try {
             
//             const logout = await useAuthService.logout()
//             console.log(logout)
//              if(logout === true){
//                  setTimeout(() => {
//                     console.log("test")
//                     window.location.assign('/');
//                 }, waitTime);
//                  return true 
//              }
//              else {
//                 console.log("ELSE FALSE")
//                 return false;
//              }
//         }
//         catch (error) {
//             return false;
//         }
//     })

//     return logout
// }


export default async function HandleLogout(useAuthService, waitTime=500) {
    console.log("Logging out placeholder")
    
    try {
        useAuthService.logout()
        window.location.assign('/');
    }
    catch (error) {
        return false;
    }
}